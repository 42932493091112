import { Button, Grid, TextInput, Title } from "@mantine/core";
import { useForm, zodResolver } from "@mantine/form";
import { ErrorCode } from "@wendy/types";
import React, { useEffect, useMemo } from "react";
import { z } from "zod";
import useFormMessage from "../../hooks/useFormMessage";
import { isApiError } from "../../lib/server";
import { useVerifyEmailMutation } from "../../services/registration";
import { useTranslate } from "../../translations";

type Props = {
  token: string;
};

export function EmailVerificationForm({ token }: Props) {
  const [verifyEmail, { isLoading }] = useVerifyEmailMutation();
  const t = useTranslate();
  const { isOpen, messageProps, Message, setError, setSuccess } = useFormMessage();
  const schema = useMemo(
    () =>
      z.object({
        token: z.string().uuid(t("email_verification.token.invalid")),
      }),
    [t],
  );
  const form = useForm({
    initialValues: {
      token: token,
    },
    validate: zodResolver(schema),
  });
  const handleSubmit = form.onSubmit(async (values) => {
    try {
      await verifyEmail(values).unwrap();
      setSuccess({
        message: t("email_verification.success_message.message"),
        title: t("email_verification.success_message.title"),
      });
      form.reset();
    } catch (error) {
      if (isApiError(error)) {
        switch (error.code) {
          case ErrorCode.TOKEN_EXPIRED:
            return setError({
              message: t("email_verification.token.expired"),
              title: t("error"),
            });
        }
      }
      setError({
        message: t("error.unknown_error_type"),
        title: t("error"),
      });
    }
  });
  useEffect(() => {
    form.validate();
  }, [token, form]);

  return (
    <form onSubmit={handleSubmit}>
      <Grid>
        <Grid.Col>
          <Title align="center">{t("email_verification.title")}</Title>
        </Grid.Col>
        {isOpen && (
          <Grid.Col>
            <Message {...messageProps} />
          </Grid.Col>
        )}
        <Grid.Col>
          <TextInput
            disabled
            label={t("email_verification.token.label")}
            withAsterisk
            {...form.getInputProps("token")}
          />
        </Grid.Col>
        <Grid.Col>
          <Button disabled={!form.isValid()} fullWidth loading={isLoading} type="submit">
            {t("email_verification.submit")}
          </Button>
        </Grid.Col>
      </Grid>
    </form>
  );
}
