import { Box, createStyles, Group, Navbar, ScrollArea, Text, UnstyledButton } from "@mantine/core";
import { IconChevronRight, IconHome, IconKey, IconLogin, IconUsers } from "@tabler/icons-react";
import React from "react";
import { useAuth } from "../../hooks/useAuth";
import { path } from "../../lib/paths";
import { useTranslate } from "../../translations";
import { CurrentUserAvatar } from "../ui/current-user-avatar";
import { NavbarLink } from "./navbar-link";
import { UserMenu } from "./user-menu";

const useStyles = createStyles((theme) => ({
  hiddenMobile: {
    [theme.fn.smallerThan("sm")]: {
      display: "none",
    },
  },
  hiddenDesktop: {
    [theme.fn.largerThan("sm")]: {
      display: "none",
    },
  },
}));

type Props = {
  close: () => void;
  opened: boolean;
};

export function AppNavbar({ close, opened }: Props) {
  const t = useTranslate();
  const auth = useAuth();
  const { classes, theme } = useStyles();
  const dark = theme.colorScheme === "dark";

  return (
    <Navbar
      hidden={!opened}
      p="xs"
      title="Navigation"
      className={classes.hiddenDesktop}
      zIndex={1000000}
    >
      <Navbar.Section grow component={ScrollArea} mx="-xs" px="xs">
        {auth.user ? (
          <>
            <NavbarLink
              to={path("dashboard")}
              label={t("dashboard.title")}
              icon={<IconUsers size={16} />}
              color="blue"
              onClick={close}
            />
            <NavbarLink
              to={path("team-list")}
              label={t("teams.title")}
              icon={<IconUsers size={16} />}
              color="teal"
              onClick={close}
            />
            <NavbarLink
              to={path("event-list")}
              label={t("events.title")}
              icon={<IconUsers size={16} />}
              color="violet"
              onClick={close}
            />
          </>
        ) : (
          <NavbarLink
            to={path("home")}
            label={t("home.title")}
            icon={<IconHome size={14} />}
            onClick={close}
          />
        )}
      </Navbar.Section>
      <Navbar.Section>
        <Box
          sx={{
            paddingTop: theme.spacing.sm,
            borderTop: `1px solid ${dark ? theme.colors.dark[4] : theme.colors.gray[2]}`,
          }}
        >
          {auth.user ? (
            <UserMenu onClose={close} position="top-end">
              <UnstyledButton
                sx={{
                  display: "block",
                  width: "100%",
                  padding: theme.spacing.xs,
                  borderRadius: theme.radius.sm,
                  color: dark ? theme.colors.dark[0] : theme.black,

                  "&:hover": {
                    backgroundColor: dark ? theme.colors.dark[6] : theme.colors.gray[0],
                  },
                }}
              >
                <Group>
                  <CurrentUserAvatar radius="xl" />

                  <Box sx={{ flex: 1 }}>
                    <Text size="sm" weight={500}>
                      {auth.user.first_name} {auth.user.last_name}
                    </Text>
                    <Text color="dimmed" size="xs">
                      {auth.user.email}
                    </Text>
                  </Box>

                  <IconChevronRight size={18} />
                </Group>
              </UnstyledButton>
            </UserMenu>
          ) : (
            <>
              <NavbarLink
                to={path("login")}
                label={t("login_form.title")}
                icon={<IconLogin size={16} />}
                color="blue"
                onClick={close}
              />
              <NavbarLink
                to={path("register")}
                label={t("register_form.title")}
                icon={<IconKey size={16} />}
                color="green"
                onClick={close}
              />
            </>
          )}
        </Box>
      </Navbar.Section>
    </Navbar>
  );
}
