import { ActionIcon, Button, Group, Stack, Text } from "@mantine/core";
import React, { useState } from "react";
import { LocationId, TeamId } from "@wendy/types";
import { IconEdit, IconPlus, IconTrash } from "@tabler/icons-react";
import { useTranslate } from "../../translations";
import { AddLocationDialog } from "./add-location-dialog";
import { EditLocationDialog } from "./edit-location-dialog";
import { RemoveLocationDialog } from "./remove-location-dialog";
import { useSelector } from "../../store/store";
import { selectTeamLocations } from "../../store/selectors";

type Props = {
  teamId: TeamId;
};

export function LocationList({ teamId }: Props) {
  const t = useTranslate();
  const [addOpened, setAddOpened] = useState(false);
  const [editOpened, setEditOpened] = useState<LocationId | null>(null);
  const [removeOpened, setRemoveOpened] = useState<LocationId | null>(null);
  const locations = useSelector((state) => selectTeamLocations(state, teamId));

  return (
    <>
      <Stack>
        <Group position="apart">
          <Button
            size="xs"
            onClick={() => setAddOpened(true)}
            leftIcon={<IconPlus size={14} />}
            variant="outline"
          >
            {t("add_location.title")}
          </Button>
        </Group>
        {locations.map((location) => (
          <Group key={JSON.stringify(location)} position="apart">
            <Text sx={{ flexGrow: 1 }}>{location.name}</Text>
            <ActionIcon
              onClick={() => setRemoveOpened(location.id)}
              variant="filled"
              color="red"
              size="sm"
              title={t("remove")}
            >
              <IconTrash size={14} />
            </ActionIcon>
            <ActionIcon
              onClick={() => setEditOpened(location.id)}
              variant="filled"
              color="blue"
              size="sm"
              title={t("edit")}
            >
              <IconEdit size={14} />
            </ActionIcon>
          </Group>
        ))}
      </Stack>
      <AddLocationDialog
        opened={addOpened}
        onClose={() => setAddOpened(false)}
        onSuccess={() => setAddOpened(false)}
        team={teamId}
      />
      {locations
        .filter((x) => x.id === removeOpened)
        .map((x) => (
          <RemoveLocationDialog
            key={x.id}
            opened={x != null}
            onClose={() => setRemoveOpened(null)}
            onSuccess={() => setRemoveOpened(null)}
            locationId={x.id}
          />
        ))}
      {locations
        .filter((x) => x.id === editOpened)
        .map((x) => (
          <EditLocationDialog
            key={x.id}
            opened={x != null}
            onClose={() => setEditOpened(null)}
            onSuccess={() => setEditOpened(null)}
            location={x}
          />
        ))}
    </>
  );
}
