import { createApi } from "@reduxjs/toolkit/query/react";
import type { Attendee, Event, EventId, Invitation } from "@wendy/types";
import { baseQuery } from "./baseQuery";

export const invitationsApi = createApi({
  reducerPath: "invitationsApi",
  baseQuery,
  endpoints: (builder) => ({
    getInvitation: builder.query<
      { attendees: Attendee[]; event: Event; invitation: Invitation },
      { token: string }
    >({
      query: ({ token }) => ({
        method: "GET",
        url: `invitation/${token}`,
      }),
    }),
    sendInvitation: builder.mutation<void, { eventId: EventId; email: string }>({
      query: ({ email, eventId }) => ({
        body: { email, event: eventId },
        method: "POST",
        url: `invitation`,
      }),
    }),
    acceptInvitation: builder.mutation<void, { token: string }>({
      query: ({ token }) => ({
        body: { accept: true },
        method: "PUT",
        url: `invitation/${token}`,
      }),
    }),
  }),
});

export const { useAcceptInvitationMutation, useGetInvitationQuery, useSendInvitationMutation } =
  invitationsApi;
