import { Container, Loader, Text } from "@mantine/core";
import { useDocumentTitle } from "@mantine/hooks";
import React, { useMemo } from "react";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { EventCreateForm } from "../features/events";
import { path } from "../lib/paths";
import { selectSelectedTeamId } from "../store/selectors";
import { useSelector } from "../store/store";
import { useTranslate } from "../translations";
import { z } from "zod";
import { EventId } from "@wendy/types";
import { useGetEventQuery } from "../services/events";

const SEARCH_PARAM_CLONE = "clone";
export const getPathWithClone = (eventId: EventId) =>
  `${path("event-create")}?${SEARCH_PARAM_CLONE}=${eventId}`;

const uuidSchema = z.string().uuid();

function useCloneEvent() {
  const [searchParams] = useSearchParams();
  const eventId = useMemo(() => {
    const result = uuidSchema.safeParse(searchParams.get(SEARCH_PARAM_CLONE));
    if (!result.success) {
      return null;
    }
    return result.data;
  }, [searchParams]);
  const { data, isLoading } = useGetEventQuery(eventId ?? "", { skip: eventId == null });
  return { data, isLoading };
}

export default function EventCreatePage() {
  const navigate = useNavigate();
  const t = useTranslate();
  useDocumentTitle(t("event_create.title"));
  const { data, isLoading } = useCloneEvent();
  const team = useSelector(selectSelectedTeamId);

  if (team == null) {
    return <Navigate to={path("team-list")} />;
  }

  return (
    <Container size="sm">
      <Text size="lg" weight={500}>
        {t("event_create.title")}
      </Text>

      {isLoading ? (
        <Loader />
      ) : (
        <EventCreateForm
          defaultValues={data}
          onSuccess={(id) => {
            navigate(path("event-detail", id));
          }}
          team={team}
        />
      )}
    </Container>
  );
}
