import { Container, Title } from "@mantine/core";
import { useDocumentTitle } from "@mantine/hooks";
import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { RegisterForm } from "../features/users";
import { path } from "../lib/paths";
import { useTranslate } from "../translations";

export default function RegisterPage() {
  const { user } = useAuth();
  const t = useTranslate();
  useDocumentTitle(t("register_form.title"));

  if (user) {
    return <Navigate to={path("dashboard")} />;
  }

  return (
    <Container size="xs" mt="xl">
      <Title align="center" mb="xl">
        {t("register_form.title")}
      </Title>
      <RegisterForm />
    </Container>
  );
}
