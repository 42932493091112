import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { UserViewFull } from "@wendy/types";
import { api, handleError } from "../../lib/api";

export const tokenKey = "access_token";
export const userKey = "user";

type CreditState = {
  amount: number;
  blocked: number;
};
export type AuthState = {
  credit: CreditState;
  token: string | null;
  user: UserViewFull | null;
};

const token = localStorage.getItem(tokenKey);
let user: UserViewFull | null = null;

try {
  const encodedUser = localStorage.getItem(userKey);
  if (typeof encodedUser === "string") {
    user = JSON.parse(encodedUser) as any;
  }
} catch (e) {
  console.error(e);
}

const initialState: AuthState = {
  credit: {
    amount: 0,
    blocked: 0,
  },
  token,
  user,
};

type Credentials = {
  email: string;
  password: string;
};

export const signIn = createAsyncThunk<{ token: string; user: UserViewFull }, Credentials>(
  "account/sign-in",
  (body) =>
    api
      .post(`/sign-in`, body, {
        withCredentials: true,
      })
      .then(({ data }) => {
        localStorage.setItem(tokenKey, data.token);
        localStorage.setItem(userKey, JSON.stringify(data.user));
        return data;
      }, handleError),
);

export const signOut = createAsyncThunk<void, void>("account/sign-out", async () => {
  localStorage.removeItem(tokenKey);
  localStorage.removeItem(userKey);
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
    if (key != null && key[0] === "~") {
      localStorage.removeItem(key);
    }
  }
});

export const fetchAccount = createAsyncThunk<UserViewFull, void>("auth/fetch-account", () =>
  api.get(`/user`).then(({ data }) => {
    localStorage.setItem(userKey, JSON.stringify(data));
    return data;
  }, handleError),
);

export const fetchCredit = createAsyncThunk<CreditState, void>("auth/fetch-credit", () =>
  api.get(`/user/credit`).then(({ data }) => data, handleError),
);

export type ForgettenPasswordPayload = {
  email: string;
};
export const forgettenPassword = createAsyncThunk<void, ForgettenPasswordPayload>(
  "account/forgetten-password",
  (body) => api.post(`/forgetten-password`, body).then(({ data }) => data, handleError),
);

export type ResetPasswordPayload = {
  token: string;
  password: string;
};
export const resetPassword = createAsyncThunk<void, ResetPasswordPayload>(
  "account/reset-password",
  (body) => api.post(`/password-reset`, body).then(({ data }) => data, handleError),
);

export type ChangePasswordPayload = {
  current: string;
  password: string;
};
export const changePassword = createAsyncThunk<void, ChangePasswordPayload>(
  "account/change-password",
  (body) => api.post(`/user/change-password`, body).then(({ data }) => data, handleError),
);

const slice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(signIn.fulfilled, (state, { payload }) => {
      state.user = payload.user;
      state.token = payload.token;
    });
    builder.addCase(signOut.fulfilled, (state) => {
      state.user = null;
      state.token = null;
    });
    builder.addCase(fetchAccount.fulfilled, (state, { payload }) => {
      state.user = payload;
    });
    builder.addCase(fetchCredit.fulfilled, (state, { payload }) => {
      state.credit = payload;
    });
  },
  initialState,
  name: "account",
  reducers: {},
});

export default slice.reducer;
