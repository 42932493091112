import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Container, Loader } from "@mantine/core";
import { useDocumentTitle } from "@mantine/hooks";
import { useGetEventQuery, useUpdateEventMutation } from "../services/events";
import { useTranslate } from "../translations";
import { EventEditForm } from "../features/events";
import { ErrorAlert } from "../features/ui/error-alert";
import { useSelector } from "../store/store";
import { selectIsTeamManager } from "../store/selectors";
import { Event, EventId } from "@wendy/types";

function ValidAccess({ event }: { event: Event }) {
  const navigate = useNavigate();
  const [editEvent, { isLoading, error }] = useUpdateEventMutation();
  const t = useTranslate();

  useDocumentTitle(t("event_edit.title"));

  return (
    <Container size="sm">
      {error && <ErrorAlert error={error} />}
      <EventEditForm
        isLoading={isLoading}
        onSave={(newData) => {
          editEvent({
            event: event.id,
            data: newData,
          });
          navigate(-1);
        }}
        data={event}
      />
    </Container>
  );
}

function ValidParameters({ id }: { id: EventId }) {
  const { data, isLoading } = useGetEventQuery(id);
  const isManager = useSelector((state) => data && selectIsTeamManager(state, data.team));
  const t = useTranslate();

  if (isLoading) {
    return <Loader />;
  }
  if (!data) {
    throw new Error(t("error_404.title"));
  }
  if (!isManager) {
    throw new Error(t("event.cannot_edit"));
  }

  return <ValidAccess event={data} />;
}

export default function EventEditPage() {
  const params = useParams<{ id: string }>();
  const t = useTranslate();

  if (!params.id) {
    throw new Error(t("error_404.note"));
  }

  return <ValidParameters id={params.id} />;
}
