import { Anchor, Group, Text } from "@mantine/core";
import React, { useMemo } from "react";
import { Location } from "@wendy/types";
import { parseGPS } from "../../lib/utils";

type Props = {
  location: Location;
};

export function LocationView({ location }: Props) {
  const gps = useMemo(
    () => (location.value == null ? null : parseGPS(location.value)),
    [location.value],
  );

  return (
    <Group spacing="sm">
      {gps && (
        <Anchor size="sm" color="violet" href={`geo:${gps.lat},${gps.long}`} target="_blank">
          {location.name}
        </Anchor>
      )}
      {!gps && <Text fz="sm">{location.name}</Text>}
    </Group>
  );
}
