import { Container } from "@mantine/core";
import { useDocumentTitle } from "@mantine/hooks";
import React from "react";
import { ForgettenPasswordForm } from "../features/users";
import { useTranslate } from "../translations";

export default function ForgettenPasswordPage() {
  const t = useTranslate();
  useDocumentTitle(t("forgetten_password.title"));

  return (
    <Container size="xs" mt="xl">
      <ForgettenPasswordForm />
    </Container>
  );
}
