import { Button, Grid, TextInput } from "@mantine/core";
import { useForm, zodResolver } from "@mantine/form";
import { showNotification } from "@mantine/notifications";
import { z } from "zod";
import useCurrentUser from "../../hooks/useCurrentUser";
import { useEditUserMutation } from "../../services/users";
import { fetchAccount } from "../../store/slices/account";
import { useDispatch } from "../../store/store";
import { useTranslate } from "../../translations";

export type ContactInformation = {
  email: string;
  first_name: string;
  last_name: string;
  phone: string;
};

const phoneNumberRegex = /^(\+\d{3})?([ -]*\d){9}$/;

export function ContactInformationForm() {
  const t = useTranslate();
  const user = useCurrentUser();
  const dispatch = useDispatch();
  const schema = z.object({
    first_name: z
      .string()
      .min(1, { message: t("form.required_field") })
      .trim(),
    last_name: z
      .string()
      .min(1, { message: t("form.required_field") })
      .trim(),
    phone: z
      .string()
      .min(1, t("contact_information.phone.required"))
      .regex(phoneNumberRegex, t("contact_information.phone.wrong_format")),
  });
  const [editUser, { isLoading }] = useEditUserMutation();
  const form = useForm({
    initialValues: {
      first_name: user.first_name ?? "",
      last_name: user.last_name ?? "",
      phone: user.phone ?? "",
    },
    validate: zodResolver(schema),
  });
  const onSubmit = form.onSubmit(async (values) => {
    try {
      await editUser({
        first_name: values.first_name,
        last_name: values.last_name,
        phone: values.phone,
      }).unwrap();
      dispatch(fetchAccount());
      showNotification({
        color: "green",
        message: t("contact_information.success"),
      });
    } catch (err) {
      const error = err as any;
      console.error(error);
      form.reset();
      showNotification({
        color: "red",
        message: error.data.message,
      });
    }
  });

  return (
    <form onSubmit={onSubmit}>
      <Grid>
        <Grid.Col>
          <TextInput
            disabled={isLoading}
            label={t("contact_information.first_name")}
            withAsterisk
            {...form.getInputProps("first_name")}
          />
        </Grid.Col>
        <Grid.Col>
          <TextInput
            disabled={isLoading}
            label={t("contact_information.last_name")}
            withAsterisk
            {...form.getInputProps("last_name")}
          />
        </Grid.Col>
        <Grid.Col>
          <TextInput
            defaultValue={user.email}
            disabled={true}
            label={t("contact_information.email")}
            title={t("contact_information.email.cannot_change")}
          />
        </Grid.Col>
        <Grid.Col>
          <TextInput
            disabled={isLoading}
            label={t("contact_information.phone.label")}
            placeholder={t("contact_information.phone.placeholder")}
            withAsterisk
            {...form.getInputProps("phone")}
          />
        </Grid.Col>
        <Grid.Col>
          <Button disabled={isLoading} type="submit">
            {t("contact_information.submit")}
          </Button>
        </Grid.Col>
      </Grid>
    </form>
  );
}
