import { Button, Grid, Group, Text, TextInput, Title } from "@mantine/core";
import { useForm, zodResolver } from "@mantine/form";
import { ErrorCode } from "@wendy/types";
import React, { useMemo, useState } from "react";
import { z } from "zod";
import useFormMessage from "../../hooks/useFormMessage";
import { isApiError } from "../../lib/server";
import { forgettenPassword } from "../../store/slices/account";
import { useDispatch } from "../../store/store";
import { useTranslate } from "../../translations";

export function ForgettenPasswordForm() {
  const t = useTranslate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { isOpen, messageProps, Message, clearMessage, setError, setSuccess } = useFormMessage();
  const schema = useMemo(
    () =>
      z.object({
        email: z
          .string()
          .min(1, { message: t("forgetten_password.email.required") })
          .email({ message: t("forgetten_password.email.invalid") }),
      }),
    [t],
  );
  const form = useForm({
    initialValues: {
      email: "",
    },
    validate: zodResolver(schema),
  });
  const handleSubmit = form.onSubmit(async (values) => {
    try {
      clearMessage();
      setLoading(true);
      await dispatch(forgettenPassword(values as any)).unwrap();
      setSuccess({
        message: t("forgetten_password.success_message.message"),
        title: t("forgetten_password.success_message.title"),
      });
      form.reset();
    } catch (error) {
      if (isApiError(error)) {
        switch (error.code) {
          case ErrorCode.NOT_FOUND:
            return setError({
              message: t("forgetten_password.fail_message.title"),
              title: t("error"),
            });
        }
      }
      setError({
        message: t("error.unknown_error_type"),
        title: t("error"),
      });
    } finally {
      setLoading(false);
    }
  });

  return (
    <form onSubmit={handleSubmit}>
      <Grid>
        <Grid.Col>
          <Title align="center">{t("forgetten_password.title")}</Title>
        </Grid.Col>
        <Grid.Col>
          <Text align="center">{t("forgetten_password.note")}</Text>
        </Grid.Col>
        {isOpen && (
          <Grid.Col>
            <Message {...messageProps} />
          </Grid.Col>
        )}
        <Grid.Col>
          <TextInput
            label={t("forgetten_password.email.label")}
            withAsterisk
            {...form.getInputProps("email")}
          />
        </Grid.Col>
        <Grid.Col>
          <Group position="center">
            <Button loading={loading} type="submit">
              {t("forgetten_password.submit")}
            </Button>
          </Group>
        </Grid.Col>
      </Grid>
    </form>
  );
}
