import { EventId, MediaId, TeamId } from "@wendy/types";
import { BASE_URL_API } from "../config";

type ImageOptions = Partial<{
  ext: "avif" | "jpg" | "png" | "svg" | "webp";
  width: number;
  height: number;
}>;

function imagePath(mediaId: string, { ext = "jpg", height, width }: ImageOptions = {}): string {
  const src = `${BASE_URL_API}/gallery/${mediaId}.${ext}`;

  const querystring: string[] = [];
  if (height) {
    querystring.push(`height=${height}`);
  }
  if (width) {
    querystring.push(`width=${width}`);
  }
  return querystring.length === 0 ? src : src + "?" + querystring.join("&");
}

export function path(route: "dashboard"): string;
export function path(route: "email-verification", token: string): string;
export function path(route: "event-create"): string;
export function path(route: "event-detail", eventId: EventId): string;
export function path(route: "event-edit", eventId: EventId): string;
export function path(route: "event-invitation", token: string): string;
export function path(route: "event-list"): string;
export function path(route: "forgetten-password"): string;
export function path(route: "home"): string;
export function path(route: "image", mediaId?: MediaId): string;
export function path(route: "image", mediaId: MediaId, props?: ImageOptions): string;
export function path(route: "login"): string;
export function path(route: "my-account"): string;
export function path(route: "password-reset", token: string): string;
export function path(route: "register"): string;
export function path(route: "settings"): string;
export function path(route: "team-create"): string;
export function path(route: "team-detail", teamId: TeamId): string;
export function path(route: "team-edit", teamId: TeamId): string;
export function path(route: "team-list"): string;
export function path(route: unknown, ...params: unknown[]): unknown {
  switch (route) {
    case "dashboard":
      return `/app`;
    case "email-verification":
      return `/email-verification/${params[0]}`;
    case "event-create":
      return `/app/events/create`;
    case "event-detail":
      return `/app/events/${params[0]}/detail`;
    case "event-edit":
      return `/app/events/${params[0]}/edit`;
    case "event-invitation":
      return `/event-invitation/${params[0]}`;
    case "event-list":
      return `/app/events`;
    case "forgetten-password":
      return `/forgetten-password`;
    case "home":
      return `/`;
    case "image": {
      if (params[0] == null) {
        return imagePath("none.jpg");
      }
      return imagePath(params[0] as string, params[1] as ImageOptions);
    }
    case "login":
      return `/login`;
    case "my-account":
      return `/app/my-account`;
    case "password-reset":
      return `/password-reset/${params[0]}`;
    case "register":
      return `/register`;
    case "settings":
      return `/app/settings`;
    case "team-create":
      return `/app/teams/create`;
    case "team-detail":
      return `/app/teams/${params[0]}/detail`;
    case "team-edit":
      return `/app/teams/${params[0]}/edit`;
    case "team-list":
      return `/app/teams`;
  }
}
