import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "./baseQuery";

export const mediaApi = createApi({
  reducerPath: "mediaApi",
  baseQuery,
  endpoints: (builder) => ({
    reportMess: builder.query({
      query: () => ({
        method: "GET",
        url: `store/report-mess`,
      }),
    }),
  }),
});

export const { useReportMessQuery } = mediaApi;
