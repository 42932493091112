import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "./baseQuery";

type SignUpProps = {
  email: string;
  first_name: string;
  invitation_token?: string;
  last_name: string;
  password: string;
  phone: string;
};

export const registrationApi = createApi({
  reducerPath: "registrationApi",
  baseQuery,
  endpoints: (builder) => ({
    signUp: builder.mutation<void, SignUpProps>({
      query: (body) => ({
        body,
        method: "POST",
        url: `register`,
      }),
    }),
    verifyEmail: builder.mutation<void, { token: string }>({
      query: (body) => ({
        body,
        method: "POST",
        url: `email-verification`,
      }),
    }),
  }),
});

export const { useSignUpMutation, useVerifyEmailMutation } = registrationApi;
