import React from "react";
import { Grid, Loader } from "@mantine/core";
import { TeamCard } from "./team-card";
import { Team } from "@wendy/types";

type Props = {
  loading: boolean;
  teams: Team[];
};

export function TeamList(props: Props) {
  return (
    <Grid>
      {props.loading ? (
        <Grid.Col>
          <Loader />
        </Grid.Col>
      ) : (
        props.teams.map((team) => (
          <Grid.Col key={team.id} sm={4}>
            <TeamCard {...team} />
          </Grid.Col>
        ))
      )}
    </Grid>
  );
}
