import React from "react";
import { ActionIcon, Badge, Button, Card, createStyles, Group, Text } from "@mantine/core";
import { Link } from "react-router-dom";
import { Team } from "@wendy/types";
import { IconHeart, IconHeartFilled } from "@tabler/icons-react";
import { path } from "../../lib/paths";
import { useTranslate } from "../../translations";
import { useDispatch, useSelector } from "../../store/store";
import {
  selectIsTeamManager,
  selectNumberOfUnreadMessagesInTeam,
  selectSelectedTeamId,
} from "../../store/selectors";
import { setSelectedTeam } from "../../store/slices/teams";

const useStyles = createStyles((theme) => ({
  card: {
    transition: "box-shadow 150ms ease, transform 100ms ease",
    padding: theme.spacing.sm,
    "&:hover": {
      boxShadow: `${theme.shadows.md} !important`,
      transform: "scale(1.02)",
    },
  },
  section: {
    paddingLeft: theme.spacing.md,
    paddingRight: theme.spacing.md,
    paddingBottom: theme.spacing.md,
  },
}));

type Props = Pick<Team, "id" | "name">;

export function TeamCard(props: Props) {
  const t = useTranslate();
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const isManager = useSelector((state) => selectIsTeamManager(state, props.id));
  const selectedTeamId = useSelector(selectSelectedTeamId);
  const unreadMessages = useSelector((state) =>
    selectNumberOfUnreadMessagesInTeam(state, props.id),
  );

  return (
    <Card className={classes.card} shadow="sm" withBorder>
      <Card.Section className={classes.section}>
        <Group position="apart" mt="md">
          <Text size="lg" weight={500}>
            {props.name}
          </Text>
        </Group>
      </Card.Section>

      <Card.Section className={classes.section}>
        <Group position="right">
          {unreadMessages > 0 && (
            <Badge color="red" variant="filled">
              {t("chat.new_messages")}
            </Badge>
          )}
          {isManager ? (
            <Badge color="green">{t("role.manager")}</Badge>
          ) : (
            <Badge color="blue">{t("role.plain")}</Badge>
          )}
        </Group>
      </Card.Section>

      <Group spacing="sm">
        <Button
          component={Link}
          to={path("team-detail", props.id)}
          variant="light"
          color="blue"
          style={{ flex: 1 }}
        >
          {t("team.detail")}
        </Button>
        <ActionIcon
          size={36}
          onClick={() => dispatch(setSelectedTeam(props.id))}
          variant="outline"
          color="red"
        >
          {selectedTeamId === props.id ? (
            <IconHeartFilled size={18} />
          ) : (
            <IconHeart size={18} color="red" stroke={1.5} />
          )}
        </ActionIcon>
      </Group>
    </Card>
  );
}
