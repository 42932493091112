import { Button, Grid, Modal, TextInput } from "@mantine/core";
import { useForm, zodResolver } from "@mantine/form";
import React, { useMemo } from "react";
import { EventId } from "@wendy/types";
import { showNotification } from "@mantine/notifications";
import { z } from "zod";
import { useSendInvitationMutation } from "../../services/invitations";
import { useTranslate } from "../../translations";

type Props = {
  eventId: EventId;
  onClose: () => void;
  opened: boolean;
};

export function SendInvitationDialog({ eventId, opened, onClose }: Props) {
  const t = useTranslate();
  const formSchema = useMemo(
    () =>
      z.object({
        email: z.string().email(t("send_invitation.email.invalid")),
      }),
    [t],
  );
  const form = useForm({
    initialValues: {
      email: "",
    },
    validate: zodResolver(formSchema),
  });
  const [sendInvitation, { isLoading }] = useSendInvitationMutation();
  const handleSubmit = form.onSubmit(async (values) => {
    try {
      await sendInvitation({ eventId, email: values.email });
      showNotification({
        color: "teal",
        message: t("send_invitation.success_message_message"),
      });
      onClose();
    } catch (error) {
      showNotification({
        color: "red",
        message: t("error.unexpected_error"),
      });
    }
  });

  return (
    <Modal opened={opened} onClose={onClose} size="md" title={t("invitation")}>
      <form onSubmit={handleSubmit}>
        <Grid>
          <Grid.Col>
            <TextInput
              autoComplete="email"
              description={t("send_invitation.email.description")}
              label={t("send_invitation.email.label")}
              placeholder={t("send_invitation.email.placeholder")}
              withAsterisk
              {...form.getInputProps("email")}
            />
          </Grid.Col>
          <Grid.Col>
            <Button disabled={!form.isValid()} loading={isLoading} type="submit">
              {t("send_invitation.submit")}
            </Button>
          </Grid.Col>
        </Grid>
      </form>
    </Modal>
  );
}
