import { Container } from "@mantine/core";
import { useDocumentTitle } from "@mantine/hooks";
import React from "react";
import { useParams } from "react-router-dom";
import { EmailVerificationForm } from "../features/users";
import { useTranslate } from "../translations";

export default function EmailVerificationPage() {
  const { token } = useParams<{ token: string }>();
  const t = useTranslate();
  useDocumentTitle(t("email_verification.title"));

  return (
    <Container size="xs" mt="xl">
      <EmailVerificationForm token={token ?? ""} />
    </Container>
  );
}
