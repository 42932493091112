import React from "react";
import { Link, LinkProps } from "react-router-dom";
import { ThemeIcon, UnstyledButton, Group, Text } from "@mantine/core";

type Props = {
  color?: string;
  icon: React.ReactNode;
  label: string;
  onClick?: () => void;
  to: LinkProps["to"];
};

export function NavbarLink({ color = "blue", icon, label, onClick, to }: Props) {
  return (
    <UnstyledButton
      sx={(theme) => ({
        display: "block",
        width: "100%",
        padding: theme.spacing.xs,
        borderRadius: theme.radius.sm,
        color: theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.black,

        "&:hover": {
          backgroundColor:
            theme.colorScheme === "dark" ? theme.colors.dark[6] : theme.colors.gray[0],
        },
      })}
      component={Link}
      onClick={onClick}
      to={to}
    >
      <Group>
        <ThemeIcon color={color} variant="light">
          {icon}
        </ThemeIcon>

        <Text size="sm">{label}</Text>
      </Group>
    </UnstyledButton>
  );
}
