import {
  ActionIcon,
  Anchor,
  Badge,
  Button,
  Group,
  Indicator,
  Popover,
  ScrollArea,
  Stack,
  Text,
} from "@mantine/core";
import { IconBell } from "@tabler/icons-react";
import React from "react";
import { Link } from "react-router-dom";
import { path } from "../../lib/paths";
import { indicatorCount } from "../../lib/utils";
import {
  selectMessageCountIsLoading,
  selectTotalCountOfUnreadMessages,
  selectUnreadEvents,
  selectUnreadTeams,
} from "../../store/selectors";
import { readAllEventMessages, readAllTeamMessages } from "../../store/slices/chat";
import { useDispatch, useSelector } from "../../store/store";
import { useTranslate } from "../../translations";

export function UnreadMessagesIcon() {
  const t = useTranslate();
  const dispatch = useDispatch();
  const loading = useSelector(selectMessageCountIsLoading);
  const unreadEvents = useSelector(selectUnreadEvents);
  const unreadTeams = useSelector(selectUnreadTeams);
  const totalCountOfUnreadMessages = useSelector(selectTotalCountOfUnreadMessages);
  const hasUnreadMessages = totalCountOfUnreadMessages > 0;

  return (
    <Popover withArrow trapFocus>
      <Popover.Target>
        <Indicator
          color="red"
          disabled={!hasUnreadMessages}
          label={indicatorCount(totalCountOfUnreadMessages)}
          mr={hasUnreadMessages ? "md" : void 0}
          offset={7}
          position="top-end"
          processing={loading}
          size={16}
        >
          <ActionIcon
            size="sm"
            title={hasUnreadMessages ? t("chat.unread_messages") : t("chat.no_messages")}
          >
            <IconBell />
          </ActionIcon>
        </Indicator>
      </Popover.Target>
      <Popover.Dropdown>
        {hasUnreadMessages ? (
          <ScrollArea.Autosize mah={400}>
            <Stack>
              {unreadEvents.length > 0 && (
                <Group position="apart">
                  <Text size="sm" c="dimmed">
                    {t("chat.event_messages")}
                  </Text>
                  <Button
                    size="xs"
                    variant="subtle"
                    onClick={() => dispatch(readAllEventMessages())}
                    loading={loading}
                  >
                    {t("chat.mark_all_read")}
                  </Button>
                </Group>
              )}
              {unreadEvents.map(({ target, count, name }) => (
                <Group position="apart" key={target}>
                  <Anchor component={Link} to={path("event-detail", target)}>
                    {name}
                  </Anchor>
                  <Badge>{indicatorCount(count)}</Badge>
                </Group>
              ))}
              {unreadTeams.length > 0 && (
                <Group position="apart">
                  <Text size="sm" c="dimmed">
                    {t("chat.team_messages")}
                  </Text>
                  <Button
                    size="xs"
                    variant="subtle"
                    onClick={() => dispatch(readAllTeamMessages())}
                    loading={loading}
                  >
                    {t("chat.mark_all_read")}
                  </Button>
                </Group>
              )}
              {unreadTeams.map(({ target, count, name }) => (
                <Group position="apart" key={target}>
                  <Anchor component={Link} to={path("team-detail", target)}>
                    {name}
                  </Anchor>
                  <Badge>{indicatorCount(count)}</Badge>
                </Group>
              ))}
            </Stack>
          </ScrollArea.Autosize>
        ) : (
          <Text>{t("chat.no_messages")}</Text>
        )}
      </Popover.Dropdown>
    </Popover>
  );
}
