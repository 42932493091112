import { Button, Grid, TextInput } from "@mantine/core";
import { useForm, zodResolver } from "@mantine/form";
import React, { useState } from "react";
import z from "zod";
import { useTranslate } from "../../translations";
import { Team } from "@wendy/types";
import { useDispatch } from "../../store/store";
import { editTeam } from "../../store/slices/teams";
import useFormMessage from "../../hooks/useFormMessage";

export type Props = {
  team: Team;
};

export function TeamEditForm({ team }: Props) {
  const t = useTranslate();
  const schema = z.object({
    name: z.string().min(1, { message: t("form.required_field") }),
  });
  const form = useForm({
    initialValues: {
      name: team.name,
    },
    validate: zodResolver(schema),
  });
  const { isOpen, messageProps, Message, setError } = useFormMessage();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleSubmit = form.onSubmit(async (values) => {
    try {
      setLoading(true);
      await dispatch(
        editTeam({
          id: team.id,
          name: values.name,
        }),
      ).unwrap();
    } catch (error) {
      console.error(error);
      setError({
        message: t("error.unknown_error_type"),
        title: t("error"),
      });
    } finally {
      setLoading(false);
    }
  });

  return (
    <form onSubmit={handleSubmit}>
      <Grid>
        {isOpen && (
          <Grid.Col>
            <Message {...messageProps} />
          </Grid.Col>
        )}
        <Grid.Col>
          <TextInput label={t("team_form.name")} withAsterisk {...form.getInputProps("name")} />
        </Grid.Col>
        <Grid.Col>
          <Button loading={loading} type="submit">
            {t("team_form.save")}
          </Button>
        </Grid.Col>
      </Grid>
    </form>
  );
}
