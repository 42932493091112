import { Button, Grid, Text, TextInput, Title } from "@mantine/core";
import { useForm, zodResolver } from "@mantine/form";
import React, { useState } from "react";
import z from "zod";
import { useTranslate } from "../../translations";
import useFormMessage from "../../hooks/useFormMessage";
import { Team } from "@wendy/types";
import { useDispatch } from "../../store/store";
import { addTeam } from "../../store/slices/teams";
import { fetchMembers } from "../../store/slices/members";

type Props = {
  onSuccess: (team: Team) => void;
};

export function TeamCreateForm({ onSuccess }: Props) {
  const t = useTranslate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const schema = z.object({
    name: z.string().min(1, { message: t("form.required_field") }),
  });
  const { isOpen, messageProps, Message, setError } = useFormMessage();
  const form = useForm({
    initialValues: {
      name: "",
    },
    validate: zodResolver(schema),
  });

  const handleSubmit = form.onSubmit(async (values) => {
    const id = crypto.randomUUID();
    try {
      setLoading(true);
      onSuccess(await dispatch(addTeam({ ...values, id })).unwrap());
      void dispatch(fetchMembers());
    } catch (error) {
      console.error(error);
      setError({
        message: t("team_create_form.error"),
        title: t("error"),
      });
    } finally {
      setLoading(false);
    }
  });

  return (
    <form onSubmit={handleSubmit}>
      <Title mb="lg" size="h2">
        {t("team_form.new_team")}
      </Title>

      <Grid>
        <Grid.Col>
          <Text>{t("team_create_form.note")}</Text>
        </Grid.Col>
        {isOpen && (
          <Grid.Col>
            <Message {...messageProps} />
          </Grid.Col>
        )}
        <Grid.Col>
          <TextInput label={t("team_form.name")} {...form.getInputProps("name")} />
        </Grid.Col>
        <Grid.Col>
          <Button loading={loading} type="submit" variant="filled">
            {t("team_form.create")}
          </Button>
        </Grid.Col>
      </Grid>
    </form>
  );
}
