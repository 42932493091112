import { Button, FileInput, Group, Stack } from "@mantine/core";
import z from "zod";
import { useMemo, useState } from "react";
import { useForm, zodResolver } from "@mantine/form";
import useFormMessage from "../../hooks/useFormMessage";
import { useDispatch } from "../../store/store";
import { useTranslate } from "../../translations";
import { fetchAccount } from "../../store/slices/account";
import { api } from "../../lib/api";
import { CurrentUserAvatar } from "../ui/current-user-avatar";

export function AvatarForm() {
  const t = useTranslate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const schema = useMemo(
    () =>
      z.object({
        file: z.instanceof(File).and(
          z.object({
            size: z.number().max(1 * 1024 * 1024),
            type: z.enum(["image/avif", "image/jpeg", "image/png", "image/svg+xml", "image/webp"]),
          }),
        ),
      }),
    [],
  );
  const form = useForm<{ file: File | null }>({
    initialValues: {
      file: null,
    },
    validate: zodResolver(schema),
  });
  const { isOpen, messageProps, Message, setError, setSuccess } = useFormMessage();
  const onSubmit = form.onSubmit(async (values) => {
    if (values.file == null) {
      return;
    }
    setLoading(true);
    try {
      const avatar = crypto.randomUUID();
      const formData = new FormData();
      formData.append("file", values.file);
      formData.append("id", avatar);
      await api.post("/user/avatar", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      await dispatch(fetchAccount()).unwrap();

      setSuccess({
        title: "Success",
        message: t("avatar_form.success"),
      });
    } catch (err) {
      const error = err as any;
      console.error(error);
      setError({
        title: t("error.unknown_error_type"),
        message: error.data.message,
      });
    } finally {
      setLoading(false);
    }
  });

  return (
    <form onSubmit={onSubmit}>
      <Stack>
        <CurrentUserAvatar size={100} />
        {isOpen && <Message {...messageProps} />}
        <FileInput
          accept="image/png,image/jpeg,image/svg"
          placeholder="Pick file"
          label="Your avatar"
          withAsterisk
          {...form.getInputProps("file")}
        />
        <Group>
          <Button loading={loading} type="submit">
            {t("avatar_form.submit")}
          </Button>
        </Group>
      </Stack>
    </form>
  );
}
