import { createContext, useContext } from "react";
import cs from "./cs";
import { Locale, Translate, Vocabulary } from "./types";

const translations: Record<Locale, Vocabulary> = { cs };
export const TranslationContext = createContext<Locale>("cs");

export function useLocale(): Locale {
  return useContext(TranslationContext);
}

export function useTranslate(): Translate {
  const locale = useLocale();
  return (key, ...params) => {
    if (key === "?") {
      return String(params[0]);
    }
    return translations[locale][key];
  };
}
