import { Container, Grid, Title } from "@mantine/core";
import { useDocumentTitle } from "@mantine/hooks";
import React, { useEffect } from "react";
import { EventCard } from "../features/events";
import { useSearchEventsMutation } from "../services/events";
import { useTranslate } from "../translations";

export default function DashboardPage() {
  const t = useTranslate();
  useDocumentTitle(t("dashboard.title"));
  const [searchEvents, { data }] = useSearchEventsMutation();

  useEffect(() => {
    searchEvents({
      filters: {
        time: "future",
      },
      pageNumber: 1,
      pageSize: 6,
    });
  }, [searchEvents]);

  return (
    <Container size="xl">
      <Title mb="lg" size="h2">
        {t("dashboard.upcomming_events")}
      </Title>

      <Grid>
        {data?.rows.map((row) => (
          <Grid.Col key={row.event.id} sm={4}>
            <EventCard {...row} />
          </Grid.Col>
        ))}
      </Grid>
    </Container>
  );
}
