import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import "dayjs/locale/cs";
import dayjs from "dayjs";

import store from "./store/store";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { TranslationContext } from "./translations";

dayjs.locale("cs");

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <Provider store={store}>
    <TranslationContext.Provider value="cs">
      <App />
    </TranslationContext.Provider>
  </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
