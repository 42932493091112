import axios, { AxiosError } from "axios";
import { BASE_URL_API } from "../config";
import { tokenKey } from "../store/slices/account";

export const api = axios.create({
  baseURL: BASE_URL_API,
  headers: {
    "Content-Type": "application/json",
  },
});

api.interceptors.request.use((config) => {
  const token = localStorage.getItem(tokenKey);
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});

export function handleError(error: unknown) {
  const e = error as AxiosError;
  if (e.response) {
    throw e.response.data;
  }
  throw error;
}
