import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { path } from "../lib/paths";
import { UserViewFull } from "@wendy/types";
import { selectAccount } from "../store/selectors";

export default function useCurrentUser(): UserViewFull {
  const auth = useSelector(selectAccount);
  const navigate = useNavigate();

  useEffect(() => {
    if (!auth.user) {
      navigate(path("login"));
    }
  }, [navigate, auth.user]);

  return auth.user!;
}
