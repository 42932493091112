import { Badge, Button, Grid, Group, Menu, Modal, Tabs, Text, Title } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import React, { useState } from "react";
import type { Member, Team, UserId } from "@wendy/types";
import { Link, useNavigate } from "react-router-dom";
import {
  IconEdit,
  IconMap,
  IconMenu,
  IconMessageCircle,
  IconTrash,
  IconUsers,
} from "@tabler/icons-react";
import { useTranslate } from "../../translations";
import { path } from "../../lib/paths";
import { UserLink } from "./user-link";
import { TeamChat } from "./team-chat";
import { useDispatch, useSelector } from "../../store/store";
import {
  selectMessageCountIsLoading,
  selectNumberOfUnreadMessagesInTeam,
} from "../../store/selectors";
import { indicatorCount } from "../../lib/utils";
import { LocationList } from "../../features/locations";
import { UserModal } from "../ui/user-modal";
import { removeTeam } from "../../store/slices/teams";

type Props = {
  asManager: boolean;
  members: Member[];
  team: Team;
};

export function TeamDetail({ asManager, members, team }: Props) {
  const t = useTranslate();
  const navigate = useNavigate();
  const [opened, setOpened] = useState(false);
  const dispatch = useDispatch();
  const [userDetailOpened, setUserDetailOpened] = useState<UserId | null>(null);
  const numberOfUnreadMessages = useSelector((state) =>
    selectNumberOfUnreadMessagesInTeam(state, team.id),
  );
  const messageCountIsLoading = useSelector(selectMessageCountIsLoading);

  return (
    <>
      <Modal
        centered
        closeOnEscape
        closeOnClickOutside
        opened={opened}
        onClose={() => setOpened(false)}
        title={t("attention")}
      >
        <Text mb="lg">{t("team.remove_confirmation_message")}</Text>
        <Group position="right">
          <Button
            color="red"
            size="xs"
            onClick={async () => {
              try {
                await dispatch(removeTeam(team.id));
                navigate(path("team-list"));
              } catch (error: any) {
                console.error(error);
                showNotification({
                  color: "red",
                  message: error.data.message,
                });
                setOpened(false);
              }
            }}
          >
            {t("remove")}
          </Button>
        </Group>
      </Modal>

      <UserModal onClose={() => setUserDetailOpened(null)} userId={userDetailOpened} />

      <Group position="apart" mb="lg">
        <Text>{t("team.detail")}</Text>

        {asManager && (
          <Menu>
            <Menu.Target>
              <Button variant="subtle" rightIcon={<IconMenu size={14} />}>
                {t("actions")}
              </Button>
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Item
                icon={<IconEdit size={14} />}
                component={Link}
                to={path("team-edit", team.id)}
              >
                {t("edit")}
              </Menu.Item>
              <Menu.Item color="red" icon={<IconTrash size={14} />} onClick={() => setOpened(true)}>
                {t("remove")}
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        )}
      </Group>

      <Title size="h2" mb="lg">
        {team.name}
      </Title>

      <Tabs defaultValue="members" keepMounted={false}>
        <Tabs.List>
          <Tabs.Tab value="members" icon={<IconUsers size={14} />}>
            {t("team_form.members")}
          </Tabs.Tab>
          <Tabs.Tab value="chat" icon={<IconMessageCircle size={14} />}>
            {t("chat.title")}
            {numberOfUnreadMessages > 0 ? (
              <Badge
                color={messageCountIsLoading ? "gray" : "red"}
                ml="xs"
                variant="filled"
                size="xs"
              >
                {indicatorCount(numberOfUnreadMessages)}
              </Badge>
            ) : null}
          </Tabs.Tab>
          {asManager && (
            <Tabs.Tab value="locations" icon={<IconMap size={14} />}>
              {t("team_detail.locations")}
            </Tabs.Tab>
          )}
        </Tabs.List>

        <Tabs.Panel value="members" p="lg">
          <Title order={4}>{t("managers")}</Title>
          <Grid my="lg">
            {members
              .filter((member) => member.role === "manager")
              .map((member) => (
                <Grid.Col key={member.user} lg={6}>
                  <UserLink id={member.user} onClick={() => setUserDetailOpened(member.user)} />
                </Grid.Col>
              ))}
          </Grid>

          <Title order={4}>{t("plain_users")}</Title>
          <Grid my="lg">
            {members
              .filter((member) => member.role === "plain")
              .map((member) => (
                <Grid.Col key={member.user} lg={6}>
                  <UserLink id={member.user} onClick={() => setUserDetailOpened(member.user)} />
                </Grid.Col>
              ))}
          </Grid>
        </Tabs.Panel>

        <Tabs.Panel value="chat">
          <TeamChat teamId={team.id} />
        </Tabs.Panel>

        {asManager && (
          <Tabs.Panel value="locations" p="lg">
            <LocationList teamId={team.id} />
          </Tabs.Panel>
        )}
      </Tabs>
    </>
  );
}
