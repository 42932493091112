import { Progress, Text } from "@mantine/core";
import { AttendeeState } from "@wendy/types";
import React from "react";
import { useTranslate } from "../../translations";

type Props = Record<AttendeeState, number> & { capacity: number };

export function AttendanceProgress({ accepted, capacity, pending }: Props) {
  const t = useTranslate();
  const total = capacity + pending;
  const acceptedPart = Math.round((100 * accepted) / total);
  const queuePart = Math.round((100 * pending) / total);
  const emptyPart = 100 - acceptedPart - queuePart;

  return (
    <>
      <Text color="dimmed" size="xs">
        {t("event.attendance")}
        {": "}
        <Text
          span
          weight={500}
          sx={(theme) => ({ color: theme.colorScheme === "dark" ? theme.white : theme.black })}
        >
          {accepted}/{capacity}
          {pending > 0 ? `(${pending})` : ""}
        </Text>
      </Text>
      <Progress
        mt={5}
        size="sm"
        sections={[
          { value: acceptedPart, color: "green" },
          { value: emptyPart, color: "gray" },
          { value: queuePart, color: "yellow" },
        ]}
      />
    </>
  );
}
