import { Button, Container, Group, Loader, Menu, Text } from "@mantine/core";
import { useDocumentTitle } from "@mantine/hooks";
import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { EventId } from "@wendy/types";
import { IconCopy, IconEdit, IconMail, IconMenu, IconTrash } from "@tabler/icons-react";
import { useGetEventQuery } from "../services/events";
import { useTranslate } from "../translations";
import { path } from "../lib/paths";
import { EventDetail, SendInvitationDialog } from "../features/events";
import { ErrorAlert } from "../features/ui/error-alert";
import { useSelector } from "../store/store";
import { selectIsTeamManager } from "../store/selectors";
import Error404Page from "./error-404";
import { useGetAttendanceQuery } from "../services/attendance";
import { getPathWithClone } from "./event-create";

function Wrapper({ id }: { id: EventId }) {
  const [openInvitation, setOpenInvitation] = useState(false);
  const {
    data: event,
    isLoading: isEventLoading,
    error: eventError,
    refetch: refetchEvent,
  } = useGetEventQuery(id);
  const {
    data: attendees,
    isLoading: isAttendeesLoading,
    error: attendeesError,
    refetch: refetchAttendees,
  } = useGetAttendanceQuery(id);
  const isManager = useSelector((state) => event && selectIsTeamManager(state, event.team));
  const t = useTranslate();

  useDocumentTitle(t("event.detail"));

  return (
    <Container size="sm">
      <Group position="apart" mb="lg">
        <Text>{t("event.detail")}</Text>

        <Menu>
          <Menu.Target>
            <Button variant="subtle" rightIcon={<IconMenu size={14} />}>
              {t("actions")}
            </Button>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Item icon={<IconMail size={14} />} onClick={() => setOpenInvitation(true)}>
              {t("send_invitation")}
            </Menu.Item>
            {isManager && event && (
              <>
                <Menu.Divider />
                <Menu.Item
                  component={Link}
                  icon={<IconCopy size={14} />}
                  to={getPathWithClone(event.id)}
                >
                  {t("event.clone")}
                </Menu.Item>
                <Menu.Item
                  component={Link}
                  icon={<IconEdit size={14} />}
                  to={path("event-edit", event.id)}
                >
                  {t("edit")}
                </Menu.Item>
                <Menu.Item
                  icon={<IconTrash size={14} />}
                  color="red"
                  onClick={() => alert("Tohle ještě není naprogramované!")}
                >
                  {t("remove")}
                </Menu.Item>
              </>
            )}
          </Menu.Dropdown>
        </Menu>
      </Group>

      {eventError && <ErrorAlert error={eventError} />}
      {attendeesError && <ErrorAlert error={attendeesError} />}
      {(isAttendeesLoading || isEventLoading) && <Loader />}
      {attendees && event && (
        <EventDetail
          attendees={attendees}
          event={event}
          onChange={() => {
            refetchEvent();
            refetchAttendees();
          }}
        />
      )}
      {event && (
        <SendInvitationDialog
          eventId={event.id}
          opened={openInvitation}
          onClose={() => setOpenInvitation(false)}
        />
      )}
    </Container>
  );
}

export default function EventDetailPage() {
  const { id } = useParams<{ id: EventId }>();
  return id ? <Wrapper id={id} /> : <Error404Page />;
}
