import { useEffect, useRef, useState } from "react";
import { BASE_WS_URL } from "../config";
import {
  countUnreadMessages,
  getMessagesForEvent,
  getMessagesForTeam,
  setConnected,
} from "../store/slices/chat";
import { fetchTeams } from "../store/slices/teams";
import { fetchFriends } from "../store/slices/users";
import { useDispatch, useSelector } from "../store/store";

export function useWebsocket(): void {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.account.token);
  const [reconnectTimer, setReconnectTimer] = useState<ReturnType<typeof setTimeout> | null>(null);
  const ws = useRef<WebSocket | null>(null);

  useEffect(() => {
    if (!token) {
      ws.current?.close();
      return;
    }
    if (reconnectTimer) {
      return;
    }
    const apiUrl = new URL(BASE_WS_URL);
    apiUrl.searchParams.set("token", token);
    const socket = new WebSocket(apiUrl.toString());
    socket.onopen = () => {
      dispatch(setConnected(true));
    };
    socket.onclose = () => {
      dispatch(setConnected(false));
      setReconnectTimer(setTimeout(() => setReconnectTimer(null), 5000));
    };
    socket.onerror = (e) => {
      console.error(e);
    };
    socket.onmessage = (event) => {
      try {
        const data = JSON.parse(event.data);
        if (data.type === "team_chat_message") {
          dispatch(getMessagesForTeam(data.team));
          dispatch(countUnreadMessages());
        } else if (data.type === "event_chat_message") {
          dispatch(getMessagesForEvent(data.event));
          dispatch(countUnreadMessages());
        } else if (data.type === "team_member_change") {
          dispatch(fetchTeams());
          dispatch(fetchFriends());
        } else if (data.type === "user_change") {
          dispatch(fetchFriends());
        }
      } catch (err) {
        console.error(err);
      }
    };

    ws.current = socket;

    return () => {
      if (socket.readyState === WebSocket.OPEN) {
        socket.close();
      }
      if (reconnectTimer) {
        clearTimeout(reconnectTimer);
      }
    };
  }, [token, dispatch, reconnectTimer]);
}
