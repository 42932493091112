export const indicatorCount = (count: number): string => (count > 100 ? "99+" : String(count));

const regexpGPS = /^-?\d{1,2}(\.\d{1,15})N?, ?-?\d{1,3}(\.\d{1,15})E?$/;

/**
 * Returns true, if the value looks like GPS coordinates:
 *  - example for google.com/maps: "49.160486723664434, 16.537926611242426"
 *  - example for mapy.cz: "49.1604644N, 16.5378842E"
 *  - example for openstreetmap.org: "49.16047, 16.53787"
 */
function isGPS(text: string): boolean {
  return regexpGPS.test(text);
}

/**
 * Parses GPS if the value looks like GPS coordinates:
 * @see isGPS
 */
export function parseGPS(text: string): { lat: string; long: string } | null {
  if (!isGPS(text)) {
    return null;
  }
  const [lat, long] = text.split(",").map((x) => x.replace(/[^-\d.]/g, ""));
  return { lat, long };
}
