import { FC, useCallback, useState } from "react";
import { Alert } from "@mantine/core";
import { IconAlertCircle, IconCircleCheck } from "@tabler/icons-react";

type ErrorMessageProps = {
  type: "error";
  message: string;
  title: string;
};
type SuccessMessageProps = {
  type: "success";
  message: string;
  title: string;
};
type NullMessageProps = {
  type: "null";
};
export type MessageProps = ErrorMessageProps | SuccessMessageProps | NullMessageProps;

const SuccessMessage: FC<Omit<SuccessMessageProps, "type">> = ({ message, title }) => (
  <Alert color="teal" icon={<IconCircleCheck size={16} />} title={title}>
    {message}
  </Alert>
);
const ErrorMessage: FC<Omit<ErrorMessageProps, "type">> = ({ message, title }) => (
  <Alert color="red" icon={<IconAlertCircle size={16} />} title={title}>
    {message}
  </Alert>
);

const Message: FC<MessageProps> = (props) => {
  if (props.type === "null") {
    return null;
  }
  if (props.type === "success") {
    return <SuccessMessage {...props} />;
  }
  return <ErrorMessage {...props} />;
};

export default function useFormMessage() {
  const [messageProps, setMessageProps] = useState<MessageProps>({
    type: "null",
  });
  const clearMessage = useCallback(() => setMessageProps({ type: "null" }), []);
  const setError = useCallback(
    (props: Omit<ErrorMessageProps, "type">) => setMessageProps({ ...props, type: "error" }),
    [],
  );
  const setSuccess = useCallback(
    (props: Omit<SuccessMessageProps, "type">) => setMessageProps({ ...props, type: "success" }),
    [],
  );
  return {
    isOpen: messageProps.type !== "null",
    messageProps,
    clearMessage,
    setError,
    setSuccess,
    Message,
  };
}
