import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Member } from "@wendy/types";
import { api } from "../../lib/api";

export type MembersState = {
  loading: boolean;
  members: Member[];
};

export const fetchMembers = createAsyncThunk<Member[], void>("members/fetchMembers", async () => {
  const response = await api.get(`/members`);
  return response.data;
});

export const setMemberRole = createAsyncThunk<Member, Member>(
  "members/setMemberRole",
  async ({ role, team, user }: Member) => {
    const response = await api.put(`/members/${team}`, { role, user });
    return response.data;
  },
);

const initialState: MembersState = {
  loading: true,
  members: [],
};

const slice = createSlice({
  name: "members",
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchMembers.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchMembers.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.members = payload;
    });
    builder.addCase(setMemberRole.fulfilled, (state, { meta }) => {
      state.members = state.members.map((x) =>
        x.team === meta.arg.team && x.user === meta.arg.user ? meta.arg : x,
      );
    });
  },
  initialState,
});

export default slice.reducer;
