import { Button, Container, Group } from "@mantine/core";
import { ErrorCode, Invitation } from "@wendy/types";
import React from "react";
import useFormMessage from "../../hooks/useFormMessage";
import { isApiError } from "../../lib/server";
import { useAcceptInvitationMutation } from "../../services/invitations";
import { useTranslate } from "../../translations";

type Props = {
  invitation: Invitation;
  onSuccess?: () => void;
};

export function EventInvitationAcceptForm({ invitation, onSuccess }: Props) {
  const [accept, { isLoading }] = useAcceptInvitationMutation();
  const t = useTranslate();
  const { isOpen, messageProps, Message, setError, setSuccess } = useFormMessage();
  const handleAccept = async () => {
    try {
      await accept({ token: invitation.id }).unwrap();
      setSuccess({
        message: t("event_invitation.success_message.message"),
        title: t("event_invitation.success_message.title"),
      });
      onSuccess?.();
    } catch (error) {
      if (isApiError(error)) {
        switch (error.code) {
          case ErrorCode.NOT_FOUND:
            return setError({
              message: t("login_form.credentials_not_valid"),
              title: t("error"),
            });
        }
      }
      setError({
        message: t("error.unknown_error_type"),
        title: t("error"),
      });
    }
  };

  return (
    <Container>
      {isOpen && <Message {...messageProps} />}
      <Group position="center">
        <Button loading={isLoading} onClick={handleAccept} size="lg">
          {t("event_invitation.submit")}
        </Button>
      </Group>
    </Container>
  );
}
