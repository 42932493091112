import { Group, Text, UnstyledButton, useMantineTheme } from "@mantine/core";
import { UserId } from "@wendy/types";
import { selectUserName } from "../../store/selectors";
import { useSelector } from "../../store/store";
import { UserAvatar } from "../ui/user-avatar";

export type Props = {
  id: UserId;
  onClick?: () => void;
};

export function UserLink({ id, onClick }: Props) {
  const theme = useMantineTheme();
  const userName = useSelector((state) => selectUserName(state, id));
  return (
    <UnstyledButton
      sx={{
        display: "block",
        width: "100%",
        padding: theme.spacing.xs,
        borderRadius: theme.radius.sm,
        color: theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.black,

        "&:hover": {
          backgroundColor:
            theme.colorScheme === "dark" ? theme.colors.dark[6] : theme.colors.gray[0],
        },
      }}
      onClick={() => onClick?.()}
    >
      <Group noWrap>
        <UserAvatar userId={id} />
        <div>
          <Text size="sm">{userName}</Text>
        </div>
      </Group>
    </UnstyledButton>
  );
}
