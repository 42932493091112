import {
  Anchor,
  Badge,
  Button,
  Card,
  createStyles,
  Grid,
  Group,
  Stack,
  Tabs,
  Text,
  TypographyStylesProvider,
} from "@mantine/core";
import React, { useCallback, useMemo, useState } from "react";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { IconMessageCircle, IconUserOff, IconUserPlus, IconUsers } from "@tabler/icons-react";
import { Attendee, AttendeeState, ErrorCode, Event, UserId } from "@wendy/types";
import { useTranslate } from "../../translations";
import useCurrentUser from "../../hooks/useCurrentUser";
import { path } from "../../lib/paths";
import { EventChat } from "./event-chat";
import { useDispatch, useSelector } from "../../store/store";
import {
  selectLocation,
  selectMessageCountIsLoading,
  selectNumberOfUnreadMessagesInEvent,
  selectTeam,
} from "../../store/selectors";
import { indicatorCount } from "../../lib/utils";
import { AttendeeRow } from "./attendee-row";
import { LocationView } from "../locations";
import { UserModal } from "../ui/user-modal";
import { useChangeAttendanceMutation } from "../../services/attendance";
import { AttendanceChangeConfirmation } from "./attendance-change-confirmation";
import { fetchCredit } from "../../store/slices/account";
import { isRtkError } from "../../lib/server";
import { showNotification } from "@mantine/notifications";

const useStyles = createStyles((theme) => ({
  section: {
    padding: theme.spacing.sm,
    "&:not(:last-child)": {
      borderBottom: `1px solid ${
        theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
      }`,
    },
  },
}));

const attendeeAccepted = ({ state }: { state: AttendeeState }) => state === "accepted";
const attendeePending = ({ state }: { state: AttendeeState }) => state === "pending";

type Props = {
  attendees: Attendee[];
  event: Event;
  onChange: () => void;
};

export function EventDetail({ attendees, event, onChange }: Props) {
  const { classes } = useStyles();
  const t = useTranslate();
  const dispatch = useDispatch();
  const user = useCurrentUser();
  const myAttendance = attendees.find((x) => x.user === user.id)?.state;
  const numberOfAccepted = attendees.filter(attendeeAccepted).length;
  const numberOfPending = attendees.filter(attendeePending).length;
  const hasFreeCapacity = numberOfAccepted < event.capacity;
  const numberOfUnreadMessages = useSelector((state) =>
    selectNumberOfUnreadMessagesInEvent(state, event.id),
  );
  const messageCountIsLoading = useSelector(selectMessageCountIsLoading);
  const team = useSelector((state) => (event.team ? selectTeam(state, event.team) : null));
  const location = useSelector((state) =>
    event.location ? selectLocation(state, event.location) : null,
  );
  const [changeAttendaceState, { isLoading }] = useChangeAttendanceMutation();
  type DialogType = "accept" | "cancel";
  const [dialog, setDialog] = useState<DialogType | null>(null);
  const [userOpened, setUserOpened] = useState<UserId | null>(null);
  const currencyFormat = useMemo(
    () => new Intl.NumberFormat("cs-CZ", { style: "currency", currency: "CZK" }),
    [],
  );
  const setState = useCallback(
    async (state: "accepted" | "canceled") => {
      try {
        await changeAttendaceState({
          eventId: event.id,
          state,
        }).unwrap();
        dispatch(fetchCredit());
        onChange();
      } catch (error) {
        if (isRtkError(error)) {
          switch (error.data.code) {
            case ErrorCode.CANNOT_CROSS_DEBIT:
              return showNotification({
                color: "red",
                message: t("event.error.cannot_cross_debit"),
                title: t("error"),
              });
            case ErrorCode.NOT_ENOUGH_CREDIT:
              return showNotification({
                color: "red",
                message: t("event.error.not_enough_credit"),
                title: t("error"),
              });
          }
        }
        showNotification({
          color: "red",
          message: t("error.unknown_error_type"),
          title: t("error"),
        });
      }
    },
    [changeAttendaceState, dispatch, event.id, onChange, t],
  );
  const cancelAttendance = useCallback(async () => {
    if (event.cancelationFee || event.price) {
      setDialog("cancel");
    } else {
      await changeAttendaceState({
        eventId: event.id,
        state: "canceled",
      });
      onChange();
    }
  }, [changeAttendaceState, event.id, event.price, event.cancelationFee, onChange]);

  return (
    <>
      <Grid mb="md">
        <Grid.Col>
          <Card withBorder>
            <Card.Section className={classes.section}>
              <Text size="lg" weight={700}>
                {event.name}
              </Text>
            </Card.Section>
            {event.description && (
              <Card.Section className={classes.section}>
                <TypographyStylesProvider>
                  <div dangerouslySetInnerHTML={{ __html: event.description }}></div>
                </TypographyStylesProvider>
              </Card.Section>
            )}
            <Card.Section className={classes.section}>
              <Group position="apart">
                <Group position="left">
                  <Text fz="sm" c="dimmed">
                    {t("event.date")}:
                  </Text>
                  <Text fz="sm">{dayjs(event.time).format("dddd, D. MMMM YYYY H:mm")}</Text>
                </Group>
                <Group position="left">
                  <Text fz="sm" c="dimmed">
                    {t("event.team")}:
                  </Text>
                  <Anchor
                    size="sm"
                    color="violet"
                    component={Link}
                    to={path("team-detail", event.team)}
                  >
                    {team?.name}
                  </Anchor>
                </Group>
              </Group>
            </Card.Section>
            {location && (
              <Card.Section className={classes.section}>
                <Group position="left">
                  <Text c="dimmed" fz="sm">
                    {t("event_edit.location")}:
                  </Text>
                  {location && <LocationView location={location} />}
                </Group>
              </Card.Section>
            )}
          </Card>
        </Grid.Col>
        <Grid.Col>
          <Card withBorder>
            <Card.Section className={classes.section}>
              <Group spacing="sm" position="apart">
                <Group spacing="xs">
                  {hasFreeCapacity ? (
                    <Badge color="green" variant="outline">
                      {t("event.free_places")}
                    </Badge>
                  ) : (
                    <Badge color="red" variant="outline">
                      {t("event_detail.occupied")}
                    </Badge>
                  )}
                  {myAttendance === "accepted" && (
                    <Badge color="green" variant="outline">
                      {t("event_detail.my_state.accepted")}
                    </Badge>
                  )}
                  {myAttendance === "pending" && (
                    <Badge color="gray" variant="outline">
                      {t("event_detail.my_state.pending")}
                    </Badge>
                  )}
                  {myAttendance === "rejected" && (
                    <Badge color="red" variant="outline">
                      {t("event_detail.my_state.rejected")}
                    </Badge>
                  )}
                  {myAttendance === "canceled" && (
                    <Badge color="red" variant="outline">
                      {t("event_detail.my_state.canceled")}
                    </Badge>
                  )}
                </Group>
                <Group position="right" spacing={5}>
                  {event.price && (
                    <>
                      <Text color="dimmed">{t("event.price")}:</Text>
                      <Text weight={700} mr="sm">
                        {currencyFormat.format(event.price)}
                      </Text>
                    </>
                  )}
                  {myAttendance === "accepted" || myAttendance === "pending" ? (
                    <Button
                      size="xs"
                      color="red"
                      leftIcon={<IconUserOff size={14} />}
                      loading={isLoading}
                      onClick={cancelAttendance}
                    >
                      {t("event_detail.attendance_cancel")}
                    </Button>
                  ) : (
                    <Button
                      size="xs"
                      color="green"
                      leftIcon={<IconUserPlus size={14} />}
                      loading={isLoading}
                      onClick={async () => {
                        if (event.cancelationFee || event.price) {
                          setDialog("accept");
                        } else {
                          await setState("accepted");
                        }
                      }}
                    >
                      {hasFreeCapacity
                        ? t("event_detail.attendance_accept")
                        : t("event_detail.attendance_wait")}
                    </Button>
                  )}
                </Group>
              </Group>
            </Card.Section>
            <Card.Section className={classes.section}>
              <Group position="left">
                <Text fz="sm" c="dimmed">
                  {t("event_edit.capacity")}:
                </Text>
                <Badge>{event.capacity}</Badge>
                {numberOfAccepted < event.capacity && (
                  <>
                    <Text c="dimmed" fz="sm">
                      {t("event_detail.free_slots")}:
                    </Text>
                    <Badge>{event.capacity - numberOfAccepted}</Badge>
                  </>
                )}
                {numberOfAccepted > event.capacity && (
                  <>
                    <Text c="dimmed" fz="sm">
                      {t("event_detail.queue_length")}:
                    </Text>
                    <Badge>{numberOfPending}</Badge>
                  </>
                )}
              </Group>
            </Card.Section>
          </Card>
        </Grid.Col>
      </Grid>

      <Tabs keepMounted={false} defaultValue="attendees">
        <Tabs.List>
          <Tabs.Tab value="attendees" icon={<IconUsers size={14} />}>
            {t("event.attendees")}
          </Tabs.Tab>
          <Tabs.Tab value="chat" icon={<IconMessageCircle size={14} />}>
            {t("chat.title")}
            {numberOfUnreadMessages > 0 ? (
              <Badge
                color={messageCountIsLoading ? "gray" : "red"}
                ml="xs"
                variant="filled"
                size="xs"
              >
                {indicatorCount(numberOfUnreadMessages)}
              </Badge>
            ) : null}
          </Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="attendees" pt="lg">
          <Stack spacing="xs">
            {attendees.map((attendee) => (
              <AttendeeRow
                key={attendee.user}
                onClick={() => setUserOpened(attendee.user)}
                state={attendee.state}
                userId={attendee.user}
              />
            ))}
          </Stack>
        </Tabs.Panel>

        <Tabs.Panel value="chat">
          <EventChat eventId={event.id} />
        </Tabs.Panel>
      </Tabs>

      <UserModal userId={userOpened} onClose={() => setUserOpened(null)} />

      <AttendanceChangeConfirmation
        onAnswer={async (answer) => {
          if (answer) {
            await setState("accepted");
          }
          setDialog(null);
        }}
        opened={dialog === "accept"}
      >
        <TypographyStylesProvider>
          <div
            dangerouslySetInnerHTML={{
              __html: `
                <p>Potvrzuji, že:</p>
                <ul>
                  <li>souhlasím s účatí na události "${event.name}", která se koná ${dayjs(
                event.time,
              ).format("D.M.YYYY H:mm")}</li>
                  ${
                    event.price
                      ? `<li>dojde uplatnění rezervačního poplatku ve výši ${currencyFormat.format(
                          event.price,
                        )}</li>`
                      : ""
                  }
                  ${
                    event.cancelationFee
                      ? `<li>dojde uplatnění storno poplatku ve výši ${currencyFormat.format(
                          event.cancelationFee,
                        )} v případě, že svoji účast zruším</li>`
                      : ""
                  }
                </ul>
              `,
            }}
          />
        </TypographyStylesProvider>
      </AttendanceChangeConfirmation>
      <AttendanceChangeConfirmation
        onAnswer={async (answer) => {
          if (answer) {
            await setState("canceled");
          }
          setDialog(null);
        }}
        opened={dialog === "cancel"}
      >
        {event.cancelationFee ? (
          <Text align="center">{`Potvrzuji, že ruším svou účast. Dojde k odečtení storno-poplatku ve výši ${currencyFormat.format(
            event.cancelationFee,
          )}.`}</Text>
        ) : (
          <Text align="center">{`Potvrzuji, že ruším svou účast.`}</Text>
        )}
      </AttendanceChangeConfirmation>
    </>
  );
}
