import { AppShell } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import React from "react";
import { Outlet } from "react-router-dom";
import { AppNavbar } from "./app-navbar";
import { AppHeader } from "./app-header";

export function PublicLayout() {
  const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] = useDisclosure(false);

  return (
    <AppShell
      header={<AppHeader drawerOpened={drawerOpened} onDrawerToggle={toggleDrawer} />}
      navbar={<AppNavbar opened={drawerOpened} close={closeDrawer} />}
    >
      <Outlet />
    </AppShell>
  );
}
