import { createStyles, Table } from "@mantine/core";
import React, { useMemo } from "react";
import { useTranslate } from "../../translations";
import { TeamId } from "@wendy/types";
import { useSelector } from "../../store/store";
import { selectTeamMembers } from "../../store/selectors";
import { MemberRow } from "./member-row";
import useCurrentUser from "../../hooks/useCurrentUser";

const useStyles = createStyles(() => ({
  shortColumn: {
    whiteSpace: "nowrap",
    width: "1%",
  },
}));

export type Props = {
  team: TeamId;
};

export function TeamMembersForm({ team }: Props) {
  const members = useSelector((state) => selectTeamMembers(state, team));
  const t = useTranslate();
  const user = useCurrentUser();
  const { classes } = useStyles();
  const list = useMemo(() => members.sort((a, b) => (b.role === "manager" ? 1 : -1)), [members]);

  return (
    <Table>
      <thead>
        <tr>
          <th>{t("team_form.member")}</th>
          <th className={classes.shortColumn}>{t("team_form.is_admin")}</th>
        </tr>
      </thead>
      <tbody>
        {list.map((member, _, a) => (
          <MemberRow
            disabled={user.id === member.user}
            key={JSON.stringify(member)}
            member={member}
          />
        ))}
      </tbody>
    </Table>
  );
}
