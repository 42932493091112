const cs = {
  "?": `?`,
  "action.new_event": `Nová událost`,
  "action.new_team": `Nový tým`,
  "add_location.error_message.message": `Nepodařilo se vvytvořit místo konání`,
  "add_location.name.label": `Název`,
  "add_location.name.placeholder": `Atletická dráha`,
  "add_location.name.required": `Vylňte název`,
  "add_location.submit": `Vytvořit`,
  "add_location.title": `Nové místo konání`,
  "add_location.value.label": `Poloha`,
  "add_location.value.note": `Můžete vložit GPS souřadnice, URL adresu, nebo popis místa`,
  "add_location.value.placeholder": `49.1760375N, 16.5496778E`,
  "avatar_form.submit": `Uložit`,
  "avatar_form.success": `Profilový obrázek byl úspěšně změněn.`,
  "avatar_form.url_invalid": `Neplatná adresa URL`,
  "avatar_form.url": `Adresa URL`,
  "change_password.confirm_new_password": `Nové heslo znovu`,
  "change_password.confirm_password.required": `Vylňte heslo`,
  "change_password.current_password.required": `Vylňte heslo`,
  "change_password.current_password": `Současné heslo`,
  "change_password.new_password.label": `Nové heslo`,
  "change_password.new_password.required": `Vylňte nové heslo`,
  "change_password.password.invalid": `Neplatné nebo příliš slabé heslo`,
  "change_password.password.not_authorized": `Současné heslo neodpovídá uloženému`,
  "change_password.password.not_found": `Heslo nenalezeno`,
  "change_password.password.not_match": `Hesla nejsou stejná`,
  "change_password.save": `Uložit`,
  "change_password.success_message.message": `Změna hesla byla úspěsná.`,
  "change_password.success_message.title": `Vše v pořádku`,
  "chat.event_messages": `Zprávy událostí`,
  "chat.mark_all_read": `Označit za přečtené`,
  "chat.message_placeholder": `Zpráva...`,
  "chat.new_messages": `Nové zprávy`,
  "chat.no_messages": `Zatím žádné zprávy`,
  "chat.send": `Poslat`,
  "chat.team_messages": `Zprávy týmů`,
  "chat.title": `Zprávy`,
  "chat.unread_messages": `Nepřečtené zprávy`,
  "color_scheme.dark": `Tmavý režim`,
  "color_scheme.light": `Světlý režim`,
  "contact_information.email.cannot_change": `E-mail nelze měnit`,
  "contact_information.email.required": `Vyplňte e-mailovou adresu`,
  "contact_information.email": `E-mail`,
  "contact_information.first_name": `Jméno`,
  "contact_information.last_name": `Příjmení`,
  "contact_information.phone.label": `Telefonní číslo`,
  "contact_information.phone.placeholder": `+420 558 840 007`,
  "contact_information.phone.required": `Vylňte své telefonní číslo`,
  "contact_information.phone.wrong_format": `Telefonní číslo nemá správný formát`,
  "contact_information.phone": `Telefon`,
  "contact_information.submit": `Uložit`,
  "contact_information.success": `Kontaktní informace úspěšně uloženy`,
  "credit.balance": `Kredit`,
  "dashboard.title": `Přehled`,
  "dashboard.upcomming_events": `Následující události`,
  "edit_location.error_message.message": `Nepodařilo se upravit místo konání`,
  "edit_location.name.label": `Název`,
  "edit_location.name.placeholder": `Atletická dráha`,
  "edit_location.name.required": `Vylňte název`,
  "edit_location.submit": `Uložit`,
  "edit_location.title": `Úprava místa konání`,
  "edit_location.value.label": `Poloha`,
  "edit_location.value.note": `Můžete vložit GPS souřadnice, URL adresu, nebo popis místa`,
  "edit_location.value.placeholder": ``,
  "email_verification.submit": `Ověřit`,
  "email_verification.success_message.message": `Registrace úspěšně ověřena. Můžete se přihlásit.`,
  "email_verification.success_message.title": `Vše v pořádku`,
  "email_verification.title": `Ověření registrace`,
  "email_verification.token.expired": `Token expiroval. Registrujte se znovu.`,
  "email_verification.token.invalid": `Token není platný.`,
  "email_verification.token.label": `Token z registračního e-mailu.`,
  "email.invalid": `Neplatný e-mail.`,
  "email.required": `Vypňte e-mail.`,
  "error_404.home_link": `Pojte raději zpět na domovskou stránku`,
  "error_404.note": `Třeba jste jen klikli na nějaký starý odkaz, nebo jsme stránku neopatrně přesunuli.`,
  "error_404.title": `Tady nic není.`,
  "error.custom_error": `Selhalo volání API`,
  "error.fetch_error": `Selhalo přenášení dat mezi serverm a klientem`,
  "error.parsing_error": `Selhalo parsování dat zaslaných serverem`,
  "error.unexpected_error": `Neočekávaná chyba!`,
  "error.unknown_error_type": `Neznámý typ chyby`,
  "event_create.submit": `Vytvořit`,
  "event_create.title": `Novná událost`,
  "event_detail.attendance_accept": `Zůčastním se`,
  "event_detail.attendance_cancel.confirmation": `Opravdu chcete zrušit svojí účast?`,
  "event_detail.attendance_cancel": `Zrušit účast`,
  "event_detail.attendance_wait": `Počkám ve frontě`,
  "event_detail.attendee_state.accepted": `Zúčastní se`,
  "event_detail.attendee_state.canceled": `Zrušil účast`,
  "event_detail.attendee_state.pending": `Čeká ve frontě`,
  "event_detail.attendee_state.rejected": `Účast odepřena`,
  "event_detail.free_slots": `Zbývá obsadit`,
  "event_detail.my_attendance": `Moje účast`,
  "event_detail.my_state.accepted": `Zůčastním se`,
  "event_detail.my_state.canceled": `Účast zrušena`,
  "event_detail.my_state.pending": `Čekám ve frontě`,
  "event_detail.my_state.rejected": `Účast odepřena`,
  "event_detail.occupied": `Obsazeno`,
  "event_detail.properties": `Vlastnosti`,
  "event_detail.queue_length": `Lidí ve frontě`,
  "event_detail.queue.accepted": `Přijdou`,
  "event_detail.queue.pending": `Čekají ve frontě`,
  "event_detail.room_left": `Volná místa`,
  "event_edit.allowed_debt": `Povolený dluh`,
  "event_edit.cancel": `Zavřít`,
  "event_edit.cancelation_fee": `Storno poplatek`,
  "event_edit.capacity.description": `Neboli kolik účastníků nanejvýš se může zúčastnit`,
  "event_edit.capacity": `Kapacita`,
  "event_edit.create_location": `Nové místo konání`,
  "event_edit.date_time": `Datum a čas`,
  "event_edit.date": `Datum`,
  "event_edit.description": `Popis`,
  "event_edit.location": `Místo konání`,
  "event_edit.name": `Název`,
  "event_edit.price": `Cena`,
  "event_edit.submit": `Uložit`,
  "event_edit.team": `Tým`,
  "event_edit.time": `Čas`,
  "event_edit.title": `Úprava události`,
  "event_filter.attend": `Zúčastním se?`,
  "event_filter.future": `Budoucí`,
  "event_filter.is_full": `Je plno?`,
  "event_filter.past": `Minulé`,
  "event_filter.team": `Tým`,
  "event_filter.when": `Kdy`,
  "event_invitation.accept_note": `Kliknutím na tlačítko přijímáte pozvánku.`,
  "event_invitation.login_note": `Jestli chcete pozvánku přijmout, přihlaste se.`,
  "event_invitation.logout_note": `Jste přihlášeni jako jiný uživatel, než na kterého je vystavena pozvánka. Jestli chcete pozvánku přijmout, odhlaste se.`,
  "event_invitation.logout": `Odhlásit`,
  "event_invitation.no_room_note": `Pozvánku nelze uplatnit, událost je již plně obsazena.`,
  "event_invitation.not_found": `Pozvánka není platná. Požádejte o další pozvánku.`,
  "event_invitation.register_note": `Vypadá to, že u nás nemáte účet. Jestli chcete pozvánku přijmout, nejprve se registrujte.`,
  "event_invitation.resolved_note": `Pozvánka byla úspěšně přijata`,
  "event_invitation.submit": `Přijímám`,
  "event_invitation.success_message.message": `Úspěšně jste přijali pozvánku na událost.`,
  "event_invitation.success_message.title": `Vše v pořádku`,
  "event_invitation.title": `Pozvánka k události`,
  "event.attend": `Zúčastním se`,
  "event.attendance.he.accepted": `Přijal`,
  "event.attendance.he.none": `Nejde`,
  "event.attendance.he.pending": `Čeká`,
  "event.attendance.he.rejected": `Odmítl`,
  "event.attendance.in_queue": `čekající`,
  "event.attendance.me.accepted": `Jdu`,
  "event.attendance.me.none": `Nejdu`,
  "event.attendance.me.pending": `Čekám`,
  "event.attendance.me.rejected": `Nejdu`,
  "event.attendance.of": `z`,
  "event.attendance": `Účast`,
  "event.attendees": `Účastníci`,
  "event.cannot_edit": `Nemáte právo editovat tuto událost`,
  "event.capacity": `Kapacita`,
  "event.clone": `Klonovat`,
  "event.create": `Nová událost`,
  "event.date": `Datum`,
  "event.detail": `Detail události`,
  "event.error.cannot_cross_debit": `Nemáte dostatek kreditu. Nelze čerpat přes povolený dluh kreditů.`,
  "event.error.no_room": `Nelze se zúčastnit. Už je plno.`,
  "event.error.not_enough_credit": `Nemáte dostatek kreditu.`,
  "event.free_places": `Volná místa`,
  "event.last_empty_room": `Poslední místo`,
  "event.name": `Událost`,
  "event.no_empty_room": `Už je plno`,
  "event.price": `Cena`,
  "event.some_empty_rooms": `Ještě je místo`,
  "event.team": `Tým`,
  "event.time_from": `Datum od`,
  "event.time_to": `Datum do`,
  "events.title": `Události`,
  "forgetten_password.email.invalid": `Neplatný e-mail`,
  "forgetten_password.email.label": `E-mail`,
  "forgetten_password.email.required": `Vyplňte e-mail`,
  "forgetten_password.fail_message.title": `Nejspíš u nás nemáte účet`,
  "forgetten_password.note": `Zadejte e-mail, kterým jste se registrovali, a my Vám na něj pošleme odkaz na zabezpečenou stránku, kde si můžete zvolit nové heslo.`,
  "forgetten_password.submit": `Poslat`,
  "forgetten_password.success_message.message": `Poslali jsme vám e-mail s odkazem na změnu hesla.`,
  "forgetten_password.success_message.title": `Vše v pořádku`,
  "forgetten_password.title": `Zapomenuté heslo`,
  "form.required_field": `Vylňte toto pole`,
  "home.title": `Wendy`,
  "invite_attendees.error_message.message": `Účastníky se nepodařilo přidat`,
  "invite_attendees.friends_to_add": `Pozvat`,
  "invite_attendees.friends": `Přátelé`,
  "invite_attendees.search.nothin_found": `Nikdo nenalezen`,
  "invite_attendees.search.placeholder": `Hledat`,
  "invite_attendees.success_message.message": `Účastníci úspěšně přidáni`,
  "invite_attendees.title": `Pozvat účastníky`,
  "login_form.button.label": `Přihlásit`,
  "login_form.credentials_not_valid": `Neplatné přihlašovací údaje`,
  "login_form.email.label": `E-mail`,
  "login_form.email.placeholder": `pepa.novak@napriklad.cz`,
  "login_form.email.required": `Vyplňte e-mailovou adresu`,
  "login_form.email.wrong_format": `Neplatná e-mailová adresa`,
  "login_form.forgetten_password": `Nepamatuju si heslo`,
  "login_form.password_not_found": `Nemáte nastavené heslo`,
  "login_form.password.required": `Vyplňte heslo`,
  "login_form.register": `Nemám účet`,
  "login_form.success_message.message": ``,
  "login_form.success_message.title": ``,
  "login_form.title": `Přihlášení`,
  "my_account.avatar": `Profilový obrázek`,
  "my_account.change_password": `Změna hesla`,
  "my_account.contact_information": `Kontaktní informace`,
  "my_account.link_label": `Můj účet`,
  "my_account.title": `Můj účet`,
  "password_reset.password.invalid": `Neplatné nebo příliš slabé heslo`,
  "password_reset.password.label": `Nové heslo`,
  "password_reset.password.required": `Vylňte heslo`,
  "password_reset.submit": `Změnit heslo`,
  "password_reset.success_message.message": `Změna hesla byla úspěšná. Můžete se přihlásit s novým heslem.`,
  "password_reset.success_message.title": `Vše v pořádku`,
  "password_reset.title": `Nastavení nového hesla`,
  "password_reset.token.description": `Token se vyplňuje automaticky, neměňte ho.`,
  "password_reset.token.expired": `Token pro resetování hesla expiroval. Znovu si zažádejte o obnovu hesla.`,
  "password_reset.token.invalid": `Neplatný formát tokenu pro resetování hesla`,
  "password_reset.token.label": `Token pro resetování hesla`,
  "password_strength.no_lowercase": `musí obsahovat malá písmena (a-z)`,
  "password_strength.no_number": `musí obsahovat číslice (0-9)`,
  "password_strength.no_symbol": `musí obsahovat speciální znaky jako jsou $&+,:;=?@#|'<>.^*()%!- ap.`,
  "password_strength.no_uppercase": `musí obsahovat velká písmena (A-Z)`,
  "password_strength.too_short": `musí být aspoň 10 znaků dlouhé`,
  "register_form.email.already_exists": `Účet se zadaným e-mailem již existuje. Přihlaste se, nebo použijte jiný e-mail.`,
  "register_form.email.label": `E-mail`,
  "register_form.email.placeholder": `pepa.novak@napriklad.cz`,
  "register_form.email.required": `Vylňte e-mail`,
  "register_form.email.wrong_format": `Vylňte e-mail`,
  "register_form.first_name.label": `Jméno`,
  "register_form.first_name.placeholder": `Pepa`,
  "register_form.first_name.required": `Vylňte jméno`,
  "register_form.last_name.label": `Příjmení`,
  "register_form.last_name.placeholder": `Novák`,
  "register_form.last_name.required": `Vylňte příjmení`,
  "register_form.note": `Vyplňte svoje personálie a my Vám pošleme e-mail s ověřovacím odkazem.`,
  "register_form.password.invalid": `Neplatné nebo příliš slabé heslo`,
  "register_form.password.label": `Heslo`,
  "register_form.password.required": `Vylňte heslo`,
  "register_form.phone.label": `Telefonní číslo`,
  "register_form.phone.placeholder": `+420 558 840 007`,
  "register_form.phone.required": `Vylňte své telefonní číslo`,
  "register_form.phone.wrong_format": `Telefonní číslo nemá správný formát`,
  "register_form.submit": `Založit účet`,
  "register_form.success_message.message": `Registrace proběhla v pořádku. Očekávejte ověřovací e-mail.`,
  "register_form.success_message.title": `Vše v pořádku`,
  "register_form.title": `Registrace`,
  "remove_location.cancel": `Zrušit`,
  "remove_location.error.cannot_remove.message": `Nelze smazat místo konání, když se používá v událostech.`,
  "remove_location.question": `Opravdu chcete smazat tuto místo konání?`,
  "remove_location.submit": `Smazat`,
  "remove_location.title": `Smazání místa konání`,
  "role.manager": `Manažer`,
  "role.plain": `Člen`,
  "send_invitation.copy": `Kopírovat`,
  "send_invitation.email.description": `Zadejte e-mail osoby, kterou chcete pozvat a my jí pošleme pozvánku.`,
  "send_invitation.email.invalid": `E-mail není platný`,
  "send_invitation.email.label": `E-mail`,
  "send_invitation.email.placeholder": `pepa.novak@napriklad.cz`,
  "send_invitation.submit": `Poslat`,
  "send_invitation.success_message_message": `Pozvánka už letí internetem k vašemu kontaktu`,
  "send_invitation.url": `URL pozvánky`,
  "settings.link_label": `Nastavení`,
  "sort_by.asc": `Vzestupně`,
  "sort_by.desc": `Sestupně`,
  "sort_by.direction": `Směr`,
  "table_settings.add_column": `Přidat sloupec`,
  "table_settings.move_down": `Posunout dolů`,
  "table_settings.move_up": `Posunout nahoru`,
  "table_settings.no_columns_set": `Žádný sloupec není přidaný`,
  "table_settings.open": `Nastavení tabulky`,
  "table_settings.remove_column": `Odebrat sloupec`,
  "table_settings.submit": `Zobrazit`,
  "table_settings.title": `Nastavení tabulky`,
  "table_settings.unknonw_colum": `Neznámý sloupec`,
  "team_create_form.error": `Vytváření týmu selhalo`,
  "team_create_form.note": `V nově vytvořeném týmu budete manažerem.`,
  "team_create_form.success": `Tým úspěšně vytvořen`,
  "team_detail.locations": `Lokace`,
  "team_form.am_i_admin": `Jsem admin?`,
  "team_form.cancel": `Zavřít`,
  "team_form.create": `Vytvořit`,
  "team_form.details": `Detaily`,
  "team_form.edit_team": `Úprava týmu`,
  "team_form.error.cannot_remove_last_member": `Nelze odstranit posledního člena`,
  "team_form.error.last_member_must_be_admin": `Aspoň jeden člen musí být admin`,
  "team_form.is_admin": `Je admin?`,
  "team_form.member": `Člen`,
  "team_form.members": `Členové`,
  "team_form.name": `Název týmu`,
  "team_form.new_team": `Nový tým`,
  "team_form.roles": `Role`,
  "team_form.save": `Uložit`,
  "team_select.no_team": `jste bez týmů`,
  "team_select.select_one": `vyberte tým`,
  "team.detail": `Detail týmu`,
  "team.name": `Tým`,
  "team.not_found": `Tým nenalezen`,
  "team.remove_confirmation_message": `Opravdu si přejete smazat tento tým?`,
  "team.size": `Počet členů`,
  "teams.title": `Týmy`,
  "unknown_error.home_link": `Pojte raději zpět na domovskou stránku`,
  "unknown_error.note": `Tak teď se nám něco pokazilo.`,
  "unknown_error.title": `Neznámá chyba`,
  "user_detail.missing_phone": `Telefon chybí`,
  "user_modal.call": `Zavolat`,
  "user_modal.copied": `Zkopírováno`,
  "user_modal.copy": `Zkopírovat`,
  "user_modal.phone": `Telefon`,
  "user_modal.write_sms": `Napsat SMS`,
  "user.me": `Já`,
  account_menu: `Nastavení účtu`,
  actions: `Akce`,
  attention: `Pozor!`,
  close: `Zavřít`,
  edit: `Upravit`,
  email: `E-mail`,
  error: `Chyba`,
  first_name: `Jméno`,
  invitaion_url: `URL pozvánky`,
  invitation: `Pozvánka`,
  invite_attendees: `Pozvat účastníky`,
  last_name: `Příjmení`,
  logout: `Odhlásit`,
  manager: `Manažer`,
  managers: `Manažeři`,
  no: `Ne`,
  notifications: `Zprávy`,
  page_size: `Velikost stránky`,
  password: `Heslo`,
  plain_users: `Běžní členové`,
  remove: `Smazat`,
  search: `Hledat`,
  select_one: `Vyberte jeden`,
  send_invitation: `Poslat pozvánku`,
  send: `Odeslat`,
  settings: `Nastavení`,
  sign_out: `Odhlásit`,
  sort_by: `Seřadit podle`,
  speed_dial_menu: `Nabídka akcí`,
  switch_color_theme: `Přepnout barevné schéma`,
  unknown: `Neznámé`,
  wanna_register: `Chcete se registrovat?`,
  yes: `Ano`,
};

export default cs;
