import { Badge, Button, Card, createStyles, Group, Text } from "@mantine/core";
import { EventSearchRow } from "@wendy/types";
import dayjs from "dayjs";
import React from "react";
import { Link } from "react-router-dom";
import { AttendanceChip } from "../ui/attendance-chip";
import { path } from "../../lib/paths";
import { useTranslate } from "../../translations";
import { useSelector } from "../../store/store";
import { selectNumberOfUnreadMessagesInEvent } from "../../store/selectors";
import { AttendanceProgress } from "./attendance-progress";

const useStyles = createStyles((theme) => ({
  card: {
    transition: "box-shadow 150ms ease, transform 100ms ease",
    "&:hover": {
      boxShadow: `${theme.shadows.md} !important`,
      transform: "scale(1.02)",
    },
    display: "flex",
    flexDirection: "column",
  },
  section: {
    borderBottom: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
    padding: theme.spacing.xs,
    grow: 0,
  },
  sectionMain: {
    grow: 1,
  },
}));

type Props = EventSearchRow;

export function EventCard({ event, my_state, slots }: Props) {
  const { classes, cx } = useStyles();
  const t = useTranslate();
  const unreadMessages = useSelector((state) =>
    selectNumberOfUnreadMessagesInEvent(state, event.id),
  );

  return (
    <Card className={classes.card} shadow="sm" withBorder>
      <Card.Section className={cx(classes.section, classes.sectionMain)}>
        <Text sx={{ fontFamily: "monospace", whiteSpace: "nowrap" }} size="xs">
          {dayjs(event.time).format("dd D.M.YYYY H:mm")}
        </Text>

        <Text size="lg" weight={500} component="div">
          {event.name}
        </Text>
      </Card.Section>
      <Card.Section className={classes.section}>
        <Group position="left" spacing="xs">
          {unreadMessages > 0 && <Badge color="red">{t("chat.new_messages")}</Badge>}
          {slots.pending > 0 ? <Badge color="gray">{t("event.no_empty_room")}</Badge> : null}
          {event.capacity - slots.accepted === 1 ? (
            <Badge color="blue">{t("event.last_empty_room")}</Badge>
          ) : null}
          {event.capacity - slots.accepted > 1 ? (
            <Badge color="blue">{t("event.some_empty_rooms")}</Badge>
          ) : null}
          <AttendanceChip who="me" attendance={my_state} />
        </Group>
      </Card.Section>
      <Card.Section className={classes.section}>
        <AttendanceProgress capacity={event.capacity} {...slots} />
      </Card.Section>
      <Card.Section className={classes.section}>
        <Group position="right">
          {event.price && (
            <Text
              size="lg"
              span
              sx={(theme) => ({
                color: theme.colorScheme === "dark" ? theme.white : theme.black,
              })}
              weight={700}
            >
              {event.price}&nbsp;Kč
            </Text>
          )}
          <Button
            color="blue"
            component={Link}
            size="xs"
            to={path("event-detail", event.id)}
            variant="filled"
          >
            {t("event.detail")}
          </Button>
        </Group>
      </Card.Section>
    </Card>
  );
}
