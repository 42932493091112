import { Group, Switch, Text } from "@mantine/core";
import React, { useCallback, useState } from "react";
import { ErrorCode, Member, Role } from "@wendy/types";
import { useDispatch, useSelector } from "../../store/store";
import { selectUserName } from "../../store/selectors";
import { setMemberRole } from "../../store/slices/members";
import { isApiError } from "../../lib/server";
import { showNotification } from "@mantine/notifications";
import { useTranslate } from "../../translations";
import { UserAvatar } from "../ui/user-avatar";

type Props = {
  disabled?: boolean;
  member: Member;
};

export function MemberRow({ disabled = false, member }: Props) {
  const userName = useSelector((state) => selectUserName(state, member.user));
  const t = useTranslate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const handleChange = useCallback(
    async (role: Role) => {
      if (disabled) {
        return;
      }
      try {
        setLoading(true);
        await dispatch(setMemberRole({ ...member, role })).unwrap();
      } catch (error) {
        if (isApiError(error)) {
          if (error.code === ErrorCode.CANNOT_ABANDON) {
            showNotification({
              color: "red",
              message: t("team_form.error.last_member_must_be_admin"),
            });
          } else {
            showNotification({
              color: "red",
              message: t("error.unknown_error_type"),
            });
          }
        }
      } finally {
        setLoading(false);
      }
    },
    [disabled, dispatch, member, t],
  );

  return (
    <tr>
      <td>
        <Group spacing="xs">
          <UserAvatar userId={member.user} />
          <Text size="sm">{userName}</Text>
        </Group>
      </td>
      <td>
        <Switch
          color="teal"
          checked={member.role === "manager"}
          disabled={disabled || loading}
          onChange={(event) => {
            const checked = event.currentTarget.checked;
            handleChange(checked ? "manager" : "plain");
          }}
        />
      </td>
    </tr>
  );
}
