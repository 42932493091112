import {
  ActionIcon,
  Burger,
  Button,
  createStyles,
  Group,
  Header,
  Text,
  UnstyledButton,
} from "@mantine/core";
import { Link, NavLink } from "react-router-dom";
import React from "react";
import { selectAccount, selectSelectedTeam } from "../../store/selectors";
import { useSelector } from "../../store/store";
import { path } from "../../lib/paths";
import { useTranslate } from "../../translations";
import { UserMenu } from "./user-menu";
import { IconChevronDown, IconPaw } from "@tabler/icons-react";
import { UnreadMessagesIcon } from "./unread-messages-icon";
import { CurrentUserAvatar } from "../ui/current-user-avatar";

const HEADER_HEIGHT = 50;

const useStyles = createStyles((theme) => ({
  hiddenMobile: {
    [theme.fn.smallerThan("sm")]: {
      display: "none",
    },
  },
  hiddenDesktop: {
    [theme.fn.largerThan("sm")]: {
      display: "none",
    },
  },
  link: {
    display: "block",
    height: HEADER_HEIGHT,
    textTransform: "uppercase",
    fontSize: 12,
    lineHeight: `${HEADER_HEIGHT}px`,
    color: theme.colorScheme === "dark" ? theme.colors.dark[1] : theme.colors.gray[6],
    fontWeight: 500,
    paddingLeft: 10,
    paddingRight: 10,
    borderBottom: "2px solid transparent",
    transition: "border-color 100ms ease, color 100ms ease",
    textDecoration: "none",

    "&:hover": {
      color: theme.colorScheme === "dark" ? theme.white : theme.black,
      textDecoration: "none",
    },
  },
  linkActive: {
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    borderBottomColor: theme.colors[theme.primaryColor][theme.colorScheme === "dark" ? 5 : 6],
  },
  user: {
    color: theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.black,
    "&:hover": {
      backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[8] : theme.white,
    },
  },
}));

type Props = {
  drawerOpened: boolean;
  onDrawerToggle: () => void;
};

export function AppHeader({ drawerOpened, onDrawerToggle }: Props) {
  const auth = useSelector(selectAccount);
  const selectedTeam = useSelector(selectSelectedTeam);
  const t = useTranslate();
  const { classes, cx, theme } = useStyles();

  return (
    <Header height={HEADER_HEIGHT} px="md">
      <Group position="apart">
        <Group noWrap>
          <ActionIcon
            component={Link}
            variant="transparent"
            className={classes.hiddenMobile}
            to={path("home")}
          >
            <IconPaw size={HEADER_HEIGHT - 2 * 6} color="purple" />
          </ActionIcon>
          {auth.user && selectedTeam && (
            <Text size="sm" sx={{ lineHeight: 1 }}>
              {selectedTeam.name}
            </Text>
          )}
          {!auth.user && <Text>{t("home.title")}</Text>}
        </Group>

        {auth.user && (
          <Group className={cx(classes.hiddenMobile)} position="right" noWrap spacing={0}>
            <NavLink
              className={({ isActive }) => cx(classes.link, { [classes.linkActive]: isActive })}
              end
              to={path("dashboard")}
            >
              {t("dashboard.title")}
            </NavLink>
            <NavLink
              className={({ isActive }) => cx(classes.link, { [classes.linkActive]: isActive })}
              to={path("team-list")}
            >
              {t("teams.title")}
            </NavLink>
            <NavLink
              className={({ isActive }) => cx(classes.link, { [classes.linkActive]: isActive })}
              to={path("event-list")}
            >
              {t("events.title")}
            </NavLink>
          </Group>
        )}

        {auth.user ? (
          <Group className={classes.hiddenMobile}>
            <UserMenu position="bottom-end">
              <UnstyledButton className={classes.user} py={6}>
                <Group spacing={7}>
                  <CurrentUserAvatar alt={auth.user.email} radius="xl" size="sm" />
                  <IconChevronDown size={12} stroke={1.5} />
                </Group>
              </UnstyledButton>
            </UserMenu>
            <UnreadMessagesIcon />
          </Group>
        ) : (
          <Group className={classes.hiddenMobile} my={6}>
            <Button component={Link} to={path("login")} variant="default">
              {t("login_form.title")}
            </Button>
            <Button component={Link} to={path("register")}>
              {t("register_form.title")}
            </Button>
          </Group>
        )}

        <Burger
          className={classes.hiddenDesktop}
          opened={drawerOpened}
          onClick={onDrawerToggle}
          color={theme.colors.gray[6]}
          size={25}
          my={6}
        />
      </Group>
    </Header>
  );
}
