import { configureStore, combineReducers } from "@reduxjs/toolkit";
import {
  useDispatch as rawUseDispatch,
  useSelector as rawUseSelector,
  TypedUseSelectorHook,
} from "react-redux";
import { attendanceApi } from "../services/attendance";
import { eventsApi } from "../services/events";
import { invitationsApi } from "../services/invitations";
import { mediaApi } from "../services/media";
import { registrationApi } from "../services/registration";
import { usersApi } from "../services/users";
import account from "./slices/account";
import chat from "./slices/chat";
import locations from "./slices/locations";
import members from "./slices/members";
import teams from "./slices/teams";
import users from "./slices/users";

const reducer = combineReducers({
  [attendanceApi.reducerPath]: attendanceApi.reducer,
  [eventsApi.reducerPath]: eventsApi.reducer,
  [invitationsApi.reducerPath]: invitationsApi.reducer,
  [mediaApi.reducerPath]: mediaApi.reducer,
  [registrationApi.reducerPath]: registrationApi.reducer,
  [usersApi.reducerPath]: usersApi.reducer,
  account,
  chat,
  locations,
  members,
  teams,
  users,
});
const store = configureStore({
  devTools: process.env.NODE_ENV !== "production",
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      attendanceApi.middleware,
      eventsApi.middleware,
      invitationsApi.middleware,
      mediaApi.middleware,
      registrationApi.middleware,
      usersApi.middleware,
    ),
});

export type RootState = ReturnType<typeof reducer>;
export type AppDispatch = typeof store.dispatch;
export const useSelector: TypedUseSelectorHook<RootState> = rawUseSelector;
export const useDispatch: () => AppDispatch = rawUseDispatch;

export default store;
