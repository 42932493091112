import React from "react";
import { useParams } from "react-router-dom";
import { Container, Loader } from "@mantine/core";
import { useDocumentTitle } from "@mantine/hooks";
import { TeamId } from "@wendy/types";
import { useTranslate } from "../translations";
import { TeamDetail } from "../features/teams";
import { useSelector } from "../store/store";
import {
  selectIsTeamManager,
  selectTeam,
  selectTeamMembers,
  selectTeamsLoading,
} from "../store/selectors";

export default function TeamDetailPage() {
  const { id } = useParams<{ id: TeamId }>();
  const isTeamLoading = useSelector(selectTeamsLoading);
  const team = useSelector((state) => (id ? selectTeam(state, id) : null));
  const members = useSelector((state) => (id ? selectTeamMembers(state, id) : []));
  const asManager = useSelector((state) => (id ? selectIsTeamManager(state, id) : false));
  const t = useTranslate();

  useDocumentTitle(t("team.detail"));

  return (
    <Container size="sm">
      {isTeamLoading && <Loader />}
      {team && <TeamDetail team={team} members={members} asManager={asManager} />}
    </Container>
  );
}
