import { Title } from "@mantine/core";
import { useDocumentTitle } from "@mantine/hooks";
import { useTranslate } from "../translations";

export default function SettingsPage() {
  const t = useTranslate();
  useDocumentTitle(t("settings"));

  return (
    <>
      <Title mb="sm" size="h3">
        {t("settings")}
      </Title>
      <p>Nastavení je ještě ve vývoji</p>
    </>
  );
}
