import { createApi } from "@reduxjs/toolkit/query/react";
import { Attendee, AttendeeState, EventId } from "@wendy/types";
import { baseQuery } from "./baseQuery";

export const attendanceApi = createApi({
  reducerPath: "attendanceApi",
  baseQuery,
  endpoints: (builder) => ({
    getAttendance: builder.query<Attendee[], EventId>({
      query: (id) => ({
        method: "GET",
        url: `attendance/${id}`,
      }),
    }),
    changeAttendance: builder.mutation<Attendee[], { eventId: EventId; state: AttendeeState }>({
      query: ({ eventId, ...body }) => ({
        body,
        method: "PUT",
        url: `attendance/${eventId}`,
      }),
    }),
  }),
});

export const { useGetAttendanceQuery, useChangeAttendanceMutation } = attendanceApi;
