import React, { useCallback, useEffect } from "react";
import { EventId } from "@wendy/types";
import { useDispatch, useSelector } from "../../store/store";
import { selectEventMessages } from "../../store/selectors";
import {
  getMessagesForEvent,
  postMessagesForEvent,
  readEventMessages,
} from "../../store/slices/chat";
import { Chat } from "../ui/chat";

type Props = {
  eventId: EventId;
};

export function EventChat({ eventId }: Props) {
  const dispatch = useDispatch();
  const messages = useSelector((state) => selectEventMessages(state, eventId));

  const sendMessage = useCallback(
    (message: string): void => {
      void dispatch(postMessagesForEvent({ eventId, message }));
    },
    [dispatch, eventId],
  );

  useEffect(() => {
    void dispatch(getMessagesForEvent(eventId));
    void dispatch(readEventMessages(eventId));
  }, [dispatch, eventId]);

  return <Chat messages={messages} sendMessage={sendMessage} />;
}
