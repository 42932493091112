import { Button, Modal, Stack, TextInput } from "@mantine/core";
import { useForm, zodResolver } from "@mantine/form";
import { showNotification } from "@mantine/notifications";
import React from "react";
import z from "zod";
import { LocationId, TeamId } from "@wendy/types";
import { useTranslate } from "../../translations";
import { useDispatch } from "../../store/store";
import { addLocation } from "../../store/slices/locations";

type Props = {
  onClose: () => void;
  onSuccess: (id: LocationId) => void;
  opened: boolean;
  team: TeamId;
};

export function AddLocationDialog({ team, opened, onClose, onSuccess }: Props) {
  const t = useTranslate();
  const schema = z.object({
    name: z.string().min(1, { message: t("add_location.name.required") }),
    team: z.string().uuid(),
    value: z.string(),
  });
  const dispatch = useDispatch();
  const form = useForm({
    initialValues: {
      name: "",
      team,
      value: "",
    },
    validate: zodResolver(schema),
  });
  const handleSubmit = form.onSubmit(async (values) => {
    try {
      const id = crypto.randomUUID();
      await dispatch(
        addLocation({
          id,
          name: values.name,
          team: values.team,
          value: values.value,
        }),
      );
      onSuccess(id);
    } catch (error) {
      console.error(error);
      showNotification({
        color: "red",
        message: t("edit_location.error_message.message"),
      });
    }
  });

  return (
    <Modal
      onClose={onClose}
      opened={opened}
      size="xl"
      title={t("add_location.title")}
      withCloseButton={true}
    >
      <form onSubmit={handleSubmit}>
        <Stack>
          <TextInput
            label={t("add_location.name.label")}
            withAsterisk
            {...form.getInputProps("name")}
            placeholder={t("add_location.name.placeholder")}
          />
          <TextInput
            label={t("add_location.value.label")}
            description={t("add_location.value.note")}
            {...form.getInputProps("value")}
            placeholder={t("add_location.value.placeholder")}
          />
          <Button mt="lg" onClick={() => handleSubmit()}>
            {t("add_location.submit")}
          </Button>
        </Stack>
      </form>
    </Modal>
  );
}
