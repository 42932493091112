import { Button, Grid, PasswordInput } from "@mantine/core";
import { useForm, zodResolver } from "@mantine/form";
import { ErrorCode } from "@wendy/types";
import { useMemo, useState } from "react";
import { z } from "zod";
import useFormMessage from "../../hooks/useFormMessage";
import { isApiError } from "../../lib/server";
import { useDispatch } from "../../store/store";
import { changePassword } from "../../store/slices/account";
import { useTranslate } from "../../translations";
import {
  PasswordStrengthMeter,
  calculateStrength,
  isPasswordValid,
} from "../ui/password-strength-meter";

export function ChangePasswordForm() {
  const t = useTranslate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { isOpen, messageProps, setError, setSuccess, Message } = useFormMessage();
  const [password, setPassword] = useState("");
  const schema = useMemo(
    () =>
      z
        .object({
          current: z
            .string()
            .min(1, { message: t("change_password.current_password.required") })
            .trim(),
          password: z
            .string()
            .min(1, { message: t("change_password.new_password.required") })
            .trim(),
          confirm: z
            .string()
            .min(1, { message: t("change_password.confirm_password.required") })
            .trim(),
        })
        .refine((data) => data.password === data.confirm, {
          message: t("change_password.password.not_match"),
          path: ["confirm"],
        })
        .refine((x) => isPasswordValid(x.password), {
          message: t("change_password.password.invalid"),
          path: ["password"],
        }),
    [t],
  );
  const form = useForm({
    initialValues: {
      current: "",
      password: "",
      confirm: "",
    },
    validate: zodResolver(schema),
  });
  const onSubmit = form.onSubmit(async (values) => {
    try {
      setLoading(true);
      await dispatch(changePassword(values)).unwrap();
      setSuccess({
        message: t("change_password.success_message.message"),
        title: t("change_password.success_message.title"),
      });
      form.reset();
    } catch (error) {
      if (isApiError(error)) {
        switch (error.code) {
          case ErrorCode.PASSWORD_NOT_FOUND:
            return setError({
              message: t("change_password.password.not_found"),
              title: t("error"),
            });
          case ErrorCode.PASSWORD_NOT_AUTHENTICATED:
            return setError({
              message: t("change_password.password.not_authorized"),
              title: t("error"),
            });
          case ErrorCode.PASSWORD_NOT_VALID:
            return setError({
              message: t("change_password.password.invalid"),
              title: t("error"),
            });
        }

        setError({
          message: t("error.unknown_error_type"),
          title: t("error"),
        });
      }
    } finally {
      setLoading(false);
    }
  });

  return (
    <form onSubmit={onSubmit}>
      <Grid>
        {isOpen && (
          <Grid.Col>
            <Message {...messageProps} />
          </Grid.Col>
        )}
        <Grid.Col>
          <PasswordInput
            label={t("change_password.current_password")}
            withAsterisk
            {...form.getInputProps("current")}
          />
        </Grid.Col>
        <Grid.Col>
          <PasswordInput
            label={t("change_password.new_password.label")}
            mb="sm"
            withAsterisk
            {...form.getInputProps("password")}
            onChange={(ev) => {
              setPassword(ev.target.value);
              return form.getInputProps("password").onChange(ev.target.value);
            }}
          />
          <PasswordStrengthMeter strength={calculateStrength(password)} />
        </Grid.Col>
        <Grid.Col>
          <PasswordInput
            label={t("change_password.confirm_new_password")}
            withAsterisk
            {...form.getInputProps("confirm")}
          />
        </Grid.Col>
        <Grid.Col>
          <Button loading={loading} type="submit">
            {t("change_password.save")}
          </Button>
        </Grid.Col>
      </Grid>
    </form>
  );
}
