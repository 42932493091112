import { createApi } from "@reduxjs/toolkit/query/react";
import {
  Event,
  EventCreate,
  EventId,
  EventUpdateCommand,
  TeamId,
  EventSearchRow,
} from "@wendy/types";
import { baseQuery } from "./baseQuery";

export const tagType = "Events";
export const getTag = (id: string | "LIST"): { id: string; type: "Events" } => ({
  id,
  type: tagType,
});

export const eventsApi = createApi({
  reducerPath: "eventsApi",
  baseQuery,
  endpoints: (builder) => ({
    getEvent: builder.query<Event, EventId>({
      providesTags: (result) => (result ? [getTag(result.id)] : []),
      query: (id) => ({
        method: "GET",
        url: `event/${id}`,
      }),
    }),
    searchEvents: builder.mutation<
      { rows: EventSearchRow[]; total: number },
      {
        filters: {
          team?: TeamId;
          time?: "future" | "past";
          is_accepted?: "y" | "n";
          is_full?: "y" | "n";
        };
        pageNumber: number;
        pageSize: number;
      }
    >({
      query: (search) => ({
        method: "POST",
        url: `event/search`,
        body: search,
      }),
    }),
    getEventsByUser: builder.query<Event[], void>({
      providesTags: (result) => [
        ...(result ? result.map(({ id }) => getTag(id)) : []),
        getTag("LIST"),
      ],
      query: () => ({
        method: "GET",
        url: `user/events`,
      }),
    }),
    updateEvent: builder.mutation<EventId[], EventUpdateCommand>({
      invalidatesTags: (_result, _error, { event }) => [getTag(event), getTag("LIST")],
      query: ({ event, data }) => ({
        body: data,
        url: `event/${event}`,
        method: "PUT",
      }),
    }),
    addEvent: builder.mutation<EventId, EventCreate>({
      invalidatesTags: (result) => (result ? [getTag(result), getTag("LIST")] : [getTag("LIST")]),
      query: (body) => ({
        body,
        method: "POST",
        url: `event`,
      }),
    }),
    removeEvent: builder.mutation<EventId, EventId>({
      invalidatesTags: (_result, _error, id) => [getTag(id), getTag("LIST")],
      query: (id) => ({
        method: "DELETE",
        url: `event/${id}`,
      }),
    }),
  }),
  tagTypes: [tagType],
});

export const {
  useAddEventMutation,
  useGetEventQuery,
  useGetEventsByUserQuery,
  useSearchEventsMutation,
  useUpdateEventMutation,
} = eventsApi;
