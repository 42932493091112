import { Button, Group, Modal, Stack } from "@mantine/core";
import React, { PropsWithChildren } from "react";
import { useTranslate } from "../../translations";

type Props = PropsWithChildren<{
  onAnswer: (answer: boolean) => void;
  opened: boolean;
}>;

export function AttendanceChangeConfirmation({ children, onAnswer, opened }: Props) {
  const t = useTranslate();

  return (
    <Modal
      centered
      onClose={() => onAnswer(false)}
      opened={opened}
      size="md"
      withCloseButton={false}
    >
      <Stack>
        {children}
        <Group position="center">
          <Button color="red" onClick={() => onAnswer(true)}>
            {t("yes")}
          </Button>
          <Button color="blue" onClick={() => onAnswer(false)}>
            {t("no")}
          </Button>
        </Group>
      </Stack>
    </Modal>
  );
}
