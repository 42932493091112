import { Menu, MenuProps, useMantineColorScheme } from "@mantine/core";
import React, { PropsWithChildren, useMemo } from "react";
import { IconLogout, IconMoonStars, IconSettings, IconSun, IconUser } from "@tabler/icons-react";
import { Link } from "react-router-dom";
import { path } from "../../lib/paths";
import { useTranslate } from "../../translations";
import { useAuth } from "../../hooks/useAuth";
import { useSelector } from "../../store/store";
import { selectUserName } from "../../store/selectors";

type Props = PropsWithChildren<{
  onClose?: () => void;
  position?: MenuProps["position"];
}>;

export function UserMenu({ children, onClose, position }: Props) {
  const t = useTranslate();
  const { user, logout } = useAuth();
  const userName = useSelector((state) => (user ? selectUserName(state, user?.id) : ""));
  const creditBalance = useSelector(
    (state) => state.account.credit.amount - state.account.credit.blocked,
  );
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();
  const currencyFormat = useMemo(
    () => new Intl.NumberFormat("cs-CZ", { style: "currency", currency: "CZK" }),
    [],
  );
  const dark = colorScheme === "dark";

  return (
    <Menu position={position}>
      <Menu.Target>{children}</Menu.Target>

      <Menu.Dropdown onClickCapture={() => onClose?.()}>
        <Menu.Label>{userName}</Menu.Label>
        <Menu.Label>
          {t("credit.balance")}
          {": "}
          {currencyFormat.format(creditBalance)}
        </Menu.Label>
        <Menu.Divider />
        <Menu.Item
          icon={
            dark ? <IconSun color="yellow" size={14} /> : <IconMoonStars color="blue" size={14} />
          }
          onClick={() => toggleColorScheme()}
        >
          {dark ? t("color_scheme.light") : t("color_scheme.dark")}
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item
          component={Link}
          to={path("my-account")}
          icon={
            <IconUser
              size={14}
              onClick={() => {
                console.log("my-account");
              }}
            />
          }
        >
          {t("my_account.link_label")}
        </Menu.Item>
        <Menu.Item component={Link} to={path("settings")} icon={<IconSettings size={14} />}>
          {t("settings.link_label")}
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item color="red" icon={<IconLogout size={14} />} onClick={() => logout()}>
          {t("logout")}
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
}
