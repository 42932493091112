import { Button, Grid, PasswordInput, Text, TextInput } from "@mantine/core";
import { useForm, zodResolver } from "@mantine/form";
import { ErrorCode } from "@wendy/types";
import React, { useMemo, useState } from "react";
import { z } from "zod";
import useFormMessage from "../../hooks/useFormMessage";
import { isApiError } from "../../lib/server";
import { useSignUpMutation } from "../../services/registration";
import { useTranslate } from "../../translations";
import {
  PasswordStrengthMeter,
  calculateStrength,
  isPasswordValid,
} from "../ui/password-strength-meter";

type Props = {
  email?: string;
  invitationToken?: string;
  onSuccess?: () => void;
};

const phoneNumberRegex = /^(\+\d{3})?([ -]*\d){9}$/;

export function RegisterForm({ email = "", invitationToken, onSuccess }: Props) {
  const [signUp, { isLoading }] = useSignUpMutation();
  const t = useTranslate();
  const { isOpen, messageProps, Message, setError, setSuccess } = useFormMessage();
  const [password, setPassword] = useState("");
  const schema = useMemo(
    () =>
      z
        .object({
          email: z
            .string()
            .min(1, t("register_form.email.required"))
            .email(t("register_form.email.wrong_format")),
          first_name: z.string().min(1, t("register_form.first_name.required")),
          last_name: z.string().min(1, t("register_form.last_name.required")),
          password: z.string().min(1, t("register_form.password.required")),
          phone: z
            .string()
            .min(1, t("register_form.phone.required"))
            .regex(phoneNumberRegex, t("register_form.phone.wrong_format"))
            .trim(),
        })
        .refine((x) => isPasswordValid(x.password), {
          message: t("register_form.password.invalid"),
          path: ["password"],
        }),
    [t],
  );
  const form = useForm({
    initialValues: {
      email,
      first_name: "",
      last_name: "",
      password: "",
      phone: "",
    },
    validate: zodResolver(schema),
  });
  const handleSubmit = form.onSubmit(async (values) => {
    try {
      await signUp({ ...values, invitation_token: invitationToken }).unwrap();
      setSuccess({
        message: t("register_form.success_message.message"),
        title: t("register_form.success_message.title"),
      });
      form.reset();
      onSuccess?.();
    } catch (error) {
      if (isApiError(error)) {
        switch (error.code) {
          case ErrorCode.PASSWORD_NOT_VALID:
            return setError({
              message: t("register_form.password.invalid"),
              title: t("error"),
            });
          case ErrorCode.SIGN_UP_EMAIL_COLLISION:
            return setError({
              message: t("register_form.email.already_exists"),
              title: t("error"),
            });
        }
      }
      setError({
        message: t("error.unknown_error_type"),
        title: t("error"),
      });
    }
  });

  return (
    <form onSubmit={handleSubmit}>
      <Grid>
        <Grid.Col>
          <Text align="center">{t("register_form.note")}</Text>
        </Grid.Col>
        {isOpen && (
          <Grid.Col>
            <Message {...messageProps} />
          </Grid.Col>
        )}
        <Grid.Col sm={6}>
          <TextInput
            autoComplete="given-name"
            label={t("register_form.first_name.label")}
            placeholder={t("register_form.first_name.placeholder")}
            withAsterisk
            {...form.getInputProps("first_name")}
          />
        </Grid.Col>
        <Grid.Col sm={6}>
          <TextInput
            autoComplete="family-name"
            label={t("register_form.last_name.label")}
            placeholder={t("register_form.last_name.placeholder")}
            withAsterisk
            {...form.getInputProps("last_name")}
          />
        </Grid.Col>
        <Grid.Col>
          <TextInput
            autoComplete="email"
            disabled={email !== ""}
            label={t("register_form.email.label")}
            placeholder={t("register_form.email.placeholder")}
            withAsterisk
            {...form.getInputProps("email")}
          />
        </Grid.Col>
        <Grid.Col>
          <TextInput
            autoComplete="tel"
            label={t("register_form.phone.label")}
            placeholder={t("register_form.phone.placeholder")}
            withAsterisk
            {...form.getInputProps("phone")}
          />
        </Grid.Col>
        <Grid.Col>
          <PasswordInput
            autoComplete="new-password"
            label={t("register_form.password.label")}
            withAsterisk
            {...form.getInputProps("password")}
            onChange={(ev) => {
              setPassword(ev.target.value);
              return form.getInputProps("password").onChange(ev.target.value);
            }}
          />
        </Grid.Col>
        <Grid.Col>
          <PasswordStrengthMeter strength={calculateStrength(password)} />
        </Grid.Col>
        <Grid.Col>
          <Button fullWidth loading={isLoading} type="submit">
            {t("register_form.submit")}
          </Button>
        </Grid.Col>
      </Grid>
    </form>
  );
}
