import { useCallback, useState } from "react";

export default function useLocalStorage(
  key: string,
  initialValue: string | null = null,
): [string | null, (value: string | null) => void, () => void] {
  const [storedValue, setStoredValue] = useState(() => {
    if (typeof window === "undefined") {
      return initialValue;
    }

    const value = window.localStorage.getItem(key);
    if (value) {
      return value;
    }

    if (initialValue == null) {
      window.localStorage.removeItem(key);
    } else {
      window.localStorage.setItem(key, initialValue);
    }

    return initialValue;
  });

  const removeValue = useCallback(() => {
    window.localStorage.removeItem(key);
  }, [key]);
  const setValue = useCallback(
    (newValue: string | null) => {
      if (newValue == null) {
        removeValue();
      } else {
        window.localStorage.setItem(key, newValue);
      }
      setStoredValue(newValue);
    },
    [key, removeValue],
  );

  return [storedValue, setValue, removeValue];
}
