import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Team, TeamCreate, TeamId } from "@wendy/types";
import { AxiosError } from "axios";
import { api, handleError } from "../../lib/api";
import { RootState } from "../store";

export const selectedTeamKey = "~selected-team";

export type TeamsState = {
  loadingAll: boolean;
  loading: TeamId[];
  records: Team[];
  selected: TeamId | null;
};

export const fetchTeams = createAsyncThunk<Team[], void, { state: RootState }>(
  "teams/fetch",
  (_, { dispatch, getState }) =>
    api
      .get(`/team`)
      .then((response) => response.data, handleError)
      .then((teams) => {
        if (teams.length === 1 && getState().teams.selected == null) {
          dispatch(setSelectedTeam(teams[0].id));
        }
        return teams;
      }),
);

type TeamUpdatePayload = {
  id: TeamId;
  name: string;
};
export const editTeam = createAsyncThunk<Team, TeamUpdatePayload, { state: RootState }>(
  "teams/edit",
  ({ id, ...body }) => api.put(`/team/${id}`, body).then((response) => response.data, handleError),
);

export const addTeam = createAsyncThunk<
  Team,
  TeamCreate,
  { state: RootState; rejectValue: AxiosError }
>("teams/add", (data) => api.post(`/team`, data).then((response) => response.data, handleError));

export const removeTeam = createAsyncThunk<TeamId, TeamId, { state: RootState }>(
  "teams/remove",
  (id) => api.delete(`/team/${id}`).then((response) => response.data, handleError),
);

export const setSelectedTeam = createAsyncThunk<TeamId | null, TeamId | null, { state: RootState }>(
  "teams/set-selected-team",
  async (teamId) => {
    if (teamId) {
      localStorage.setItem(selectedTeamKey, teamId);
    } else {
      localStorage.removeItem(selectedTeamKey);
    }
    return teamId;
  },
);

const initialState: TeamsState = {
  loadingAll: false,
  loading: [],
  records: [],
  selected: localStorage.getItem(selectedTeamKey),
};

const slice = createSlice({
  name: "teams",
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchTeams.pending, (state) => {
      state.loadingAll = true;
    });
    builder.addCase(fetchTeams.fulfilled, (state, { payload }) => {
      state.loadingAll = false;
      state.records = payload;
    });
    builder.addCase(fetchTeams.rejected, (state) => {
      state.loadingAll = false;
    });

    builder.addCase(addTeam.fulfilled, (state, { payload }) => {
      state.records = [...state.records, payload];
    });

    builder.addCase(editTeam.pending, (state, { meta }) => {
      state.loading = [...state.loading, meta.arg.id];
    });
    builder.addCase(editTeam.rejected, (state, { meta }) => {
      state.loading = state.loading.filter((id) => id !== meta.arg.id);
    });
    builder.addCase(editTeam.fulfilled, (state, { meta, payload }) => {
      state.loading = state.loading.filter((id) => id !== meta.arg.id);
      state.records = state.records.map((record) =>
        record.id === meta.arg.id ? meta.arg : record,
      );
    });

    builder.addCase(removeTeam.pending, (state, { meta }) => {
      state.loading = [...state.loading, meta.arg];
    });
    builder.addCase(removeTeam.rejected, (state, { meta }) => {
      state.loading = state.loading.filter((id) => id !== meta.arg);
    });
    builder.addCase(removeTeam.fulfilled, (state, { meta, payload }) => {
      state.records = state.records.filter((record) => record.id !== meta.arg);
    });

    builder.addCase(setSelectedTeam.fulfilled, (state, { payload }) => {
      state.selected = payload;
    });
  },
  initialState,
});

export default slice.reducer;
