import { useState } from "react";
import { ActionIcon, createStyles, Group, Select } from "@mantine/core";
import { LocationId, TeamId } from "@wendy/types";
import { IconPlus } from "@tabler/icons-react";
import { useTranslate } from "../../translations";
import { AddLocationDialog } from "./add-location-dialog";
import { useSelector } from "../../store/store";
import { selectIsAllLocationLoading, selectTeamLocations } from "../../store/selectors";

const useStyles = createStyles(() => ({
  group: {
    alignItems: "end",
  },
  select: {
    flexGrow: 1,
  },
  button: {
    flexGrow: 0,
  },
}));

export type Props = {
  team: TeamId;
  value: LocationId | null;
  onChange: (value: LocationId | null) => void;
};

export function LocationsSelect({ team, value, onChange }: Props) {
  const t = useTranslate();
  const { classes } = useStyles();
  const locations = useSelector((state) => selectTeamLocations(state, team));
  const loading = useSelector(selectIsAllLocationLoading);
  const [opened, setOpened] = useState(false);

  return (
    <>
      <Group className={classes.group} grow>
        <Select
          className={classes.select}
          clearable
          data={locations.map((x) => ({ value: x.id, label: x.name }))}
          disabled={loading}
          label={t("event_edit.location")}
          onChange={onChange}
          searchable
          value={value}
        />
        <ActionIcon
          className={classes.button}
          color="blue"
          onClick={() => setOpened(true)}
          size="lg"
          title={t("event_edit.create_location")}
          variant="outline"
        >
          <IconPlus />
        </ActionIcon>
      </Group>
      <AddLocationDialog
        onClose={() => setOpened(false)}
        onSuccess={async (id) => {
          onChange(id);
          setOpened(false);
          console.log({ locationDialog: id });
        }}
        opened={opened}
        team={team}
      />
    </>
  );
}
