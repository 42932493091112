import {
  Modal,
  Text,
  Stack,
  Button,
  Group,
  CopyButton as MantineCopyButton,
  CopyButtonProps,
  Tooltip,
  ActionIcon,
} from "@mantine/core";
import React from "react";
import { UserId } from "@wendy/types";
import {
  IconCheck,
  IconCopy,
  IconMessage2,
  IconPhoneCall,
  IconPhoneOff,
} from "@tabler/icons-react";
import { useTranslate } from "../../translations";
import { useSelector } from "../../store/store";
import { selectUserById, selectUserName } from "../../store/selectors";
import { UserAvatar } from "./user-avatar";

function CopyButton({ timeout, value }: Pick<CopyButtonProps, "timeout" | "value">) {
  const t = useTranslate();
  return (
    <MantineCopyButton value={value} timeout={timeout}>
      {({ copied, copy }) => (
        <Tooltip
          label={copied ? t("user_modal.copied") : t("user_modal.copy")}
          withArrow
          position="right"
        >
          <ActionIcon color={copied ? "teal" : "gray"} onClick={copy}>
            {copied ? <IconCheck size={16} /> : <IconCopy size={16} />}
          </ActionIcon>
        </Tooltip>
      )}
    </MantineCopyButton>
  );
}

function UserDetailContent({ userId }: { userId: UserId }) {
  const user = useSelector((state) => selectUserById(state, userId));
  const userName = useSelector((state) => selectUserName(state, userId));
  const t = useTranslate();

  if (!user) {
    return null;
  }

  return (
    <Stack mb="lg" spacing="xs">
      <UserAvatar mx="auto" size="lg" userId={userId} />

      <Text ta="center" fz="lg">
        {userName}
      </Text>
      {user.view === "full" && (
        <Group position="center" spacing="xs">
          <Text ta="center" fz="sm" c="dimmed">
            {user.email}
          </Text>

          <CopyButton value={user.email} timeout={3000} />
        </Group>
      )}
      {user.view === "full" && user.phone && (
        <Group position="center" spacing="xs">
          <Text ta="center" fz="sm" c="dimmed">
            {t("user_modal.phone")}
            {": "}
            {user.phone}
          </Text>

          <CopyButton value={user.phone} timeout={3000} />
        </Group>
      )}
      {user.view === "full" && user.phone && (
        <Group position="center">
          <Button
            component="a"
            href={`tel:${user.phone}`}
            leftIcon={<IconPhoneCall />}
            color="green"
          >
            {t("user_modal.call")}
          </Button>
          <Button
            component="a"
            href={`sms:${user.phone}?body=${encodeURIComponent("Ahoj")}`}
            leftIcon={<IconMessage2 />}
            color="green"
          >
            {t("user_modal.write_sms")}
          </Button>
        </Group>
      )}
      {user.view === "full" && !user.phone && (
        <Button mx="auto" leftIcon={<IconPhoneOff />} variant="light" size="lg" disabled>
          {t("user_detail.missing_phone")}
        </Button>
      )}
    </Stack>
  );
}

type Props = {
  onClose: () => void;
  userId: UserId | null;
};

export function UserModal({ onClose, userId }: Props) {
  return (
    <Modal centered onClose={onClose} opened={userId != null} size="sm" withCloseButton={true}>
      {userId && <UserDetailContent userId={userId} />}
    </Modal>
  );
}
