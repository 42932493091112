import { Badge, Group, Skeleton, Text, UnstyledButton, useMantineTheme } from "@mantine/core";
import React from "react";
import { AttendeeState, UserId } from "@wendy/types";
import { useSelector } from "../../store/store";
import { selectUserById } from "../../store/selectors";
import { useTranslate } from "../../translations";
import { UserAvatar } from "../ui/user-avatar";

type Props = {
  onClick: () => void;
  state: AttendeeState;
  userId: UserId;
};

export function AttendeeRow({ onClick, state, userId }: Props) {
  const t = useTranslate();
  const theme = useMantineTheme();
  const attendee = useSelector((state) => selectUserById(state, userId));

  if (attendee == null) {
    return (
      <tr>
        <td colSpan={3}>
          <Skeleton />
        </td>
      </tr>
    );
  }

  return (
    <UnstyledButton
      sx={{
        padding: theme.spacing.xs,
        borderRadius: theme.radius.sm,
        color: theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.black,

        "&:hover": {
          backgroundColor:
            theme.colorScheme === "dark" ? theme.colors.dark[6] : theme.colors.gray[0],
        },
      }}
      onClick={() => onClick()}
    >
      <Group spacing="sm" position="apart" noWrap>
        <Group spacing="sm" noWrap>
          <UserAvatar userId={attendee.id} />

          <div>
            <Text fz="sm">
              {attendee.first_name} {attendee.last_name}
            </Text>
            {attendee.view === "full" && attendee.phone && (
              <Text fz="xs" c="dimmed">
                {attendee.phone}
              </Text>
            )}
          </div>
        </Group>

        {state === "accepted" && (
          <Badge color="green" variant="outline">
            {t("event_detail.attendee_state.accepted")}
          </Badge>
        )}
        {state === "pending" && (
          <Badge color="blue" variant="outline">
            {t("event_detail.attendee_state.pending")}
          </Badge>
        )}
        {state === "rejected" && (
          <Badge color="red" variant="outline">
            {t("event_detail.attendee_state.rejected")}
          </Badge>
        )}
        {state === "canceled" && (
          <Badge color="red" variant="outline">
            {t("event_detail.attendee_state.canceled")}
          </Badge>
        )}
      </Group>
    </UnstyledButton>
  );
}
