import React, { useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { path } from "../../lib/paths";
import { fetchLocations } from "../../store/slices/locations";
import { fetchMembers } from "../../store/slices/members";
import { fetchTeams, setSelectedTeam } from "../../store/slices/teams";
import { useDispatch, useSelector } from "../../store/store";
import { PublicLayout } from "./public-layout";
import { selectSelectedTeamId, selectTeamList } from "../../store/selectors";
import { fetchCredit } from "../../store/slices/account";
import { countUnreadMessages } from "../../store/slices/chat";
import { useWebsocket } from "../../hooks/use-websocket";
import { fetchFriends } from "../../store/slices/users";

export function PrivateLayout() {
  const { user } = useAuth();
  const dispatch = useDispatch();
  const selectedTeamId = useSelector(selectSelectedTeamId);
  const teamList = useSelector(selectTeamList);
  const teamsLoading = useSelector((state) => state.teams.loading);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchTeams());
    dispatch(fetchMembers());
    dispatch(fetchLocations());
    dispatch(fetchCredit());
    dispatch(fetchFriends());
    dispatch(countUnreadMessages());
  }, [dispatch]);

  useEffect(() => {
    if (!selectedTeamId) {
      if (teamList.length > 0) {
        dispatch(setSelectedTeam(teamList[0].id));
      } else if (!teamsLoading) {
        navigate(path("team-create"));
      }
    }
  }, [dispatch, teamsLoading, navigate, selectedTeamId, teamList]);

  useWebsocket();

  if (!user) {
    return <Navigate to={path("login")} replace />;
  }

  return <PublicLayout />;
}
