import { Button, Modal, Stack, TextInput } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { useForm, zodResolver } from "@mantine/form";
import React from "react";
import z from "zod";
import { Location } from "@wendy/types";
import { useTranslate } from "../../translations";
import { useDispatch, useSelector } from "../../store/store";
import { editLocation } from "../../store/slices/locations";
import { selectIsLocationLoading } from "../../store/selectors";

type Props = {
  location: Location;
  onClose: () => void;
  onSuccess: () => void;
  opened: boolean;
};

export function EditLocationDialog({ location, opened, onClose, onSuccess }: Props) {
  const t = useTranslate();
  const schema = z.object({
    name: z.string().min(1, { message: t("edit_location.name.required") }),
    value: z.string(),
  });
  const isLoading = useSelector((state) => selectIsLocationLoading(state, location.id));
  const dispatch = useDispatch();
  const form = useForm({
    initialValues: {
      name: location.name,
      value: location.value,
    },
    validate: zodResolver(schema),
  });
  const handleSubmit = form.onSubmit(async (values) => {
    try {
      await dispatch(
        editLocation({
          id: location.id,
          name: values.name,
          value: values.value,
        }),
      );
      onSuccess();
    } catch (error) {
      console.error(error);
      showNotification({
        color: "red",
        message: t("edit_location.error_message.message"),
      });
    }
  });

  return (
    <Modal
      onClose={onClose}
      opened={opened}
      size="xl"
      title={t("edit_location.title")}
      withCloseButton={true}
    >
      <form onSubmit={handleSubmit}>
        <Stack>
          <TextInput
            label={t("edit_location.name.label")}
            withAsterisk
            {...form.getInputProps("name")}
            placeholder={t("edit_location.name.placeholder")}
          />
          <TextInput
            label={t("edit_location.value.label")}
            description={t("edit_location.value.note")}
            {...form.getInputProps("value")}
            placeholder={t("edit_location.value.placeholder")}
          />
          <Button loading={isLoading} mt="lg" onClick={() => handleSubmit()}>
            {t("edit_location.submit")}
          </Button>
        </Stack>
      </form>
    </Modal>
  );
}
