import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import z from "zod";

export function isFetchBaseQueryError(error: unknown): error is FetchBaseQueryError {
  return typeof error === "object" && error != null && "status" in error;
}

const apiError = z.object({
  code: z.string(),
  error: z.string().optional(),
  message: z.string().optional(),
  stack: z.string().optional(),
});

type ApiError = z.infer<typeof apiError>;

export function isApiError(error: unknown): error is ApiError {
  return apiError.safeParse(error).success;
}

const rtkError = z.object({
  status: z.number(),
  data: z.object({
    code: z.string(),
    message: z.string().optional(),
    stack: z.string().optional(),
  }),
});

type RtkError = z.infer<typeof rtkError>;

export function isRtkError(error: unknown): error is RtkError {
  return rtkError.safeParse(error).success;
}
