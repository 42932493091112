import React, { useCallback, useEffect } from "react";
import { TeamId } from "@wendy/types";
import { useDispatch, useSelector } from "../../store/store";
import { selectTeamMessages } from "../../store/selectors";
import { getMessagesForTeam, postMessagesForTeam, readTeamMessages } from "../../store/slices/chat";
import { Chat } from "../ui/chat";

type Props = {
  teamId: TeamId;
};

export function TeamChat({ teamId }: Props) {
  const dispatch = useDispatch();
  const messages = useSelector((state) => selectTeamMessages(state, teamId));

  const sendMessage = useCallback(
    (message: string): void => {
      void dispatch(postMessagesForTeam({ teamId, message }));
    },
    [dispatch, teamId],
  );

  useEffect(() => {
    void dispatch(getMessagesForTeam(teamId));
    void dispatch(readTeamMessages(teamId));
  }, [dispatch, teamId]);

  return <Chat messages={messages} sendMessage={sendMessage} />;
}
