import React from "react";
import { Button, Container, Group, Text } from "@mantine/core";
import { useDocumentTitle } from "@mantine/hooks";
import { Link } from "react-router-dom";
import { IconUserPlus } from "@tabler/icons-react";
import { path } from "../lib/paths";
import { useTranslate } from "../translations";
import { useSelector } from "../store/store";
import { selectTeamList, selectTeamsLoading } from "../store/selectors";
import { TeamList } from "../features/teams";

export default function TeamListPage() {
  const t = useTranslate();
  const loading = useSelector(selectTeamsLoading);
  const teams = useSelector(selectTeamList);

  useDocumentTitle(t("teams.title"));

  return (
    <Container size="xl">
      <Group position="apart" mb="lg">
        <Text size="lg" weight={500}>
          {t("teams.title")}
        </Text>

        <Button
          size="xs"
          leftIcon={<IconUserPlus size={14} />}
          component={Link}
          to={path("team-create")}
          variant="outline"
        >
          {t("action.new_team")}
        </Button>
      </Group>

      <TeamList loading={loading} teams={Object.values(teams)} />
    </Container>
  );
}
