import { Avatar, AvatarProps } from "@mantine/core";
import { UserId } from "@wendy/types";
import { path } from "../../lib/paths";
import { useSelector } from "../../store/store";
import { selectUserById } from "../../store/selectors";

type Props = {
  userId: UserId;
} & Omit<AvatarProps, "src">;

export function UserAvatar({ userId, ...rest }: Props) {
  const user = useSelector((state) => selectUserById(state, userId));
  let width = 100;
  if (typeof rest.size === "number") {
    width = rest.size;
  }
  if (rest.size === "sm") {
    width = 26;
  }
  return user?.avatar ? (
    <Avatar
      {...rest}
      src={path("image", user.avatar, {
        ext: "webp",
        width,
      })}
    />
  ) : (
    <Avatar {...rest} />
  );
}
