import { Container, Tabs, Title } from "@mantine/core";
import { useDocumentTitle } from "@mantine/hooks";
import React from "react";
import {
  AvatarForm,
  ChangePasswordForm,
  ContactInformationForm,
  CreditForm,
} from "../features/account";
import { useTranslate } from "../translations";

export default function MyAccountPage() {
  const t = useTranslate();
  useDocumentTitle(t("my_account.title"));

  return (
    <Container size="md">
      <Title mb="lg" size="h2">
        {t("my_account.title")}
      </Title>

      <Tabs defaultValue="contact_information">
        <Tabs.List>
          <Tabs.Tab value="contact_information">{t("my_account.contact_information")}</Tabs.Tab>
          <Tabs.Tab value="credit">{`Kredit`}</Tabs.Tab>
          <Tabs.Tab value="avatar">{t("my_account.avatar")}</Tabs.Tab>
          <Tabs.Tab value="change_password">{t("my_account.change_password")}</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="contact_information" mt="lg">
          <Container size="xs">
            <ContactInformationForm />
          </Container>
        </Tabs.Panel>

        <Tabs.Panel value="credit" mt="lg">
          <Container size="xs">
            <CreditForm />
          </Container>
        </Tabs.Panel>

        <Tabs.Panel value="avatar" mt="lg">
          <Container size="xs">
            <AvatarForm />
          </Container>
        </Tabs.Panel>

        <Tabs.Panel value="change_password" mt="lg">
          <Container size="xs">
            <ChangePasswordForm />
          </Container>
        </Tabs.Panel>
      </Tabs>
    </Container>
  );
}
