import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { UserId, UserView } from "@wendy/types";
import { api, handleError } from "../../lib/api";

export type UsersState = {
  byId: Record<UserId, UserView>;
};

export const fetchFriends = createAsyncThunk<UserView[], void>("users/fetch-friends", () =>
  api.get(`/user/friends`).then((response) => response.data, handleError),
);

const initialState: UsersState = {
  byId: {},
};

const slice = createSlice({
  name: "users",
  reducers: {},
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchFriends.fulfilled, (state, { payload }) => {
      state.byId = payload.reduce((a, x) => ({ ...a, [x.id]: x }), {});
    });
  },
});

export default slice.reducer;
