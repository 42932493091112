import { createApi } from "@reduxjs/toolkit/query/react";
import { MediaId, User } from "@wendy/types";
import { baseQuery } from "./baseQuery";

export const usersApi = createApi({
  reducerPath: "usersApi",
  baseQuery,
  endpoints: (builder) => ({
    editUser: builder.mutation<User, Partial<Omit<User, "name" | "id">>>({
      query: (data) => ({
        url: `user`,
        method: "PUT",
        body: { data },
      }),
    }),
    editAvatar: builder.mutation<void, { mediaId: MediaId }>({
      query: (body) => ({
        url: `user/avatar`,
        method: "PUT",
        body,
      }),
    }),
  }),
  tagTypes: ["Users"],
});

export const { useEditUserMutation, useEditAvatarMutation } = usersApi;
