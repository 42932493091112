import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Location, LocationId } from "@wendy/types";
import { BASE_URL_API } from "../../config";
import { api } from "../../lib/api";
import { RootState } from "../store";

export type LocationsState = {
  loadingAll: boolean;
  loading: LocationId[];
  records: Location[];
};

export const fetchLocations = createAsyncThunk<Location[], void, { state: RootState }>(
  "locations/fetch",
  async () => {
    const response = await api.get(`/locations`);
    return response.data;
  },
);

export const editLocation = createAsyncThunk<
  LocationId,
  Pick<Location, "id" | "name" | "value">,
  { state: RootState }
>("locations/edit", async (data) =>
  api.put(`${BASE_URL_API}/locations/${data.id}`, {
    name: data.name,
    value: data.value,
  }),
);

export const addLocation = createAsyncThunk<LocationId, Location, { state: RootState }>(
  "locations/add",
  async (data) => {
    const response = await api.post(`/locations`, data);
    return response.data;
  },
);

export const removeLocation = createAsyncThunk<LocationId, LocationId, { state: RootState }>(
  "locations/remove",
  async (id) => {
    const response = await api.delete(`/locations/${id}`);
    return response.data;
  },
);

const initialState: LocationsState = {
  loading: [],
  loadingAll: false,
  records: [],
};

const slice = createSlice({
  name: "teams",
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchLocations.pending, (state) => {
      state.loadingAll = true;
    });
    builder.addCase(fetchLocations.fulfilled, (state, { payload }) => {
      state.loadingAll = false;
      state.records = payload;
    });
    builder.addCase(fetchLocations.rejected, (state) => {
      state.loadingAll = false;
    });

    builder.addCase(addLocation.fulfilled, (state, { meta }) => {
      state.loading = state.loading.filter((x) => x !== meta.arg.id);
      state.records = [...state.records, meta.arg];
    });

    builder.addCase(editLocation.pending, (state, { meta }) => {
      state.loading = [...state.loading, meta.arg.id];
    });
    builder.addCase(editLocation.rejected, (state, { meta }) => {
      state.loading = state.loading.filter((x) => x !== meta.arg.id);
    });
    builder.addCase(editLocation.fulfilled, (state, { meta }) => {
      state.loading = state.loading.filter((x) => x !== meta.arg.id);
      state.records = state.records.map((x) => (x.id === meta.arg.id ? { ...x, ...meta.arg } : x));
    });

    builder.addCase(removeLocation.pending, (state, { meta }) => {
      state.loading = [...state.loading, meta.arg];
    });
    builder.addCase(removeLocation.rejected, (state, { meta }) => {
      state.loading = state.loading.filter((x) => x !== meta.arg);
    });
    builder.addCase(removeLocation.fulfilled, (state, { meta }) => {
      state.loading = state.loading.filter((x) => x !== meta.arg);
      state.records = state.records.filter((x) => x.id !== meta.arg);
    });
  },
  initialState,
});

export default slice.reducer;
