import { Badge } from "@mantine/core";
import { AttendeeState } from "@wendy/types";
import { useTranslate } from "../../translations";

type Who = "me" | "he";

export type Props = {
  attendance?: AttendeeState;
  who: Who;
};

export function AttendanceChip({ attendance, who }: Props) {
  const t = useTranslate();
  if (attendance === "accepted") {
    return (
      <Badge color="green" variant="filled">
        {t(`event.attendance.${who}.accepted`)}
      </Badge>
    );
  }
  if (attendance === "rejected") {
    return (
      <Badge color="red" variant="filled">
        {t(`event.attendance.${who}.rejected`)}
      </Badge>
    );
  }
  if (attendance === "pending") {
    return (
      <Badge color="yellow" variant="filled">
        {t(`event.attendance.${who}.pending`)}
      </Badge>
    );
  }
  return (
    <Badge color="gray" variant="outline">
      {t(`event.attendance.${who}.none`)}
    </Badge>
  );
}
