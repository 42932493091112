import { createSelector } from "@reduxjs/toolkit";
import { EventId, LocationId, TeamId, UserId } from "@wendy/types";
import { RootState } from "./store";

export const selectAccount = (state: RootState) => state.account;
const selectChat = (state: RootState) => state.chat;
const selectUsers = (state: RootState) => state.users;
export const selectMembers = (state: RootState) => state.members;
export const selectTeams = (state: RootState) => state.teams;
export const selectLocations = (state: RootState) => state.locations;

export const selectUserById = createSelector(
  selectUsers,
  (_: RootState, userId: UserId) => userId,
  ({ byId }, id) => (id in byId ? byId[id] : undefined),
);
export const selectUserName = createSelector(
  selectUsers,
  (_: RootState, userId: UserId) => userId,
  ({ byId }, id) => (id in byId ? `${byId[id].first_name} ${byId[id].last_name}` : ""),
);

export const selectEventMessages = createSelector(
  selectChat,
  (_: RootState, eventId: EventId) => eventId,
  ({ messages }, eventId) =>
    Object.values(messages)
      .filter((x) => x.for === "event" && x.target === eventId)
      .sort((a, b) => (a.time > b.time ? 1 : -1)),
);

export const selectTeamMessages = createSelector(
  selectChat,
  (_: RootState, teamId: TeamId) => teamId,
  ({ messages }, teamId) =>
    Object.values(messages)
      .filter((x) => x.for === "team" && x.target === teamId)
      .sort((a, b) => (a.time > b.time ? 1 : -1)),
);

export const selectMessageCountIsLoading = createSelector(selectChat, ({ loading }) => loading);
export const selectTotalCountOfUnreadMessages = createSelector(
  selectChat,
  ({ totalCountOfUnreadMessages }) => totalCountOfUnreadMessages,
);
export const selectUnreadEvents = createSelector([selectChat], ({ unread }) =>
  unread.filter((x) => x.for === "event"),
);
export const selectUnreadTeams = createSelector(selectChat, ({ unread }) =>
  unread.filter((x) => x.for === "team"),
);
export const selectNumberOfUnreadMessagesInEvent = createSelector(
  selectUnreadEvents,
  (_: RootState, eventId: EventId) => eventId,
  (xs, eventId) => xs.filter((x) => x.target === eventId).reduce((a, x) => a + x.count, 0),
);
export const selectNumberOfUnreadMessagesInTeam = createSelector(
  selectUnreadTeams,
  (_: RootState, id: TeamId) => id,
  (xs, teamId) => xs.filter((x) => x.target === teamId).reduce((a, x) => a + x.count, 0),
);

export const selectTeam = createSelector(
  selectTeams,
  (_: RootState, id: TeamId) => id,
  ({ records }, id) => records.find((record) => record.id === id),
);
export const selectTeamList = createSelector(selectTeams, ({ records }) => records);
export const selectTeamsLoading = createSelector(selectTeams, ({ loadingAll }) => loadingAll);
export const selectSelectedTeamId = createSelector(selectTeams, ({ selected }) => selected);
export const selectSelectedTeam = createSelector(
  selectTeams,
  selectSelectedTeamId,
  ({ records }, selected) =>
    selected ? records.find((record) => record.id === selected) ?? null : null,
);

export const selectTeamMembers = createSelector(
  selectMembers,
  selectUsers,
  (_: RootState, id: TeamId) => id,
  ({ members }, { byId }, id) =>
    members
      .filter(({ team }) => team === id)
      .sort((a, b) =>
        b.role === "manager" && byId[a.user]?.last_name > byId[b.user]?.last_name ? 1 : -1,
      ),
);

export const selectIsTeamManager = createSelector(
  selectMembers,
  selectAccount,
  (_: RootState, id: TeamId) => id,
  ({ members }, { user }, id) =>
    user
      ? members.some(
          (member) => member.user === user.id && member.role === "manager" && member.team === id,
        )
      : false,
);

export const selectTeamLocations = createSelector(
  selectLocations,
  (_: RootState, id: TeamId) => id,
  ({ records }, id) => records.filter((record) => record.team === id),
);
export const selectLocation = createSelector(
  selectLocations,
  (_: RootState, id: LocationId) => id,
  ({ records }, id) => records.find((record) => record.id === id) ?? null,
);
export const selectIsAllLocationLoading = createSelector(
  selectLocations,
  ({ loadingAll }) => loadingAll,
);
export const selectIsLocationLoading = createSelector(
  selectLocations,
  (_: RootState, id: LocationId) => id,
  ({ loading, loadingAll }, id) => loadingAll || loading.includes(id),
);
