import { ActionIcon, Paper, ScrollArea, Stack, Text, TextInput } from "@mantine/core";
import React, { useCallback, useEffect, useRef } from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { IconSend } from "@tabler/icons-react";
import { useDispatch, useSelector } from "../../store/store";
import { useTranslate } from "../../translations";
import { ChatMessage as ChatMessageComponent } from "./chat-message";
import { ChatMessage } from "../../store/slices/chat";

dayjs.extend(relativeTime);

type Props = {
  messages: ChatMessage[];
  sendMessage: (message: string) => void;
};

export function Chat({ messages, sendMessage }: Props) {
  const t = useTranslate();
  const dispatch = useDispatch();
  const message = useRef<HTMLInputElement | null>(null);
  const viewport = useRef<HTMLDivElement>(null);
  const isConnected = useSelector((state) => state.chat.connected);
  const innerSendMessage = useCallback(() => {
    const value = message.current?.value.trim();
    if (value) {
      sendMessage(value);
    }
    if (message.current?.value) {
      message.current.value = "";
    }
  }, [sendMessage]);

  useEffect(() => {
    const handler = (event: KeyboardEvent): void => {
      if (event.key === "Enter") {
        innerSendMessage();
      }
    };
    message.current?.addEventListener("keypress", handler);
  }, [dispatch, innerSendMessage]);

  useEffect(() => {
    if (viewport.current) {
      viewport.current.scrollTo({ top: viewport.current.scrollHeight, behavior: "smooth" });
    }
  }, [messages]);

  return (
    <Stack>
      <ScrollArea.Autosize mah={400} viewportRef={viewport}>
        <Stack spacing="xs" p="xs">
          {messages.length === 0 ? (
            <Paper shadow="sm" p="xs">
              <Text size="sm" align="center">
                {t("chat.no_messages")}
              </Text>
            </Paper>
          ) : null}
          {messages.map((message) => (
            <ChatMessageComponent {...message} key={message.id} />
          ))}
        </Stack>
      </ScrollArea.Autosize>
      <TextInput
        autoComplete="off"
        placeholder={t("chat.message_placeholder")}
        ref={message}
        rightSection={
          <ActionIcon
            loading={!isConnected}
            onClick={innerSendMessage}
            title={t("chat.send")}
            variant="filled"
            color="blue"
          >
            <IconSend size={18} />
          </ActionIcon>
        }
      />
    </Stack>
  );
}
