import { Text } from "@mantine/core";
import dayjs from "dayjs";
import React from "react";

export type Props = {
  format: string;
  value: string;
};

export function DateTime({ format, value }: Props) {
  return (
    <Text sx={{ fontFamily: "monospace", whiteSpace: "nowrap" }} size="xs">
      {dayjs(value).format(format)}
    </Text>
  );
}
