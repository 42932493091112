import { Button, NumberInput, Stack, Text } from "@mantine/core";
import { useForm, zodResolver } from "@mantine/form";
import { showNotification } from "@mantine/notifications";
import { z } from "zod";
import { api } from "../../lib/api";
import { fetchCredit } from "../../store/slices/account";
import { useDispatch, useSelector } from "../../store/store";

export type ContactInformation = {
  email: string;
  first_name: string;
  last_name: string;
  phone: string;
};

export function CreditForm() {
  const dispatch = useDispatch();
  const balance = useSelector(
    (state) => state.account.credit.amount - state.account.credit.blocked,
  );
  const schema = z.object({
    amount: z.number(),
  });
  const form = useForm({
    initialValues: {
      amount: 0,
    },
    validate: zodResolver(schema),
  });
  const onSubmit = form.onSubmit(async (values) => {
    try {
      await api.post("/user/credit", values);
      dispatch(fetchCredit());
      showNotification({
        color: "green",
        message: `Kredit navýšen o ${values.amount} Kč`,
      });
    } catch (err) {
      const error = err as any;
      console.error(error);
      form.reset();
      showNotification({
        color: "red",
        message: error.data.message,
      });
    }
  });

  return (
    <form onSubmit={onSubmit}>
      <Stack>
        <Text color="red">Slouží jen k testovacím účelům!</Text>
        <Text weight={700}>{`Aktuální kredit je: ${balance} Kč`}</Text>
        <NumberInput label={`Částka`} withAsterisk {...form.getInputProps("amount")} />
        <Button type="submit" sx={{ alignSelf: "start" }}>
          {`Poslat`}
        </Button>
      </Stack>
    </form>
  );
}
