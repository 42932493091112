import { Button, Group, PasswordInput, Stack, TextInput } from "@mantine/core";
import { useForm, zodResolver } from "@mantine/form";
import { ErrorCode } from "@wendy/types";
import React, { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import z from "zod";
import useFormMessage from "../../hooks/useFormMessage";
import { path } from "../../lib/paths";
import { isApiError } from "../../lib/server";
import { signIn } from "../../store/slices/account";
import { useDispatch } from "../../store/store";
import { useTranslate } from "../../translations";

type Props = {
  email?: string;
  onSuccess?: () => void;
};

export function LoginForm({ email = "", onSuccess }: Props) {
  const t = useTranslate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const loginSchema = useMemo(
    () =>
      z.object({
        email: z
          .string()
          .min(1, t("login_form.email.required"))
          .email(t("login_form.email.wrong_format")),
        password: z.string().min(1, t("login_form.password.required")),
      }),
    [t],
  );
  const form = useForm({
    initialValues: {
      email,
      password: "",
    },
    validate: zodResolver(loginSchema),
  });
  const { isOpen, messageProps, Message, setError, setSuccess } = useFormMessage();
  const handleSubmit = form.onSubmit(async (credentials) => {
    try {
      setLoading(true);
      await dispatch(signIn(credentials)).unwrap();
      setSuccess({
        message: t("login_form.success_message.message"),
        title: t("login_form.success_message.title"),
      });
      form.reset();
      onSuccess?.();
    } catch (error) {
      if (isApiError(error)) {
        switch (error.code) {
          case ErrorCode.NOT_FOUND:
          case ErrorCode.PASSWORD_NOT_VALID:
            return setError({
              message: t("login_form.credentials_not_valid"),
              title: t("error"),
            });
          case ErrorCode.PASSWORD_NOT_FOUND:
            return setError({
              message: t("login_form.password_not_found"),
              title: t("error"),
            });
        }
      }
      setError({
        message: t("error.unknown_error_type"),
        title: t("error"),
      });
    } finally {
      setLoading(false);
    }
  });

  return (
    <form onSubmit={handleSubmit}>
      <Stack>
        {isOpen && <Message {...messageProps} />}
        <TextInput
          disabled={email !== ""}
          label={t("login_form.email.label")}
          placeholder={t("login_form.email.placeholder")}
          autoComplete="email"
          withAsterisk
          {...form.getInputProps("email")}
        />
        <PasswordInput label={t("password")} withAsterisk {...form.getInputProps("password")} />
        <Button fullWidth loading={loading} type="submit">
          {t("login_form.button.label")}
        </Button>
        <Group grow mt="lg">
          <Button component={Link} to={path("forgetten-password")} variant="light">
            {t("login_form.forgetten_password")}
          </Button>
          <Button component={Link} to={path("register")} variant="light">
            {t("login_form.register")}
          </Button>
        </Group>
      </Stack>
    </form>
  );
}
