import { Container, Tabs, Title } from "@mantine/core";
import { useDocumentTitle } from "@mantine/hooks";
import { Navigate, useParams } from "react-router-dom";
import { TeamId } from "@wendy/types";
import { TeamEditForm } from "../features/teams";
import { useTranslate } from "../translations";
import { useSelector } from "../store/store";
import { selectIsTeamManager, selectTeam } from "../store/selectors";
import { TeamMembersForm } from "../features/teams/team-members-form";
import { path } from "../lib/paths";

function ValidParameters({ id }: { id: TeamId }) {
  const t = useTranslate();
  const isManager = useSelector((state) => (id ? selectIsTeamManager(state, id) : false));
  const team = useSelector((state) => selectTeam(state, id));

  useDocumentTitle(t("team_form.edit_team"));

  if (!team) {
    throw new Error("page not found");
  }
  if (!isManager) {
    return <Navigate to={path("team-detail", id)} />;
  }

  return (
    <Container size="sm">
      <Title mb="lg" size="h2">
        {t("team_form.edit_team")}
      </Title>

      <Tabs defaultValue="details">
        <Tabs.List>
          <Tabs.Tab value="details">{t("team_form.details")}</Tabs.Tab>
          <Tabs.Tab value="roles">{t("team_form.roles")}</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="details" py="lg">
          <TeamEditForm team={team} />
        </Tabs.Panel>

        <Tabs.Panel value="roles" py="lg">
          <TeamMembersForm team={team.id} />
        </Tabs.Panel>
      </Tabs>
    </Container>
  );
}

export default function TeamEditPage() {
  const { id } = useParams<{ id: TeamId }>();

  if (!id) {
    throw new Error("missing parameter id");
  }

  return <ValidParameters id={id} />;
}
