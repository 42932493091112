import { Container } from "@mantine/core";
import { useDocumentTitle } from "@mantine/hooks";
import { showNotification } from "@mantine/notifications";
import { useNavigate } from "react-router-dom";
import { TeamCreateForm } from "../features/teams";
import { path } from "../lib/paths";
import { useTranslate } from "../translations";

export default function TeamCreatePage() {
  const t = useTranslate();
  const navigate = useNavigate();
  useDocumentTitle(t("team_form.new_team"));

  return (
    <Container size="sm">
      <TeamCreateForm
        onSuccess={(team) => {
          navigate(path("team-detail", team.id));
          showNotification({
            color: "teal",
            message: t("team_create_form.success"),
          });
        }}
      />
    </Container>
  );
}
