import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "../store/store";
import { signOut } from "../store/slices/account";
import { path } from "../lib/paths";

export const useAuth = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.account.user);
  const navigate = useNavigate();

  const value = useMemo(
    () => ({
      user,
      logout: () => {
        dispatch(signOut());
        navigate(path("login"), { replace: true });
      },
    }),
    [user, dispatch, navigate],
  );

  return value;
};
