import { Card, Container, SimpleGrid, Text, Title, useMantineTheme } from "@mantine/core";
import { useDocumentTitle } from "@mantine/hooks";
import { IconCalendarEvent, IconUsers, IconNotification } from "@tabler/icons-react";
import React from "react";
import { useTranslate } from "../translations";

export default function HomePage() {
  const t = useTranslate();
  const theme = useMantineTheme();
  useDocumentTitle(t("home.title"));

  return (
    <Container>
      <Title align="center" mb="xl">
        {t("home.title")}
      </Title>

      <SimpleGrid cols={3} spacing="xl" mt={50} breakpoints={[{ maxWidth: "md", cols: 1 }]}>
        <Card shadow="md" radius="md" p="xl">
          <IconCalendarEvent size={50} color={theme.fn.primaryColor()} />
          <Text size="lg" weight={500} mt="md">
            Správa událostí
          </Text>
          <Text size="sm" color="dimmed" mt="sm">
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Etiam bibendum elit eget erat.
            Sed elit dui, pellentesque a, faucibus vel, interdum nec, diam. Phasellus rhoncus.
            Curabitur sagittis hendrerit ante. Nulla non lectus sed nisl molestie malesuada. Nullam
            faucibus mi quis velit.
          </Text>
        </Card>
        <Card shadow="md" radius="md" p="xl">
          <IconUsers size={50} color={theme.fn.primaryColor()} />
          <Text size="lg" weight={500} mt="md">
            Správa týmů
          </Text>
          <Text size="sm" color="dimmed" mt="sm">
            Etiam quis quam. Duis sapien nunc, commodo et, interdum suscipit, sollicitudin et,
            dolor. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit
            laboriosam, nisi ut aliquid ex ea commodi consequatur? Praesent vitae arcu tempor neque
            lacinia pretium.
          </Text>
        </Card>
        <Card shadow="md" radius="md" p="xl">
          <IconNotification size={50} color={theme.fn.primaryColor()} />
          <Text size="lg" weight={500} mt="md">
            Včasné aktualizace
          </Text>
          <Text size="sm" color="dimmed" mt="sm">
            Nulla non lectus sed nisl molestie malesuada. Nullam dapibus fermentum ipsum. Integer
            pellentesque quam vel velit. Nulla quis diam. Aliquam erat volutpat. Vivamus ac leo
            pretium faucibus.
          </Text>
        </Card>
      </SimpleGrid>
    </Container>
  );
}
