import { createStyles, Title, Text, Button, Container, Group } from "@mantine/core";
import { useDocumentTitle } from "@mantine/hooks";
import { Link } from "react-router-dom";
import { path } from "../lib/paths";
import { useTranslate } from "../translations";

const useStyles = createStyles((theme) => ({
  root: {
    paddingTop: 80,
    paddingBottom: 80,
  },

  label: {
    textAlign: "center",
    fontWeight: 900,
    fontSize: 220,
    lineHeight: 1,
    marginBottom: `calc(${theme.spacing.xl} * 1.5}`,
    color: theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[2],

    [theme.fn.smallerThan("sm")]: {
      fontSize: 120,
    },
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    textAlign: "center",
    fontWeight: 900,
    fontSize: 38,

    [theme.fn.smallerThan("sm")]: {
      fontSize: 32,
    },
  },

  description: {
    maxWidth: 500,
    margin: "auto",
    marginTop: theme.spacing.xl,
    marginBottom: `calc(${theme.spacing.xl} * 1.5)`,
  },
}));

export default function Error404Page() {
  const { classes } = useStyles();
  const t = useTranslate();

  useDocumentTitle(t("error_404.title"));

  return (
    <Container className={classes.root}>
      <div className={classes.label}>404</div>
      <Title className={classes.title}>{t("error_404.title")}</Title>
      <Text color="dimmed" size="lg" align="center" className={classes.description}>
        {t("error_404.note")}
      </Text>
      <Group position="center">
        <Button size="md" variant="subtle" component={Link} to={path("home")}>
          {t("error_404.home_link")}
        </Button>
      </Group>
    </Container>
  );
}
