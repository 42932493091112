import { Button, Grid, PasswordInput, TextInput, Title } from "@mantine/core";
import { useForm, zodResolver } from "@mantine/form";
import React, { useMemo, useState } from "react";
import { z } from "zod";
import useFormMessage from "../../hooks/useFormMessage";
import {
  PasswordStrengthMeter,
  calculateStrength,
  isPasswordValid,
} from "../ui/password-strength-meter";
import { useTranslate } from "../../translations";
import { isApiError } from "../../lib/server";
import { ErrorCode } from "@wendy/types";
import { resetPassword } from "../../store/slices/account";
import { useDispatch } from "../../store/store";

type Props = {
  token: string;
};

export function PasswordResetForm({ token }: Props) {
  const t = useTranslate();
  const dispatch = useDispatch();
  const { isOpen, messageProps, setError, setSuccess, Message } = useFormMessage();
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const schema = useMemo(
    () =>
      z
        .object({
          token: z.string().uuid(t("password_reset.token.invalid")),
          password: z.string().min(1, t("password_reset.password.required")),
        })
        .refine((x) => isPasswordValid(x.password), {
          message: t("password_reset.password.invalid"),
          path: ["password"],
        }),
    [t],
  );
  const form = useForm({
    initialValues: {
      token,
      password: "",
    },
    validate: zodResolver(schema),
  });
  const handleSubmit = form.onSubmit(async (values) => {
    try {
      setLoading(false);
      await dispatch(resetPassword(values)).unwrap();
      setSuccess({
        message: t("password_reset.success_message.message"),
        title: t("password_reset.success_message.title"),
      });
      form.reset();
    } catch (error) {
      if (isApiError(error)) {
        switch (error.code) {
          case ErrorCode.TOKEN_EXPIRED:
            return setError({
              message: t("password_reset.token.expired"),
              title: t("error"),
            });
        }
        setError({
          message: t("error.unknown_error_type"),
          title: t("error"),
        });
      }
    } finally {
      setLoading(false);
    }
  });

  return (
    <form onSubmit={handleSubmit}>
      <Grid>
        <Grid.Col>
          <Title align="center">{t("password_reset.title")}</Title>
        </Grid.Col>
        {isOpen && (
          <Grid.Col>
            <Message {...messageProps} />
          </Grid.Col>
        )}
        <Grid.Col>
          <TextInput
            disabled
            label={t("password_reset.token.label")}
            description={t("password_reset.token.description")}
            withAsterisk
            {...form.getInputProps("token")}
          />
        </Grid.Col>
        <Grid.Col>
          <PasswordInput
            label={t("password_reset.password.label")}
            mb="sm"
            withAsterisk
            {...form.getInputProps("password")}
            onChange={(ev) => {
              setPassword(ev.target.value);
              return form.getInputProps("password").onChange(ev.target.value);
            }}
          />
          <PasswordStrengthMeter strength={calculateStrength(password)} />
        </Grid.Col>
        <Grid.Col>
          <Button fullWidth loading={loading} type="submit">
            {t("password_reset.submit")}
          </Button>
        </Grid.Col>
      </Grid>
    </form>
  );
}
