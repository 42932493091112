import { Button, Grid, NumberInput, Textarea, TextInput } from "@mantine/core";
import { DateTimePicker } from "@mantine/dates";
import { useForm, zodResolver } from "@mantine/form";
import z from "zod";
import { Event, EventId, TeamId } from "@wendy/types";
import { useTranslate } from "../../translations";
import { useAddEventMutation } from "../../services/events";
import { LocationsSelect } from "../locations";
import { useMemo } from "react";

export type Props = {
  defaultValues?: Event;
  onSuccess: (eventId: EventId) => void;
  team: TeamId;
};

type FormValues = {
  allowedDebt: number | "";
  cancelationFee: number | "";
  capacity: number;
  description: string;
  location: string | null;
  name: string;
  price: number | "";
  time: Date;
};

export function EventCreateForm({ onSuccess, team, defaultValues }: Props) {
  const t = useTranslate();
  const schema = useMemo(
    () =>
      z.object({
        name: z.string().min(1, { message: t("form.required_field") }),
        location: z.string().uuid().nullable(),
        time: z.date().min(new Date("1970-01-01"), { message: t("form.required_field") }),
        description: z.string().nullable(),
        capacity: z.number().min(1, { message: t("form.required_field") }),
      }),
    [t],
  );
  const form = useForm<FormValues>({
    initialValues: {
      allowedDebt: defaultValues?.allowedDebt ?? "",
      cancelationFee: defaultValues?.cancelationFee ?? "",
      capacity: defaultValues?.capacity ?? 1,
      description: defaultValues?.description ?? "",
      location: defaultValues?.location ?? null,
      name: defaultValues?.name ?? "",
      price: defaultValues?.price ?? "",
      time: defaultValues?.time ? new Date(defaultValues?.time) : new Date(),
    },
    validate: zodResolver(schema),
  });
  const [addEvent, { isLoading }] = useAddEventMutation();
  const handleSubmit = form.onSubmit(async (values) => {
    const id = crypto.randomUUID();
    await addEvent({
      id,
      allowedDebt: values.allowedDebt === "" ? null : values.allowedDebt,
      cancelationFee:
        values.cancelationFee === "" || values.cancelationFee <= 0 ? null : values.cancelationFee,
      capacity: values.capacity,
      description: values.description,
      location: values.location,
      name: values.name,
      price: values.price === "" ? null : values.price,
      team,
      time: values.time.toISOString(),
    }).unwrap();

    onSuccess(id);
  });

  return (
    <form onSubmit={handleSubmit}>
      <Grid>
        <Grid.Col>
          <TextInput label={t("event_edit.name")} withAsterisk {...form.getInputProps("name")} />
        </Grid.Col>
        <Grid.Col>
          <Textarea label={t("event_edit.description")} {...form.getInputProps("description")} />
        </Grid.Col>
        <Grid.Col md={4}>
          <DateTimePicker
            dropdownType="modal"
            label={t("event_edit.date_time")}
            locale="cs"
            valueFormat="DD.MM.YYYY HH:mm"
            withAsterisk
            {...form.getInputProps("time")}
          />
        </Grid.Col>
        <Grid.Col md={4}>
          <NumberInput
            label={t("event_edit.capacity")}
            withAsterisk
            {...form.getInputProps("capacity")}
          />
        </Grid.Col>
        <Grid.Col md={12}>
          <LocationsSelect team={team} {...form.getInputProps("location")} />
        </Grid.Col>
        <Grid.Col md={4}>
          <NumberInput label={t("event_edit.price")} min={0} {...form.getInputProps("price")} />
        </Grid.Col>
        <Grid.Col md={4}>
          <NumberInput
            label={t("event_edit.cancelation_fee")}
            min={0}
            {...form.getInputProps("cancelationFee")}
          />
        </Grid.Col>
        <Grid.Col md={4}>
          <NumberInput
            label={t("event_edit.allowed_debt")}
            min={0}
            {...form.getInputProps("allowedDebt")}
          />
        </Grid.Col>
        <Grid.Col>
          <Button loading={isLoading} type="submit">
            {t("event_create.submit")}
          </Button>
        </Grid.Col>
      </Grid>
    </form>
  );
}
