import {
  Button,
  NumberInput,
  SimpleGrid,
  Stack,
  Text,
  Textarea,
  TextInput,
  Title,
} from "@mantine/core";
import z from "zod";
import { DateTimePicker } from "@mantine/dates";
import { useForm, zodResolver } from "@mantine/form";
import { Event, EventUpdate } from "@wendy/types";
import { useTranslate } from "../../translations";
import { LocationsSelect } from "../locations";

export type Props = {
  isLoading?: boolean;
  data: Event;
  onSave: (event: EventUpdate) => void;
};

type FormValues = {
  allowedDebt: number | "";
  cancelationFee: number | "";
  capacity: number;
  description: string;
  location: string | null;
  name: string;
  price: number | "";
  time: Date;
};

export function EventEditForm({ isLoading, data, onSave }: Props) {
  const t = useTranslate();
  const schema = z.object({
    name: z.string().min(1, { message: t("form.required_field") }),
    time: z.date().min(new Date("1970-01-01"), { message: t("form.required_field") }),
    location: z
      .string()
      .uuid({ message: t("form.required_field") })
      .nullable(),
    description: z.string(),
    capacity: z.number().min(1, { message: t("form.required_field") }),
    cancelationFee: z.number().nullable().or(z.literal("")),
    allowedDebt: z.number().nullable().or(z.literal("")),
  });
  const form = useForm<FormValues>({
    initialValues: {
      name: data.name,
      time: new Date(data.time),
      description: data.description,
      cancelationFee: data.cancelationFee == null ? "" : data.cancelationFee,
      capacity: data.capacity,
      location: data.location,
      price: data.price == null ? "" : data.price,
      allowedDebt: data.allowedDebt == null ? "" : data.allowedDebt,
    },
    validate: zodResolver(schema),
  });
  const handleSubmit = form.onSubmit((values) => {
    if (isLoading) {
      return;
    }

    onSave({
      allowedDebt: values.allowedDebt === "" ? null : Number(values.allowedDebt),
      cancelationFee:
        values.cancelationFee === "" || values.cancelationFee <= 0
          ? null
          : Number(values.cancelationFee),
      capacity: values.capacity,
      description: values.description,
      location: values.location,
      name: values.name,
      price: values.price === "" ? null : Number(values.price),
      time: values.time.toISOString(),
    });
  });

  return (
    <form onSubmit={handleSubmit}>
      <Title mb="lg" size="h2">
        {t("event_edit.title")}
      </Title>

      <Stack>
        <TextInput label={t("event_edit.name")} withAsterisk {...form.getInputProps("name")} />
        <Textarea
          autosize
          label={t("event_edit.description")}
          {...form.getInputProps("description")}
        />
        <SimpleGrid cols={2}>
          <DateTimePicker
            dropdownType="modal"
            label={t("event_edit.date")}
            locale="cs"
            valueFormat="DD.MM.YYYY HH:mm"
            withAsterisk
            {...form.getInputProps("time")}
          />
        </SimpleGrid>
        <SimpleGrid cols={3}>
          <NumberInput
            autoComplete="off"
            label={t("event_edit.price")}
            min={0}
            rightSection={<Text color="dimmed">Kč</Text>}
            {...form.getInputProps("price")}
          />
          <NumberInput
            autoComplete="off"
            label={t("event_edit.cancelation_fee")}
            min={0}
            rightSection={<Text color="dimmed">Kč</Text>}
            {...form.getInputProps("cancelationFee")}
          />
          <NumberInput
            autoComplete="off"
            label={t("event_edit.allowed_debt")}
            min={0}
            rightSection={<Text color="dimmed">Kč</Text>}
            {...form.getInputProps("allowedDebt")}
          />
        </SimpleGrid>
        <LocationsSelect
          team={data.team}
          {...form.getInputProps("location")}
          onChange={(id) => form.setFieldValue("location", id)}
        />
        <NumberInput
          description={t("event_edit.capacity.description")}
          label={t("event_edit.capacity")}
          sx={{ alignSelf: "start" }}
          withAsterisk
          min={data.capacity}
          {...form.getInputProps("capacity")}
        />
        <Button sx={{ alignSelf: "start" }} loading={isLoading} type="submit">
          {t("event_edit.submit")}
        </Button>
      </Stack>
    </form>
  );
}
