import { Group, Paper, Stack, Text } from "@mantine/core";
import React from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useSelector } from "../../store/store";
import { useTranslate } from "../../translations";
import { UserAvatar } from "./user-avatar";
import { selectUserName } from "../../store/selectors";
import { ChatMessage as Props } from "../../store/slices/chat";

dayjs.extend(relativeTime);

export function ChatMessage({ from, message, time }: Props) {
  const t = useTranslate();

  const userName = useSelector((state) => selectUserName(state, from));

  return (
    <Paper shadow="sm" p="xs">
      <Group spacing="xs" sx={{ alignItems: "flex-start" }}>
        <UserAvatar userId={from} />
        <Stack spacing="xs">
          <Group>
            <Text fz="sm">{userName ?? t("unknown")}</Text>
            <Text fz="xs" c="dimmed">
              {dayjs(time).fromNow()}
            </Text>
          </Group>
          <Text>{message}</Text>
        </Stack>
      </Group>
    </Paper>
  );
}
