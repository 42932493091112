import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { EventId, TeamId, UserId } from "@wendy/types";
import { api } from "../../lib/api";
import { RootState } from "../store";

export type ChatTarget = { for: "event"; target: EventId } | { for: "team"; target: TeamId };

export type ChatMessageId = string;

export type ChatMessage = {
  id: ChatMessageId;
  from: UserId;
  message: string;
  time: string;
} & ChatTarget;

export type ChatMessagePayload = { type: "message" } & ChatMessage;

export type UnreadMessagesReport = {
  count: number;
  /** time of the last message */
  lastMessageTime: string | null;
  name: string;
} & ChatTarget;

export type CountUnreadMessagesResponse = UnreadMessagesReport[];

export type ChatState = {
  loading: boolean;
  connected: boolean;
  messages: Record<ChatMessageId, ChatMessage>;
  totalCountOfUnreadMessages: number;
  unread: CountUnreadMessagesResponse;
};

export const countUnreadMessages = createAsyncThunk<
  CountUnreadMessagesResponse,
  void,
  { state: RootState }
>("chat/countUnreadMessages", async () => {
  const response = await api.get(`/chat/count-unread`);
  return response.data;
});

export const readAllEventMessages = createAsyncThunk<
  CountUnreadMessagesResponse,
  void,
  { state: RootState }
>("chat/readAllEventMessages", async () => {
  const response = await api.put(`/chat/read-event-all`, null);
  return response.data;
});

export const readEventMessages = createAsyncThunk<
  CountUnreadMessagesResponse,
  EventId,
  { state: RootState }
>("chat/readEventMessages", async (eventId) => {
  const response = await api.put(`/chat/read-event/${eventId}`, null);
  return response.data;
});

export const readAllTeamMessages = createAsyncThunk<
  CountUnreadMessagesResponse,
  void,
  { state: RootState }
>("chat/readAllTeamMessages", async () => {
  const response = await api.put(`/chat/read-team-all/`, null);
  return response.data;
});

export const readTeamMessages = createAsyncThunk<
  CountUnreadMessagesResponse,
  TeamId,
  { state: RootState }
>("chat/readTeamMessages", async (teamId) => {
  const response = await api.put(`/chat/read-team/${teamId}`, null);
  return response.data;
});

export const getMessagesForEvent = createAsyncThunk<ChatMessage[], EventId, { state: RootState }>(
  "chat/getMessagesForEvent",
  async (eventId: EventId) => {
    const response = await api.get(`/chat/for-event/${eventId}`);
    return response.data;
  },
);

export const getMessagesForTeam = createAsyncThunk<ChatMessage[], TeamId, { state: RootState }>(
  "chat/getMessagesForTeam",
  async (teamId: TeamId) => {
    const response = await api.get(`/chat/for-team/${teamId}`);
    return response.data;
  },
);

export const postMessagesForEvent = createAsyncThunk<
  ChatMessage,
  { eventId: EventId; message: string },
  { state: RootState }
>("chat/postMessagesForEvent", async ({ eventId, message }) => {
  const response = await api.post(`/chat/for-event/${eventId}`, { message });
  return response.data;
});

export const postMessagesForTeam = createAsyncThunk<
  ChatMessage,
  { teamId: TeamId; message: string },
  { state: RootState }
>("chat/postMessagesForTeam", async ({ teamId, message }) => {
  const response = await api.post(`/chat/for-team/${teamId}`, { message });
  return response.data;
});

const initialState: ChatState = {
  loading: false,
  connected: false,
  messages: {},
  totalCountOfUnreadMessages: 0,
  unread: [],
};

const slice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    setConnected(state, { payload }: PayloadAction<boolean>) {
      state.connected = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(countUnreadMessages.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(countUnreadMessages.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.unread = payload;
      state.totalCountOfUnreadMessages = payload.reduce((a, x) => a + x.count, 0);
    });
    builder.addCase(readAllEventMessages.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(readAllEventMessages.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.unread = payload;
      state.totalCountOfUnreadMessages = payload.reduce((a, x) => a + x.count, 0);
    });
    builder.addCase(readEventMessages.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(readEventMessages.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.unread = payload;
      state.totalCountOfUnreadMessages = payload.reduce((a, x) => a + x.count, 0);
    });
    builder.addCase(readAllTeamMessages.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(readAllTeamMessages.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.unread = payload;
      state.totalCountOfUnreadMessages = payload.reduce((a, x) => a + x.count, 0);
    });
    builder.addCase(readTeamMessages.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(readTeamMessages.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.unread = payload;
      state.totalCountOfUnreadMessages = payload.reduce((a, x) => a + x.count, 0);
    });
    builder.addCase(getMessagesForEvent.fulfilled, (state, { payload }) => {
      state.messages = {
        ...state.messages,
        ...payload.reduce((a, x) => ({ ...a, [x.id]: x }), state.messages),
      };
    });
    builder.addCase(getMessagesForTeam.fulfilled, (state, { payload }) => {
      state.messages = {
        ...state.messages,
        ...payload.reduce((a, x) => ({ ...a, [x.id]: x }), state.messages),
      };
    });
    builder.addCase(postMessagesForEvent.fulfilled, (state, { payload }) => {
      state.messages = { ...state.messages, [payload.id]: payload };
    });
    builder.addCase(postMessagesForTeam.fulfilled, (state, { payload }) => {
      state.messages = { ...state.messages, [payload.id]: payload };
    });
  },
});

export const { setConnected } = slice.actions;
export default slice.reducer;
