import { useRouteError } from "react-router-dom";

export function RouterErrorBoundary() {
  let error = useRouteError() as Error;
  return (
    <div>
      <h1>Uh oh, něco se pokazilo 😩</h1>
      <pre>{error.message || JSON.stringify(error)}</pre>
      <button onClick={() => (window.location.href = "/")}>
        Kliknutí sem přenačtete celou aplikaci.
      </button>
    </div>
  );
}
