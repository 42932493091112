import React from "react";
import { Alert } from "@mantine/core";
import { IconAlertCircle } from "@tabler/icons-react";
import { isApiError } from "../../lib/server";
import { useTranslate } from "../../translations";

type Props = {
  error?: unknown;
};

export function ErrorAlert({ error }: Props) {
  const t = useTranslate();
  if (isApiError(error)) {
    return (
      <Alert color="red" icon={<IconAlertCircle size={16} />} title={t("error")}>
        {error.message}
      </Alert>
    );
  } else {
    return (
      <Alert color="red" icon={<IconAlertCircle size={16} />} title={t("error")}>
        {JSON.stringify(error)}
      </Alert>
    );
  }
}
