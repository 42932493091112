import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  Grid,
  Group,
  Loader,
  LoadingOverlay,
  Pagination,
  Select,
  SimpleGrid,
  Text,
} from "@mantine/core";
import { useDocumentTitle } from "@mantine/hooks";
import { IconCalendarPlus } from "@tabler/icons-react";
import { TeamId } from "@wendy/types";
import { Link, Navigate } from "react-router-dom";
import { useSearchEventsMutation } from "../services/events";
import { useTranslate } from "../translations";
import { ErrorAlert } from "../features/ui/error-alert";
import { EventCard } from "../features/events";
import { path } from "../lib/paths";
import useLocalStorage from "../hooks/use-local-storage";
import { useSelector } from "../store/store";
import { selectSelectedTeamId, selectTeamsLoading } from "../store/selectors";

export function PageContent({ team }: { team: TeamId }) {
  const t = useTranslate();
  const pageSize = 12;
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [searchEvents, { data, isLoading: isEventsLoading, error: eventsError }] =
    useSearchEventsMutation();
  const [isAcceptedFilter, setIsAcceptedFilter] = useLocalStorage("!event-filter.is_accepted");
  const [timeFilter, setTimeFilter] = useLocalStorage("~event-filter.time");
  const [isFullFilter, setIsFullFilter] = useLocalStorage("~event-filter.is_full");
  const numberOfPages = Math.ceil((data?.total ?? 0) / pageSize);

  useDocumentTitle(t("events.title"));

  useEffect(() => {
    searchEvents({
      filters: {
        is_full: isFullFilter as any,
        is_accepted: isAcceptedFilter as any,
        team,
        time: timeFilter as any,
      },
      pageNumber,
      pageSize,
    });
  }, [isAcceptedFilter, isFullFilter, pageNumber, searchEvents, team, timeFilter]);

  return (
    <Container size="xl">
      <Group position="apart">
        <Text size="lg" weight={500}>
          {t("events.title")}
        </Text>

        <Button
          size="xs"
          leftIcon={<IconCalendarPlus size={14} />}
          component={Link}
          to={path("event-create")}
          variant="outline"
        >
          {t("action.new_event")}
        </Button>
      </Group>

      <Grid mb="lg">
        <Grid.Col xs={6} sm={4} md={3}>
          <Select
            clearable
            label={t("event_filter.when")}
            placeholder={t("select_one")}
            value={timeFilter}
            onChange={setTimeFilter}
            data={[
              { label: t("event_filter.future"), value: "future" },
              { label: t("event_filter.past"), value: "past" },
            ]}
          />
        </Grid.Col>
        <Grid.Col xs={6} sm={4} md={3}>
          <Select
            clearable
            label={t("event_filter.attend")}
            placeholder={t("select_one")}
            value={isAcceptedFilter}
            onChange={setIsAcceptedFilter}
            data={[
              { label: t("yes"), value: "y" },
              { label: t("no"), value: "n" },
            ]}
          />
        </Grid.Col>
        <Grid.Col xs={6} sm={4} md={3}>
          <Select
            clearable
            label={t("event_filter.is_full")}
            placeholder={t("select_one")}
            value={isFullFilter}
            onChange={setIsFullFilter}
            data={[
              { label: t("yes"), value: "y" },
              { label: t("no"), value: "n" },
            ]}
          />
        </Grid.Col>
      </Grid>

      {eventsError && <ErrorAlert error={eventsError} />}
      <SimpleGrid
        spacing="xs"
        cols={3}
        breakpoints={[
          { maxWidth: "xs", cols: 1 },
          { maxWidth: "sm", cols: 2 },
          { maxWidth: "md", cols: 3 },
        ]}
      >
        <LoadingOverlay visible={isEventsLoading} overlayBlur={2} />
        {data?.rows.map((row) => (
          <EventCard key={row.event.id} {...row} />
        ))}
      </SimpleGrid>
      {numberOfPages > 1 && (
        <Pagination mt="lg" onChange={setPageNumber} value={pageNumber} total={numberOfPages} />
      )}
    </Container>
  );
}

export default function EventListPage() {
  const loading = useSelector(selectTeamsLoading);
  const team = useSelector(selectSelectedTeamId);

  if (team == null) {
    if (!loading) {
      return <Loader />;
    }
    return <Navigate to={path("team-list")} />;
  }

  return <PageContent team={team} />;
}
