import { AvatarProps } from "@mantine/core";
import useCurrentUser from "../../hooks/useCurrentUser";
import { UserAvatar } from "./user-avatar";

type Props = Omit<AvatarProps, "src">;

export function CurrentUserAvatar(props: Props) {
  const user = useCurrentUser();
  return <UserAvatar {...props} userId={user.id} />;
}
