import { Alert, Button, Card, Container, Group, Progress, Text, Title } from "@mantine/core";
import { useDocumentTitle } from "@mantine/hooks";
import { IconAlertCircle } from "@tabler/icons-react";
import { Attendee, ErrorCode, Event } from "@wendy/types";
import React, { PropsWithChildren } from "react";
import { useParams } from "react-router-dom";
import { DateTime } from "../features/ui/datetime";
import { EventInvitationAcceptForm } from "../features/invitations";
import { LoginForm, RegisterForm } from "../features/users";
import { isApiError } from "../lib/server";
import { useGetInvitationQuery } from "../services/invitations";
import { selectAccount } from "../store/selectors";
import { signOut } from "../store/slices/account";
import { useDispatch, useSelector } from "../store/store";
import { useTranslate } from "../translations";

type EventCardProps = {
  attendees: Attendee[];
  event: Event;
};

function EventCard({ attendees, event }: EventCardProps) {
  const t = useTranslate();
  const roomLeft = event.capacity - attendees.filter((x) => x.state === "accepted").length;

  return (
    <Card withBorder mb="xl">
      <Text weight={500} mb="sm">
        {event.name}
      </Text>
      <Text size="sm" color="dimmed" mb="sm">
        {event.description}
      </Text>
      <Group>
        <Text size="xs">
          {t("event_detail.room_left")}
          {": "}
        </Text>
        <Text size="xs">{roomLeft}</Text>
      </Group>
      <Group>
        <Text size="xs">
          {t("event.date")}
          {": "}
        </Text>
        <DateTime value={event.time} format="dddd D. MMMM YYYY H:mm" />
      </Group>
    </Card>
  );
}

type WrapperProps = PropsWithChildren<{
  attendees?: Attendee[];
  event?: Event;
  note?: string;
}>;

function Wrapper({ attendees, children, event, note }: WrapperProps) {
  const t = useTranslate();

  return (
    <Container size="xs" mt="xl">
      <Title align="center" mb="xl">
        {t("event_invitation.title")}
      </Title>
      {children}
    </Container>
  );
}

export default function EventInvitationPage() {
  const { token = "" } = useParams<{ token: string }>();
  const t = useTranslate();
  const dispatch = useDispatch();
  const auth = useSelector(selectAccount);
  const { data, error, isLoading, refetch } = useGetInvitationQuery({ token });

  useDocumentTitle(t("event_invitation.title"));

  if (isApiError(error)) {
    if (error.code === ErrorCode.NOT_FOUND) {
      return (
        <Wrapper>
          <Alert color="red" icon={<IconAlertCircle size={16} />} title={t("error")}>
            {t("event_invitation.not_found")}
          </Alert>
        </Wrapper>
      );
    }
    return (
      <Wrapper>
        <Alert color="red" icon={<IconAlertCircle size={16} />} title={t("error")}>
          {t("error.unknown_error_type")}
        </Alert>
      </Wrapper>
    );
  }
  if (isLoading) {
    return <Progress />;
  }
  if (!data) {
    return (
      <Wrapper>
        <Alert color="red" icon={<IconAlertCircle size={16} />} title={t("error")}>
          {t("error.unknown_error_type")}
        </Alert>
      </Wrapper>
    );
  }

  const { attendees, event, invitation } = data;
  const numberOfAccepted = attendees?.filter(({ state }) => state !== "accepted").length ?? 0;
  const roomLeft = event.capacity - numberOfAccepted;

  if (invitation.resolved_date != null) {
    return (
      <Wrapper>
        <Alert color="teal">{t("event_invitation.resolved_note")}</Alert>
      </Wrapper>
    );
  }
  if (roomLeft <= 0) {
    return (
      <Wrapper>
        <Alert color="teal">{t("event_invitation.no_room_note")}</Alert>
      </Wrapper>
    );
  }
  if (invitation.suggested_user == null) {
    return (
      <Wrapper>
        <EventCard attendees={attendees} event={event} />
        <Alert color="yellow" mb="xl">
          {t("event_invitation.register_note")}
        </Alert>
        <RegisterForm
          email={invitation.email}
          invitationToken={invitation.id}
          onSuccess={refetch}
        />
      </Wrapper>
    );
  }
  if (auth.user == null) {
    return (
      <Wrapper>
        <EventCard attendees={attendees} event={event} />
        <Alert color="yellow" mb="xl">
          {t("event_invitation.login_note")}
        </Alert>
        <LoginForm email={invitation.email} onSuccess={refetch} />
      </Wrapper>
    );
  }
  if (invitation.suggested_user !== auth.user.id) {
    return (
      <Wrapper>
        <EventCard attendees={attendees} event={event} />
        <Alert color="yellow" mb="xl">
          {t("event_invitation.logout_note")}
        </Alert>
        <Button
          onClick={() => {
            dispatch(signOut());
            refetch();
          }}
        >
          {t("event_invitation.logout")}
        </Button>
      </Wrapper>
    );
  }
  return (
    <Wrapper event={event} note={t("event_invitation.accept_note")}>
      <EventInvitationAcceptForm invitation={invitation} onSuccess={refetch} />
    </Wrapper>
  );
}
