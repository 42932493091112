import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL_API } from "../config";
import { RootState } from "../store/store";

export const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL_API,
  keepalive: true,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).account.token;
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});
