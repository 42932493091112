import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { MantineProvider, ColorSchemeProvider, ColorScheme } from "@mantine/core";
import { Notifications } from "@mantine/notifications";
import { path } from "./lib/paths";
import { useLocalStorage } from "@mantine/hooks";
import { BASE_URL_APP } from "./config";

import DashboardPage from "./pages/dashboard";
import EmailVerificationPage from "./pages/email-verification";
import EventCreatePage from "./pages/event-create";
import EventDetailPage from "./pages/event-detail";
import EventEditPage from "./pages/event-edit";
import EventInvitationPage from "./pages/event-invitation";
import EventListPage from "./pages/event-list";
import ForgettenPasswordPage from "./pages/forgetten-password";
import HomePage from "./pages/home";
import LoginPage from "./pages/login";
import MyAccountPage from "./pages/my-account";
import PasswordResetPage from "./pages/password-reset";
import RegisterPage from "./pages/register";
import SettingsPage from "./pages/settings";
import TeamCreatePage from "./pages/team-create";
import TeamDetailPage from "./pages/team-detail";
import TeamEditPage from "./pages/team-edit";
import TeamListPage from "./pages/team-list";
import { PrivateLayout, PublicLayout } from "./features/layouts";
import { RouterErrorBoundary } from "./features/ui/router-error-boundry";

const basename = new URL(BASE_URL_APP).pathname;

const router = createBrowserRouter(
  [
    {
      path: "/",
      element: <PublicLayout />,
      errorElement: <RouterErrorBoundary />,
      children: [
        { path: path("home"), element: <HomePage /> },
        { path: path("login"), element: <LoginPage /> },
        { path: path("register"), element: <RegisterPage /> },
        { path: path("forgetten-password"), element: <ForgettenPasswordPage /> },
        { path: path("email-verification", ":token"), element: <EmailVerificationPage /> },
        { path: path("password-reset", ":token"), element: <PasswordResetPage /> },
        { path: path("event-invitation", ":token"), element: <EventInvitationPage /> },
      ],
    },
    {
      path: "/app",
      element: <PrivateLayout />,
      errorElement: <RouterErrorBoundary />,
      children: [
        { path: path("dashboard"), element: <DashboardPage /> },
        { path: path("event-list"), element: <EventListPage /> },
        { path: path("event-create"), element: <EventCreatePage /> },
        { path: path("event-detail", ":id"), element: <EventDetailPage /> },
        { path: path("event-edit", ":id"), element: <EventEditPage /> },
        { path: path("team-list"), element: <TeamListPage /> },
        { path: path("team-create"), element: <TeamCreatePage /> },
        { path: path("team-detail", ":id"), element: <TeamDetailPage /> },
        { path: path("team-edit", ":id"), element: <TeamEditPage /> },
        { path: path("settings"), element: <SettingsPage /> },
        { path: path("my-account"), element: <MyAccountPage /> },
      ],
    },
  ],
  { basename },
);

export default function App() {
  const [colorScheme, setColorScheme] = useLocalStorage<ColorScheme>({
    key: "color-scheme",
    defaultValue: "light",
    getInitialValueInEffect: true,
  });

  const toggleColorScheme = (value?: ColorScheme) =>
    setColorScheme(value || (colorScheme === "dark" ? "light" : "dark"));

  return (
    <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
      <MantineProvider theme={{ colorScheme }} withGlobalStyles withNormalizeCSS>
        <Notifications />
        <RouterProvider router={router} />
      </MantineProvider>
    </ColorSchemeProvider>
  );
}
