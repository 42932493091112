export type LocationId = string;
export type Location = {
  id: LocationId;
  team: TeamId;
  name: string;
  value: string | null;
};

export type EventId = string;
export type Event = {
  allowedDebt: number | null;
  cancelationFee: number | null;
  capacity: number;
  description: string;
  id: EventId;
  location: LocationId | null;
  name: string;
  price: number | null;
  team: TeamId;
  time: string;
};
export type EventCreate = Event;
export type EventUpdate = Partial<
  Pick<
    Event,
    | "allowedDebt"
    | "cancelationFee"
    | "capacity"
    | "description"
    | "location"
    | "name"
    | "price"
    | "time"
  >
>;
export type EventUpdateCommand = {
  event: EventId;
  data: EventUpdate;
};

export type EventSearchRow = {
  event: Omit<Event, "description">;
  slots: Record<AttendeeState, number>;
  my_state: AttendeeState;
};

export type AttendeeState = "accepted" | "canceled" | "pending" | "rejected";

export type Attendee = {
  state: AttendeeState;
  user: UserId;
};

export type Role = "manager" | "plain";

export type Member = {
  role: Role;
  team: TeamId;
  user: UserId;
};

export type TeamId = string;
export type Team = {
  id: TeamId;
  name: string;
};
export type TeamCreate = {
  id: TeamId;
  name: string;
};
export type TeamUpdate = Pick<Team, "name">;

export type UserId = string;
export type User = {
  avatar: MediaId | null;
  id: string;
  email: string;
  first_name: string;
  last_name: string;
  phone: string;
};
export type UserCreate = Omit<User, "name">;
export type UserUpdate = Partial<Pick<User, "first_name" | "last_name" | "phone" | "avatar">>;

type UserViewBase = Pick<User, "avatar" | "first_name" | "id" | "last_name">;
export type UserViewFull = UserViewBase & Pick<User, "email" | "phone"> & { view: "full" };
export type UserViewReduced = UserViewBase & { view: "reduced" };
export type UserView = UserViewFull | UserViewReduced;

export type SearchRow = { id: string } & Record<string, null | number | string>;

export type SearchResponse = {
  rows: SearchRow[];
  total: number;
};

export type SearchFilter = {
  name: string;
  operator: string;
  value?: string;
};

export type SearchColumn = {
  name: string;
  label?: string;
};

export type SearchRequest<Params = Record<string, string> | undefined> = {
  columns: SearchColumn[];
  filter: SearchFilter[];
  order?: { by: string; direction: "asc" | "desc" };
  pageNumber?: number;
  pageSize?: number;
  params: Params;
};

export type FilterMetadata = {
  label: string;
  operator: string;
  arity?: number;
};

export type ColumnMetadata = {
  filters: FilterMetadata[];
  label: string;
  name: string;
  selectable?: boolean;
  sortable?: boolean;
  type: "date" | "datetime" | "enum" | "json" | "number" | "string" | "time" | "uuid";
};

export type SearchMetadata = ColumnMetadata[];

export enum ErrorCode {
  BAD_REQUEST = "BAD_REQUEST",
  CANNOT_ABANDON = "CANNOT_ABANDON",
  CANNOT_CROSS_DEBIT = "CANNOT_CROSS_DEBIT",
  CANNOT_DELETE = "CANNOT_DELETE",
  FORBIDDEN = "FORBIDDEN",
  NOT_ENOUGH_CREDIT = "NOT_ENOUGH_CREDIT",
  NOT_FOUND = "NOT_FOUND",
  PASSWORD_NOT_VALID = "PASSWORD_NOT_VALID",
  PASSWORD_NOT_AUTHENTICATED = "PASSWORD_NOT_AUTHENTICATED",
  PASSWORD_NOT_FOUND = "PASSWORD_NOT_FOUND",
  SIGN_UP_EMAIL_COLLISION = "SIGN_UP_EMAIL_COLLISION",
  TOKEN_EXPIRED = "TOKEN_EXPIRED",
  UNEXPECTED_DATA = "UNEXPECTED_DATA",
  UNKNOWN_ERROR = "UNKNOWN_ERROR",
}

export type Invitation = {
  author: UserId;
  email: string;
  event: EventId;
  id: string;
  suggested_user: UserId | null;
  resolved_date: string | null;
};

export type MediaType = "img" | "doc";
export type MediaId = string;
export type Media = {
  id: string;
  type: MediaType;
  ext: string;
  mimetype: string;
};
