import { Box, Progress, Text } from "@mantine/core";
import { passwordStrength, Result } from "check-password-strength";
import { IconCheck, IconX } from "@tabler/icons-react";
import { FC } from "react";
import { useTranslate } from "../../translations";

const MIN_LENGTH = 10;

const PasswordRequirement: FC<{ meets: boolean; label: string }> = ({ meets, label }) => (
  <Text
    color={meets ? "teal" : "red"}
    sx={{ display: "flex", alignItems: "center" }}
    mt={7}
    size="sm"
  >
    {meets ? <IconCheck size={14} /> : <IconX size={14} />} <Box ml={10}>{label}</Box>
  </Text>
);

type Props = {
  strength: Result<string>;
};

export function PasswordStrengthMeter({ strength }: Props) {
  const t = useTranslate();
  const color = strength.id === 3 ? "teal" : strength.id === 2 ? "yellow" : "red";
  const progress = (100 * (1 + strength.id)) / 4;

  return (
    <>
      <Progress color={color} value={progress} size={5} mb="sm" />
      <PasswordRequirement
        label={t("password_strength.no_lowercase")}
        meets={strength.contains.includes("lowercase")}
      />
      <PasswordRequirement
        label={t("password_strength.no_uppercase")}
        meets={strength.contains.includes("uppercase")}
      />
      <PasswordRequirement
        label={t("password_strength.no_number")}
        meets={strength.contains.includes("number")}
      />
      <PasswordRequirement
        label={t("password_strength.no_symbol")}
        meets={strength.contains.includes("symbol")}
      />
      <PasswordRequirement
        label={t("password_strength.too_short")}
        meets={strength.length >= MIN_LENGTH}
      />
    </>
  );
}

export const calculateStrength = (value: string) =>
  passwordStrength(value, [
    {
      id: 0,
      value: "Too weak",
      minDiversity: 0,
      minLength: 0,
    },
    {
      id: 1,
      value: "Weak",
      minDiversity: 2,
      minLength: 6,
    },
    {
      id: 2,
      value: "Medium",
      minDiversity: 3,
      minLength: 8,
    },
    {
      id: 3,
      value: "Strong",
      minDiversity: 4,
      minLength: MIN_LENGTH,
    },
  ]);

export const isPasswordValid = (value: string) => calculateStrength(value).id === 3;
