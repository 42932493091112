import { Button, Group, Modal, Stack, Text } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import React from "react";
import { LocationId } from "@wendy/types";
import { useTranslate } from "../../translations";
import { useDispatch } from "../../store/store";
import { removeLocation } from "../../store/slices/locations";

type Props = {
  locationId: LocationId;
  onClose: () => void;
  onSuccess: () => void;
  opened: boolean;
};

export function RemoveLocationDialog({ locationId, opened, onClose, onSuccess }: Props) {
  const t = useTranslate();
  const dispatch = useDispatch();
  const handleSubmit = async () => {
    try {
      await dispatch(removeLocation(locationId)).unwrap();
      onSuccess();
    } catch (error) {
      console.error(error);
      showNotification({
        color: "red",
        message: t("remove_location.error.cannot_remove.message"),
      });
      onClose();
    }
  };

  return (
    <Modal
      onClose={onClose}
      opened={opened}
      title={t("remove_location.title")}
      withCloseButton={true}
    >
      <Stack>
        <Text>{t("remove_location.question")}</Text>
        <Group position="apart">
          <Button onClick={() => onClose()} color="gray">
            {t("remove_location.cancel")}
          </Button>
          <Button onClick={() => handleSubmit()} color="red">
            {t("remove_location.submit")}
          </Button>
        </Group>
      </Stack>
    </Modal>
  );
}
